import React from "react";

// components
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";
import {CircularProgress} from "@mui/joy";


class MarketingUserWebinarTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webinars: null,
            pages: null,
            page: 1,
        };
    }

    async componentDidMount() {
        await this.retrieveWebinars();
    }

    async retrieveWebinars(page=1) {
        await this.setState(
            {
                webinars: null
            }
        );
        const url = `webinar/`;
        const params = {
            attendees: this.props.user.id,
            page: page
        }
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        await this.setState(
            {
                webinars: response.data.results,
                pages: response.data.total_pages,
                page: page,
            }
        );
    }

    render() {
        if (!this.state.webinars) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <CommonPaginatedTable
                data={this.state.webinars}
                pages={this.state.pages}
                pageNumber={this.state.page}
                pagesCallback={this.retrieveWebinars.bind(this)}
                fields={[
                    {
                        title: 'Заголовок',
                        name: 'title',
                        visible: true,
                        linkPattern: '/admin/webinar/detail/:linkParam',
                        linkParam: 'id'
                    },
                ]
                }
            />
        )
    }
}

export default MarketingUserWebinarTab
