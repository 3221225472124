import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";

// components
import CommonInfoTab from "../Common/CommonInfoTab";
import BaseApi from "../../utils/BaseApi";
import RequisitesCreateModal from "./RequisitesCreateModal";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import retrievePermission from "../../utils/retrievePermission";
import AddressCreateModal from "./AddressCreateModal";

// icons



class AddressTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            addresses: null,
            createPermission: 0,
            updatePermission: 0,
        };
    }

    async componentDidMount() {
        await this.retrieveAddresses();
        await this.retrievePermissions();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveAddresses();
        }
    }

    async retrievePermissions() {
        let c = await retrievePermission(
            'AddressViewSet',
            'create',
            this.context.subscriberObject
        )
        let u = await retrievePermission(
            'AddressViewSet',
            'update',
            this.context.subscriberObject
        )
        await this.setState(
            {
                createPermission: c,
                updatePermission: u,
            }
        )
    }

    async retrieveAddresses() {
        const url = 'address/';
        const api = new BaseApi();
        let params = {
            related_app: 'Account',
            related_app_id: this.props.account.id
        }
        let response = await api.get(url, params);
        this.setState(
            {
                addresses: response.data.results,
            }
        );
    }

    render() {
        if (!this.state.addresses) {
            return (<CircularProgress/>)
        }
        return(
            <Stack
                spacing={2}
            >
                {
                    this.state.addresses.map((a) => (
                        <CommonInfoTab
                            callBack={this.retrieveAddresses.bind(this)}
                            groups={
                                [
                                    {
                                        title: a.title,
                                        icon: null,
                                        fields: [
                                            {
                                                title: 'Индекс',
                                                value: a.postal_code,
                                                edit: {
                                                    permission: this.state.updatePermission,
                                                    fields: ['line_one', ],
                                                    baseUrl: 'address',
                                                    obj: a
                                                }
                                            },
                                            {
                                                title: 'Страна',
                                                value: a.country,
                                                edit: {
                                                    permission: this.state.updatePermission,
                                                    fields: ['line_one', ],
                                                    baseUrl: 'address',
                                                    obj: a
                                                }
                                            },
                                            {
                                                title: 'Регион',
                                                value: a.region,
                                                edit: {
                                                    permission: this.state.updatePermission,
                                                    fields: ['line_one', ],
                                                    baseUrl: 'address',
                                                    obj: a
                                                }
                                            },
                                            {
                                                title: 'Город',
                                                value: a.city,
                                                edit: {
                                                    permission: this.state.updatePermission,
                                                    fields: ['line_one', ],
                                                    baseUrl: 'address',
                                                    obj: a
                                                }
                                            },
                                            {
                                                title: 'Строка 1',
                                                value: a.line_one,
                                                edit: {
                                                    permission: this.state.updatePermission,
                                                    fields: ['line_one', ],
                                                    baseUrl: 'address',
                                                    obj: a
                                                }
                                            },
                                            {
                                                title: 'Строка 2',
                                                value: a.line_two,
                                                edit: {
                                                    permission: this.state.updatePermission,
                                                    fields: ['line_two', ],
                                                    baseUrl: 'address',
                                                    obj: a
                                                }
                                            },
                                        ]
                                    },
                                ]
                            }
                        />
                    ))
                }

                {
                    this.state.createPermission?
                        <AddressCreateModal
                            related_app={'Account'}
                            related_app_id={this.props.account.id}
                            callBack={this.retrieveAddresses.bind(this)}
                        />:
                        <CircularProgress/>
                }
            </Stack>
        )
    }
}

export default AddressTab
