// components
import CommonForm from "../Common/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableViewIcon from "@mui/icons-material/TableView";


class CalculatorItemCreate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.constructFormFields()
        return {
            modalLabel: 'Добавление отчета',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async constructFormFields() {
        let url = `calculator/models/`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        let models = response.data
        return [
            {
                'name': 'title',
                'label': 'Заголовок',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'size',
                'label': 'Размер блока',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 6,
                'type': 'select',
                'options': [
                    {
                        value: 6,
                        label: 'Половина ширины'
                    },
                    {
                        value: 12,
                        label: 'Полная ширина'
                    },
                ]
            },
            {
                'name': 'model',
                'label': 'Модель',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': null,
                'type': 'select',
                'options': models
            },
            {
                'name': 'date_field',
                'label': 'Поле даты (выбор из списка)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': 6,
                'xl': 6,
                'required': false,
                'default': null,
                'type': 'select',
                'options': []
            },
            {
                'name': 'custom_date_field',
                'label': 'Поле даты (свой вариант)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': 6,
                'xl': 6,
                'required': false,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'field',
                'label': 'Поле (выбор из списка)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': 6,
                'xl': 6,
                'required': false,
                'default': null,
                'type': 'select',
                'options': []
            },
            {
                'name': 'custom_field',
                'label': 'Поле (свой вариант)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': 6,
                'xl': 6,
                'required': false,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'period',
                'label': 'Период агрегации',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 'month',
                'type': 'select',
                'options': [
                    {
                        value: 'quarter',
                        label: 'Квартал'
                    },
                    {
                        value: 'month',
                        label: 'Месяц'
                    },
                    {
                        value: 'week',
                        label: 'Неделя'
                    },
                ]
            },
            {
                'name': 'groups',
                'label': 'Группировки',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': false,
                'default': '[]',
                'type': 'stringList',
            },
            {
                'name': 'queryset_params',
                'label': 'Настройки отбора',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': false,
                'default': '{}',
                'type': 'keyValuePairs',
            },
            {
                type: 'submit',
                label: 'Добавить'
            }
        ];
    }

    async processForm(form) {
        let data = Object.fromEntries(form.state.formData.entries());
        data.id = crypto.randomUUID();
        data.size = parseInt(data.size);
        let currentCategory = data.field;
        if (!currentCategory) {
            currentCategory = data.custom_field;
            if (!currentCategory) {
                currentCategory = 'count'
            }
        }
        let currentYear = new Date().getFullYear()
        data.charts = [
            {
                years: [currentYear,],
                'currentCategory': currentCategory,
                yearColors: {},
                colorMode: 'heatmap',
            }
        ]
        data.charts[0].yearColors[currentYear] = this.props.colors[0]
        await this.props.callBack(data);
        this.setModalOpenFalse();
    }
}

export default CalculatorItemCreate
