import React from 'react';

// components
import CommonSidebar from "../Common/CommonSidebar";
import LeadCreateModal from "../Lead/LeadCreateModal";
import retrievePermission from "../../utils/retrievePermission";

//icons
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import UserSubscriberContext from "../../context/UserSubscriberContext";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import ApprovalIcon from "@mui/icons-material/Approval";
import GavelIcon from '@mui/icons-material/Gavel';


class OpportunitySidebar extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            additionalGroups: [],
        };
    }

    async componentDidMount() {
        await this.retrieveCreatePermission();
    }

    async retrieveCreatePermission(){
        let p = 0;
        p += await retrievePermission(
            'OppPaymentViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        p += await retrievePermission(
            'OppCertViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        if (p > 1){
            this.setState(
            {
                    additionalGroups: [
                        ...this.state.additionalGroups,
                        {
                            title: 'Договорные документы',
                            items: [
                                {
                                    title: 'Договоры',
                                    link: '/contract/contract/',
                                    icon: GavelIcon,
                                },
                                {
                                    title: 'Входящие платежи',
                                    link: '/contract/payment/',
                                    icon: CurrencyRubleIcon,
                                },
                                {
                                    title: 'Акты',
                                    link: '/contract/certificate/',
                                    icon: ApprovalIcon,
                                },
                            ],
                        },
                    ]
                }
            );
        }
    }

    render() {
        return(
            <CommonSidebar
                groups={
                    [...[
                        {
                            title: 'Лиды',
                            items: [
                                {
                                    type: 'component',
                                    component: <LeadCreateModal />
                                },
                                {
                                    title: 'Нераспределенные',
                                    link: '/lead/waiting/',
                                    icon: QuestionMarkIcon,
                                },
                                {
                                    title: 'Входящие',
                                    link: '/lead/incoming/',
                                    icon: MoveToInboxIcon,
                                },
                                {
                                    title: 'Исходящие ',
                                    link: '/lead/outgoing/',
                                    icon: ForwardToInboxIcon,
                                }
                            ],
                        },
                        {
                            title: 'Сделки',
                            items: [
                                {
                                    title: 'Список',
                                    link: '/opportunity/',
                                    icon: LocalOfferIcon,
                                },
                            ],
                        },
                    ], ...this.state.additionalGroups]
                }
            />
        )
    }
}

export default OpportunitySidebar
