import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";

import TaskCard from "./TaskCard";
import TaskCreateModal from "./TaskCreateModal";


class TaskWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: null,
        };
    }

    async componentDidMount() {
        await this.retrieveTasks();
    }

    async retrieveTasks() {
        this.setState(
            {
                tasks: null
            }
        );
        const url = 'task/my/';
        const api = new BaseApi();
        let response = await api.get(
            url+'?work_status=PSD&work_status=WRK'
        );
        this.setState(
            {
                tasks: response.data.results
            }
        );
    }

    render() {
        if (!this.state.tasks) {
            return (<CircularProgress/>)
        }
        return (
            <Sheet
                style={
                    {
                        'maxHeight': 350,
                        'minHeight': 350,
                        'overflowY': 'scroll',
                    }
                }
            >

                {
                    this.state.tasks.map(
                        (task) => (
                            <TaskCard
                                task={task}
                                key={'task-card-'+task.id}
                                callBack={this.retrieveTasks.bind(this)}
                            />
                        )
                    )
                }
                <TaskCreateModal
                    account={null}
                    opportunity={null}
                    phase={null}
                    activeStep={0}
                    lockedSteps={[]}
                    callBack={this.retrieveTasks.bind(this)}
                />
            </Sheet>
        )
    }
}

export default TaskWidget;
