import React from "react";

import Sheet from "@mui/joy/Sheet";
import {CircularProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";

// components
import BaseApi from "../../utils/BaseApi";
import ComponentCard from "../Common/ComponentCard";


class NotificationDetailInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification: null,
            links: null
        };
    }

    async componentDidMount() {
        await this.retrieveNotification();
    }

    async retrieveNotification() {
        const api = new BaseApi();
        let url = `notification/${this.props.notificationId}/`;
        let notificationResponse = await api.patch(
            url,
            {is_read: true},
        );
        url = 'notification_link/';
        const params = {
            notification: this.props.notificationId,
        };
        let linksResponse = await api.get(
            url,
            params
        )
        this.setState(
            {
                notification: notificationResponse.data,
                links: linksResponse.data.results,
            }
        );
    }

    render() {
        if (!this.state.notification) {
            return (<CircularProgress/>)
        }
        if (this.state.links === null) {
            return (<CircularProgress/>)
        }
        return (
            <Sheet
                variant="outlined"
                color="neutral"
                sx={
                    {
                        p: 4,
                        height: '100%',
                        alignContent: 'space-between',
                        // display: 'flex',
                        flexWrap: 'wrap',
                        width: 'calc(80vw)',
                    }
                }
            >
                <Typography
                    level={'h2'}
                    sx={{
                        mb: 2,
                    }}
                >
                    {this.state.notification.title}
                </Typography>
                <Box
                    sx={{
                        mb: 2,
                    }}
                >
                    <div dangerouslySetInnerHTML={{__html: this.state.notification.desc}} />
                </Box>
                <Typography
                    level={'h3'}
                    sx={{
                        mb: 2,
                    }}
                >
                    Ссылки
                </Typography>
                {this.state.links.map(
                    (link) => (
                        <Box
                            key={'link-'+link.id}
                        >
                            <ComponentCard
                                entity={link.entity}
                                entity_id={link.entity_id}
                            />
                        </Box>
                    )
                )}
            </Sheet>
        )
    }
}

export default NotificationDetailInner;
