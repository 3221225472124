import React from "react";

import Button from "@mui/joy/Button";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import {CircularProgress, Stack} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import CommonStepper from "../Common/CommonStepper";
import CommonForm from "../Common/CommonForm";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";
import {deleteArrayItemByKeyValue, setFormFieldKey, splitToList} from "../../utils/misc";
import CommonCallBackButton from "../Common/CommonCallBackButton";

// icons
import DeleteIcon from "@mui/icons-material/Delete";


class NewQuoteCreateInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            formFields: [],
            quote: {},
            revisions: [],
        };
        this.steps = [
            {
                order: 0,
                title: 'Общая информация',
            },
            {
                order: 1,
                title: 'Трудоемкости',
            },
            {
                order: 2,
                title: 'Параметры',
            },
            {
                order: 3,
                title: 'Текст',
            },
            {
                order: 4,
                title: 'Персоналии',
            },
        ]
    }

    async componentDidMount() {
        let formFields = await this.retrieveFields(
            'new_quote/create_form',
                [
                    'title',
                    'reply_to_number',
                    'reply_to_date',
                ]
        )
        formFields = setFormFieldKey(
            formFields,
            'title',
            'default',
            this.props.opportunity.name,
        )
        formFields = setFormFieldKey(
            formFields,
            'submitButton',
            'label',
            'Далее',
        )
        this.setState(
            {
                formFields: formFields
            }
        )
    }

    async retrieveFields(url, fields){
        const api = new BaseApi();
        let response = await api.get(
            url,
            {
                fields: fields?JSON.stringify(fields):null
            }
        );
        return response.data
    }

    async retrieveRevisions(){
        const url = 'estimation_revision/';
        const params = {
            opportunity: this.props.opportunity.id
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        return response.data.results
    }

    updateQuoteObj(quote, formData){
        let formDataObj = Object.fromEntries(
            formData.entries()
        );
        for (let k of Object.keys(formDataObj)){
            quote[k] = formDataObj[k]
        }
        return quote
    }

    async processStep0(form) {
        let quote = this.updateQuoteObj(
            this.state.quote,
            form.state.formData,
        );
        let revisions = await this.retrieveRevisions()
        let formFields = [
            {
                'name': 'revision',
                'label': 'Ревизия',
                'xs': 12,
                'sm': 12,
                'md': 6,
                'lg': 6,
                'xl': 6,
                'required': true,
                'default': revisions[0].title,
                'type': 'select',
                'options': revisions.map((each) => {return(
                    {'value': each.id, 'label': each.desc?each.desc:`Ревизия ${each.number}`}
                )})
            },
            {
                'name': 'add_text',
                'label': 'Добавить текст',
                'xs': 12,
                'sm': 12,
                'md': 2,
                'lg': 2,
                'xl': 2,
                'required': true,
                'default': true,
                'type': 'checkbox',
            },
            {
                'name': 'order',
                'label': 'Порядок',
                'xs': 12,
                'sm': 12,
                'md': 2,
                'lg': 2,
                'xl': 2,
                'required': true,
                'default': 1,
                'type': 'number',
            },
            {
                'xs': 12,
                'sm': 12,
                'md': 2,
                'lg': 2,
                'xl': 2,
                type: 'submit',
                label: 'Добавить'
            }
        ]
        this.setState(
            {
                activeStep: 1,
                quote: quote,
                formFields: formFields,
            }
        );
    }

    async addRevision(form){
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        formDataObj.add_text = formDataObj.add_text==="true"
        let revisions = this.state.revisions;
        formDataObj.hash = crypto.randomUUID();
        const url = `estimation_revision/${formDataObj.revision}/`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        formDataObj.title = response.data.desc?response.data.desc:`Ревизия ${response.data.number}`
        revisions.push(formDataObj);
        revisions.sort(
            function(a, b) {
                return a.order - b.order
            }
        )
        this.setState(
            {
                revisions: revisions
            }
        )
    }

    async deleteRevision(hash){
        let revisions = this.state.revisions;
        revisions = deleteArrayItemByKeyValue(
            revisions,
            'hash',
            hash
        )
        this.setState(
            {
                revisions: revisions
            }
        )
    }

    async processStep1() {
        const api = new BaseApi();
        let formFields = await this.retrieveFields(
            'new_quote/create_form',
                [
                    'work_days',
                    'day_rate',
                    'overhead_rate',
                    'misc_cost',
                    'profit_rate',
                    'prepay_rate',
                ]
        );
        let work_days = 0;
        for (let revision of this.state.revisions){
            let url = `estimation_revision/${revision.revision}/chart/`;
            let response = await api.get(
                url,
                {}
            );
            work_days += response.data.max_proba_estimation
        }
        formFields = setFormFieldKey(
            formFields,
            'work_days',
            'default',
            Math.ceil(work_days),
        )
        formFields = setFormFieldKey(
            formFields,
            'submitButton',
            'label',
            'Далее',
        )
        this.setState(
            {
                activeStep: 2,
                formFields: formFields,
            }
        );
    }

    async processStep2(form) {
        let quote = this.updateQuoteObj(
            this.state.quote,
            form.state.formData,
        );
        let formFields = await this.retrieveFields(
            'new_quote/create_form',
            [
                'about_company',
                'specification',
                'discount',
                'offer_end_dt',
                'letter_wordings',
                'businesses',
                'accounts',
                'free_text',
            ]
        )
        const api = new BaseApi();
        let url = `account/customers/`;
        let response = await api.get(
            url,
            {
                page_size: 10000,
            }
        );
        formFields = setFormFieldKey(
            formFields,
            'accounts',
            'options',
            response.data.results.map((each) => (
                {'value': each.id, 'label': each.nameshort}
            ))
        )

        // MAYBEMISC: end of current quarter
        const today = new Date();
        const quarter = Math.floor(today.getMonth() / 3);
        const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
        const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);

        formFields = setFormFieldKey(
            formFields,
            'offer_end_dt',
            'default',
            endFullQuarter,
        )
        formFields = setFormFieldKey(
            formFields,
            'submitButton',
            'label',
            'Далее',
        )
        this.setState(
            {
                activeStep: 3,
                formFields: formFields,
                quote: quote,
            }
        );
    }

    async processStep3(form) {
        let quote = this.updateQuoteObj(
            this.state.quote,
            form.state.formData,
        );
        let formFields = await this.retrieveFields(
            'new_quote/create_form',
            [
                'contact',
                'signature',
            ]
        )
        const api = new BaseApi();
        let url = `contact/`;
        let response = await api.get(
            url,
            {
                account: this.props.opportunity.account.id,
                page_size: 10000,
            }
        );
        formFields = setFormFieldKey(
            formFields,
            'contact',
            'options',
            response.data.results.map((each) => (
                {'value': each.id, 'label': `${each.first_name} ${each.last_name} - ${each.role}`}
            ))
        )
        url = `subscriber/`;
        response = await api.get(
            url,
            {
                active: true,
                can_sign_financial: true,
            }
        );
        formFields = setFormFieldKey(
            formFields,
            'signature',
            'options',
            response.data.results.map((each) => (
                {'value': each.id, 'label': `${each.user.first_name} ${each.user.last_name}`}
            ))
        )
        formFields = setFormFieldKey(
            formFields,
            'submitButton',
            'label',
            'Далее',
        )
        this.setState(
            {
                activeStep: 4,
                quote,
                formFields: formFields,
            }
        );
    }

    async processStep4(form) {
        const api = new BaseApi();
        let url = `new_quote/`;
        let quote = this.updateQuoteObj(
            this.state.quote,
            form.state.formData,
        );
        quote.opportunity = this.props.opportunity.id;
        if ('letter_wordings' in quote) {
            quote.letter_wordings = splitToList(quote.letter_wordings, ',')
        }
        if ('businesses' in quote) {
            quote.businesses = splitToList(quote.businesses, ',')
        }
        if ('accounts' in quote) {
            quote.accounts = splitToList(quote.accounts, ',')
        }
        let response = await api.post(
            url,
            quote
        );
        if (response.status === 201) {
            quote.id = response.data.id;
            url = 'new_quote_estimation_revisions/';
            for (let revision of this.state.revisions){
                revision.quote = quote.id
                response = await api.post(
                    url,
                    revision
                );
            }
            await this.props.callBack();
        }
    }

    async setStep(order) {
        let formData = new FormData();
        let formFake = {'state': {'formData': formData}}
        if (order === 0){
            await this.componentDidMount()
        }
        else {
            await this['processStep'+(order-1)](formFake)
        }
        this.setState(
            {activeStep: order}
        )
    }

    render(){
        if (!this.state.formFields) {
            return (<CircularProgress/>)
        }
        if (this.state.activeStep === 0){
            return(
                <Stack
                    spacing={2}
                >
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        key={`new-qoute-form-step-${this.state.activeStep}`}
                        fields={this.state.formFields}
                        processForm={this.processStep0.bind(this)}
                    />
                </Stack>
            )
        }
        else if (this.state.activeStep === 1){
            return(
                <Stack
                    spacing={2}
                >
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <Card
                        variant="soft"
                    >
                        <CardContent>
                            <CommonForm
                                key={`new-qoute-form-step-addRevision`}
                                fields={this.state.formFields}
                                processForm={this.addRevision.bind(this)}
                            />
                        </CardContent>
                    </Card>
                    <CommonPaginatedTable
                        data={this.state.revisions}
                        fields={
                            [
                                {
                                    title: 'Ревизия',
                                    name: 'title',
                                    visible: true,
                                },
                                {
                                    title: 'Доб. текст',
                                    name: 'add_text',
                                    visible: true,
                                    preProcess: (value) => (!!value)
                                },
                                {
                                    title: 'Порядок',
                                    name: 'order',
                                    visible: true,
                                },
                                {
                                    title: '',
                                    visible: true,
                                    use_component: true,
                                    component: CommonCallBackButton,
                                    componentProps: {
                                        propsObject: 'componentObj',
                                        propsObjectKey: 'hash',
                                        icon: DeleteIcon,
                                    },
                                    callBack: this.deleteRevision.bind(this),
                                }
                            ]
                        }
                    />
                    <Button
                        onClick={this.processStep1.bind(this)}
                    >
                        Далее
                    </Button>
                </Stack>
            )
        }
        else if (this.state.activeStep === 2){
            return(
                <Stack
                    spacing={2}
                >
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        key={`new-qoute-form-step-${this.state.activeStep}`}
                        fields={this.state.formFields}
                        processForm={this.processStep2.bind(this)}
                    />
                </Stack>
            )
        }
        else if (this.state.activeStep === 3){
            return(
                <Stack
                    spacing={2}
                >
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        key={`new-qoute-form-step-${this.state.activeStep}`}
                        fields={this.state.formFields}
                        processForm={this.processStep3.bind(this)}
                    />
                </Stack>
            )
        }
        else if (this.state.activeStep === 4){
            return(
                <Stack
                    spacing={2}
                >
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        key={`new-qoute-form-step-${this.state.activeStep}`}
                        fields={this.state.formFields}
                        processForm={this.processStep4.bind(this)}
                    />
                </Stack>
            )
        }
    }
}

export default NewQuoteCreateInner
