import React from 'react';

import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";

// components
import {retrieveImage} from "../../utils/misc";


class ProfileHorizontalByProps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
        };
    }

    async componentDidMount() {
        await this.retrieveImage();
    }

    async retrieveImage(){
        let image = await retrieveImage(
            'Subscriber',
            'image',
            this.props.user.subscriber
        )
        this.setState(
            {
                image: image
            }
        );
    }

    render() {
        return(
            <Chip
                sx={{
                    mr: 1,
                }}
                startDecorator={
                    <Avatar
                        src={`data:image/jpeg;base64,${this.state.image}`}
                        size="sm"
                    />
                }
            >
                {this.props.user.first_name + ' ' + this.props.user.last_name}
            </Chip>
        )
    }
}

export default ProfileHorizontalByProps;