import React from 'react';
import {BaseEdge,} from 'reactflow';
import FloatingEdge from "./FloatingEdge";

export default function SelfConnectingEdge(props) {
    if (props.source !== props.target) {
        return <FloatingEdge
            {...props}
            style={{strokeWidth: 4, stroke: props.style?.stroke?props.style?.stroke:'FFF'}}
        />;
    }
    const {sourceX, sourceY, targetX, targetY, id, markerEnd, style} = props;
    const radiusY = 200;
    const radiusX = 200;
    const edgePath = `M ${sourceX} ${sourceY} A ${radiusX} ${radiusY} 0 1 1 ${
        targetX + 2
    } ${targetY}`;

    return <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{strokeWidth: 4, stroke: style?.stroke?style?.stroke:'FFF'}}
    />;
}
