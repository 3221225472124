import React from 'react';
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";

// components
import BaseApi from "../../utils/BaseApi";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";

// icons
import BoltIcon from '@mui/icons-material/Bolt'


class EstimationRevisionSetCurrent extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            revision: this.props.revision,
            updatePermission: 0,
        };
    }

    async componentDidMount(){
        await this.retrieveUpdatePermission();
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'EstimationRevisionViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        this.setState(
            {
                updatePermission: p
            }
        );
    }

    async setCurrentRevision() {
        const url = `estimation_revision/${this.props.revision.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            {
                current: !this.state.revision.current
            }
        );
        if (response.status === 200){
            this.setState(
                {
                    revision: response.data,
                }
            );
            await this.props.callBack();
        }
    }

    render() {
        return(
            <>
                {
                    this.state.updatePermission > 0 ?
                        <Box
                            sx={{
                                display: 'inline'
                            }}
                        >
                            <IconButton
                                size={'sm'}
                                color={this.state.revision.current?'warning':'neutral'}
                                onClick={this.setCurrentRevision.bind(this)}
                            >
                                <BoltIcon />
                            </IconButton>
                        </Box> :
                        null
                }
            </>
        )
    }
}

export default EstimationRevisionSetCurrent