import React from "react";

import Avatar from "@mui/joy/Avatar";

// components
import UserSubscriberContext from "../../context/UserSubscriberContext";
import {retrieveImage} from "../../utils/misc";
import withNavigate from "../../utils/withNavigate";


class UserAvatar extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            image: null,
        };
    }

    async componentDidMount() {
        await this.retrieveImage();
    }

    async retrieveImage(){
        let image = await retrieveImage(
            'Subscriber',
            'image',
            this.context.subscriberObject.id
        )
        this.setState(
            {
                image: image
            }
        );
    }

    render() {
        return(
            <Avatar
                onClick={() => (this.props.navigate('/profile/'))}
                src={`data:image/jpeg;base64,${this.state.image}`}
            />
        )
    }
}

export default withNavigate(UserAvatar);
