import React from "react";

import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";

// components
import BaseApi from "../../../utils/BaseApi";

// icons
import SettingsIcon from '@mui/icons-material/Settings';
import CommonJSONFieldForm from "../../Common/CommonJSONFieldForm";


class RouteActionCreateParams extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    async componentDidMount(){
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async processForm(form) {
        const url = `route_stage_action_create/${this.props.obj.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            {params: form}
        );
        if (response.status === 200) {
            await this.setModalOpenFalse();
        }
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        await this.props.callBack();
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Link
                    level="body-xs"
                    color={'warning'}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <SettingsIcon/>
                </Link>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h2'}
                                sx={{mb: 2}}
                            >
                                Редактирование параметров порождения
                            </Typography>
                            <CommonJSONFieldForm
                                value={this.props.obj.params}
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default RouteActionCreateParams
