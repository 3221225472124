import React from "react";
import ReconnectingWebSocket from 'reconnecting-websocket';

import Sheet from "@mui/joy/Sheet";
import Box from "@mui/joy/Box";
import {CircularProgress, Stack} from "@mui/joy";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";

// components
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";

// Icons
import TelegramIcon from '@mui/icons-material/Telegram';

// context
import UserSubscriberContext from "../../context/UserSubscriberContext";
import CommentAttachmentAdd from "./CommentAttachmentAdd";
import CommentAttachment from "./CommentAttachment";
import BaseApi from "../../utils/BaseApi";


class CommentSidebar extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.editor = React.createRef();
        this.state = {
            comment_form: <CommentForm
                ref={this.editor}
            />,
            attachments: [],
            ready: true,
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }

    async postComment(){
        this.setState(
            {ready: false,}
        );
        let data = {
            type: "comment",
            content: this.editor.current.state.value,
            reply_to: this.editor.current.state.replyTo?.id,
            related_app: this.props.appName,
            related_app_id: this.props.appId,
        };
        if (this.state.attachments.length){
            data.attachments = this.state.attachments
        }
        const api = new BaseApi();
        const url = 'comment/'
        let response = await api.post(
            url,
            data
        );
        if (response.status === 201){
            let r = (Math.random() + 1).toString(36).substring(7);
            this.setState(
            {
                    comment_form: <CommentForm
                        ref={this.editor}
                        initialValue={''}
                        key={r}
                    />,
                    attachments: [],
                    ready: true,
                },
            );
        }
    }

    reply(comment) {
        this.editor.current.reply(comment)
    }

    addAttachment(id){
        let attachments = this.state.attachments;
        attachments.push(id)
        this.setState({
            attachments: attachments
        })
    }

    render() {
        if (!this.state.comment_form) {
            return (<CircularProgress />)
        }
        return(
            <Sheet
                variant="plain"
                color="neutral"
                sx={
                    {
                        alignContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 'md',
                        width: '100%',
                        height: '100%',
                        justifyContent: "space-between",
                        alignItems: 'stretch',
                    }
                }
            >
                <Box sx={
                    {
                        display: 'flex',
                        width: '100%',
                        overflowY: 'scroll',
                    }
                }>
                    <CommentList
                        replyCallBack={this.reply.bind(this)}
                        appId={this.props.appId}
                        appName={this.props.appName}
                    />
                </Box>
                <Box sx={
                    {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }>
                    <Divider
                        sx={{
                            mt: 2,
                            mb: 2
                        }}
                    />
                    {this.state.comment_form}
                    <CommentAttachmentAdd
                        callBack={this.addAttachment.bind(this)}
                        appId={this.props.appId}
                        appName={this.props.appName}
                    />
                    <Stack
                        spacing={1}
                    >
                        {
                            this.state.attachments.map(
                                (a) => (
                                    <CommentAttachment
                                        id={a}
                                    />
                                )
                            )
                        }
                    </Stack>
                    <Stack
                        direction={'row'}
                    >
                    </Stack>
                    <Button
                        disabled={!this.state.ready}
                        aria-label="commentSubmit"
                        size={'sm'}
                        color="primary"
                        onClick={
                            this.postComment.bind(this)
                        }
                        sx={{mt: 1}}
                    >
                        <TelegramIcon sx={{mr: 2}}/> Отправить
                    </Button>
                </Box>
            </Sheet>
        )
    }
}

export default CommentSidebar;
