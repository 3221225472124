import React from "react";

import Box from "@mui/joy/Box";
import {Stack} from "@mui/joy";

// components
import OpportunitySearchCard from "./OpportunitySearchCard";
import TaskSearchCard from "./TaskSearchCard";
import AccountSearchCard from "./AccountSearchCard";
import ContactSearchCard from "./ContactSearchCard";
import FeatureRequestSearchCard from "./FeatureRequestSearchCard";


class SearchResults extends React.Component {
    renderItem(item){
        switch(item.model){
            case 'Opportunity':
                return (
                    <OpportunitySearchCard
                        opportunityId={item.id}
                        callBack={this.props.callBack}
                    />
                )
            case 'Task':
                return (
                    <TaskSearchCard
                        taskId={item.id}
                    />
                )
            case 'Account':
                return (
                    <AccountSearchCard
                        accountId={item.id}
                        callBack={this.props.callBack}
                    />
                )
            case 'Contact':
                return (
                    <ContactSearchCard
                        contactId={item.id}
                        callBack={this.props.callBack}
                    />
                )
            case 'FeatureRequest':
                return (
                    <FeatureRequestSearchCard
                        featureRequestId={item.id}
                        callBack={this.props.callBack}
                    />
                )
            default:
                return(
                    <></>
                )
        }
    }
    render() {
        return (
            <Box
                sx={{
                    overflowY: 'scroll',
                    width: 'calc(80vw)',
                    pr: 2
                }}
            >
                <Stack
                    spacing={2}
                >
                    {this.props.results.map(each => {
                        return(
                            <>
                                {this.renderItem(each)}
                            </>
                        )
                    })}
                </Stack>
            </Box>
        )
    }
}

export default SearchResults