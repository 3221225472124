import React from 'react';

import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';

// components
import BaseApi from "../../utils/BaseApi";
import withRouter from "../../utils/withRouter";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";


class MarketingUserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            pages: null,
            filters: {},
        };
    }

    async componentDidMount() {
        await this.retrieveUsers();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveUsers();
        }
    }

    async filterCallBack(filters){
        await this.setState({
            filters: filters
        });
        await this.retrieveUsers();
    }

    async retrieveUsers() {
        this.setState(
            {
                users: null,
            }
        );
        const url = 'marketing_user/';
        const api = new BaseApi();
        let params = {};
        params = {...params, ...this.state.filters};
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        let users = response.data.results
        for (let user of users) {
            user.webinars = (await api.get('webinar/', {attendees: user.id})).data.results
        }
        this.setState(
            {
                users: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.users) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedTable
                    title={'HUB.Пользователи'}
                    url={'admin/marketing_user'}
                    callBack={this.retrieveUsers.bind(this)}
                    // filterFields={[
                    //     {
                    //             name: 'work_status',
                    //             label: 'Статус',
                    //             xs: 12,
                    //             sm: null,
                    //             md: null,
                    //             lg: null,
                    //             xl: null,
                    //             required: true,
                    //             default: null,
                    //             type: 'select',
                    //             options: [
                    //                 {value: 'PSD', label: 'На рассмотрении'},
                    //                 {value: 'WRK', label: 'В работе'},
                    //                 {value: 'COM', label: 'Завершена'},
                    //                 {value: 'CLD', label: 'Отклонена'},
                    //             ]
                    //         },
                    // ]}
                    // filterCallBack={this.filterCallBack.bind(this)}
                    data={this.state.users}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    fields={[
                        {
                            title: 'Фамилия',
                            name: 'last_name',
                            visible: true,
                            linkPattern: '/admin/marketing_user/detail/:linkParam',
                            linkParam: 'id'
                        },
                        {
                            title: 'Имя',
                            name: 'first_name',
                            visible: true,
                        },
                        {
                            title: 'Отчество',
                            name: 'patronymic',
                            visible: true,
                        },
                        {
                            title: 'Компания',
                            name: 'account_title',
                            visible: true,
                        },
                        {
                            title: 'Должность',
                            name: 'role',
                            visible: true,
                        },
                        {
                            title: 'Эл. почта',
                            name: 'email',
                            visible: true,
                        },
                        {
                            title: 'Телефон',
                            name: 'phone',
                            visible: true,
                        },
                        {
                            title: 'Перс. данные',
                            name: 'personal_data',
                            visible: true,
                        },
                        {
                            title: 'Рассылка',
                            name: 'mailing',
                            visible: true,
                        },
                        {
                            title: 'Вебинары',
                            name: 'webinars.length',
                            visible: true,
                            chip: true,
                            color_rule: (value, comparison) => (value>0?'success':'error'),
                            color_value: 'webinars.length',
                        },
                        {
                            title: 'Последний логин',
                            name: 'last_login',
                            visible: true,
                        },
                    ]
                    }
                />
            </Box>
        )
    }
}

export default withRouter(MarketingUserList)
