import React from "react";

// components
import CommonForm from "../Common/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonStepper from "../Common/CommonStepper";
import {retrieveModelField} from "../../utils/misc";

// icons
import {CircularProgress} from "@mui/joy";


class LeadConvert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            account: null,
            contact: null,
            opportunity: null,
            formFields: null,
        };
        this.steps = [
            {
                order: 0,
                title: 'Компания',
            },
            {
                order: 1,
                title: 'Контакт',
            },
            {
                order: 2,
                title: 'Сделка',
            },
            {
                order: 3,
                title: ' Роль контакта ',
            },
        ]
    }

    async componentDidMount() {
        let accountField = await retrieveModelField(
            'account',
            {
                page_size: 10000,
            },
            'account',
            'Компания',
            'id',
            ['nameshort',],
            'Создать новую',
            true
        )
        this.setState(
            {
                formFields: [
                    accountField,
                    {
                        type: 'submit',
                        label: 'Далее',
                    },
                ]
            }
        );
    }

    async retrieveRoleField(){
        const url = `opp_contact_team/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        for (let field of response.data) {
            if (field.name === 'role'){
                field.required = true;
                return field
            }

        }
    }

    async processStep0(form) {
        let account = form.state.formData.get('account');
        if (account === 'NaN'){
            account = null
        }
        if (account){
            let contactField = await retrieveModelField(
                'contact',
                {
                    account: account,
                    page_size: 10000,
                },
                'contact',
                'Контакт',
                'id',
                ['last_name', 'first_name', 'patronymic',],
            'Создать новый',
                true
            )
            this.setState(
                {
                    account: account,
                    formFields: [
                        contactField,
                        {
                            type: 'submit',
                            label: 'Далее',
                        },
                    ],
                    activeStep: 1,
                }
            );
            return
        }
        let roleField = await this.retrieveRoleField();
        this.setState(
            {
                formFields: [
                    roleField,
                    {
                        type: 'submit',
                        label: 'Далее',
                    },
                ],
                activeStep: 3,
            }
        );
    }

    async processStep1(form) {
        let contact = form.state.formData.get('contact');
        if (contact === 'NaN'){
            contact = null
        }
        let opportunityField = await retrieveModelField(
            'opportunity',
            {
                account: this.state.account,
                page_size: 10000,
            },
            'opportunity',
            'Сделка',
            'id',
            ['short_name',],
            'Создать новую',
            true
        )
        this.setState(
            {
                contact: contact,
                formFields: [
                    opportunityField,
                    {
                        type: 'submit',
                        label: 'Далее',
                    },
                ],
                activeStep: 2,
            }
        );
    }

    async processStep2(form) {
        let opportunity = form.state.formData.get('opportunity');
        if (opportunity === 'NaN'){
            opportunity = null
        }
        let roleField = await this.retrieveRoleField();
        this.setState(
            {
                opportunity: opportunity,
                formFields: [
                    roleField,
                    {
                        type: 'submit',
                        label: 'Далее',
                    },
                ],
                activeStep: 3,
            }
        );
    }

    async processStep3(form) {
        let role = form.state.formData.get('role');
        await this.props.callBack(
            {
                account: this.state.account,
                contact: this.state.contact,
                opportunity: this.state.opportunity,
                role: role,
            }
        )
    }

    async setStep(order) {
        let formData = new FormData();
        formData.set('account', this.state.account);
        formData.set('contact', this.state.contact);
        formData.set('opportunity', this.state.opportunity);
        let formFake = {'state': {'formData': formData}}
        if (order === 0){
            await this.componentDidMount()
        }
        else {
            await this['processStep'+(order-1)](formFake)
        }
        this.setState(
            {activeStep: order}
        )
    }

    render() {
        if (!this.state.formFields) {
            return (<CircularProgress />)
        }
        if (this.state.activeStep === 0) {
            return (
                <>
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        fields={this.state.formFields}
                        processForm={this.processStep0.bind(this)}
                    />
                </>
            )
        }
        else if (this.state.activeStep === 1) {
            return (
                <>
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        fields={this.state.formFields}
                        processForm={this.processStep1.bind(this)}
                    />
                </>
            )
        }
        else if (this.state.activeStep === 2) {
            return (
                <>
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        fields={this.state.formFields}
                        processForm={this.processStep2.bind(this)}
                    />
                </>
            )
        }
        else if (this.state.activeStep === 3) {
            return (
                <>
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        fields={this.state.formFields}
                        processForm={this.processStep3.bind(this)}
                    />
                </>
            )
        }
    }
}

export default LeadConvert
