// components
import CommonForm from "./CommonForm";
import BaseApi from "../../utils/BaseApi";
import {setFormFieldKey, splitToList} from "../../utils/misc";
import CommonActionModal from "./CommonActionModal";

// icons
import EditIcon from '@mui/icons-material/Edit';


class CommonEditModal extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: EditIcon,
            buttonLabel: null,
            buttonIconOnly: this.props.buttonIconOnly,
            buttonColor: 'default',
            ready: true,
            permission: this.props.permission?this.props.permission:true,
            deniedPermissionComponent: this.props.deniedPermissionComponent,
            flat: this.props.flat,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: '',
            modalIcon: null,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
                object: this.props.obj,
            },
        }
    }

    async retrieveFields() {
        const url = `${this.props.baseUrl}/update_form/`;
        const api = new BaseApi();
        const params = {
            fields: JSON.stringify(
                this.props.m2mFields?
                    this.props.fields.concat(this.props.m2mFields):
                    this.props.fields
            )
        }
        let response = await api.get(
            url,
            params
        );
        let formFields = response.data;
        if (this.props.fieldUpdates){
            for (let field of this.props.fieldUpdates){
                for (let update of field.updates){
                    formFields = setFormFieldKey(
                        formFields,
                        field.name,
                        update.key,
                        update.value,
                    )
                }
            }
        }
        return formFields
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        if (this.props.m2mFields){
            for (const key in formDataObj) {
                if (this.props.m2mFields.indexOf(key) > -1){
                    formDataObj[key] = splitToList(formDataObj[key], ',')
                }
            }
        }
        const url = `${this.props.baseUrl}/${this.props.obj.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            formDataObj
        );
        if (response.status === 200 ){
            if (typeof this.props.callBack == 'function') {
                await this.props.callBack(response.data);
            }
            this.setModalOpenFalse();
        }
    }
}

export default CommonEditModal
