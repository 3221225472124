import React from 'react';

import {CircularProgress} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import withRouter from "../../utils/withRouter";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";
import NotificationDetailModal from "./NotificationDetailModal";

// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import CommonCallBackButton from "../Common/CommonCallBackButton";


class NotificationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveNotifications();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveNotifications();
        }
    }

    async readAll(){
        const api = new BaseApi();
        let url = 'notification/read_all';
        await api.get(url, {});
        await this.retrieveNotifications();
    }

    async retrieveNotifications() {
        const api = new BaseApi();
        let url;
        if (this.props.params.notificationId){
            url = `notification/${this.props.notificationId}/`;
            const data = {
                is_read: true,
            };
            await api.patch(
                url,
                data,
            );
        }
        url = 'notification/';
        let params = {}
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                notifications: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!(this.state.notifications && this.state.pages)) {
            return (<CircularProgress/>)
        }
        return (
            <>
                <CommonPaginatedTable
                    title={'Уведомления'}
                    url={'notification'}
                    data={this.state.notifications}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    color={{
                        rule: (value, comparison) => (!value?'warning':null),
                        value: 'is_read',
                        comparison: '',
                    }}
                    extraComponents={[
                        {
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                label: 'Прочитать все',
                                icon: VisibilityIcon,
                                color: 'neutral',
                            },
                            callBack: this.readAll.bind(this)
                        }
                    ]}
                    fields={[
                        {
                            title: '',
                            name: 'title',
                            visible: true,
                            width: '70%',
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: NotificationDetailModal,
                            callBack: this.retrieveNotifications.bind(this)
                        },
                        {
                            title: 'Создано',
                            name: 'created_on',
                            visible: true,
                        },
                    ]
                    }
                />
                {
                    this.props.params.notificationId?
                        <NotificationDetailModal
                            notificationId={this.props.params.notificationId}
                            modalOpen={true}
                            callBack={this.retrieveNotifications.bind(this)}
                        />:
                        null
                }
            </>
        )
    }
}

export default withRouter(NotificationList);
