// components
import CommonForm from ".//CommonForm";
import CommonActionModal from ".//CommonActionModal";

// icons
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from "@mui/icons-material/Close";


class CommonFilterModal extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: TuneIcon,
            buttonLabel: 'Фильтры',
            buttonColor: 'primary',
            extraButtons: [
                {
                    label: 'Сбросить',
                    icon: CloseIcon,
                    color: 'warning',
                    onClick: this.discardFilters.bind(this)
                }
            ],
            ready: true,
        })
    }

    async beforeModalOpen(){
        return {
            modalLabel: 'Фильтры',
            modalIcon: TuneIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: this.props.filterFields.concat(
                    [
                        {
                            type: 'submit',
                            label: 'Применить',
                        },
                    ]
                ),
                processForm: this.processForm.bind(this),
            },
        }
    }

    discardFilters() {
        this.props.callBack({});
    }

    processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        this.props.callBack(formDataObj);
        this.setModalOpenFalse();
    }

}

export default CommonFilterModal