import React from 'react';
import CommonInfoTab from "../Common/CommonInfoTab";


class FeatureRequestTab extends React.Component {
    render() {
        return(
            <CommonInfoTab
                groups={
                    [
                        {
                            fields: [
                                {
                                    title: 'Автор',
                                    value: `${this.props.featureRequest.owner.first_name} ${this.props.featureRequest.owner.last_name}`,
                                    html: true,
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                    lg: 6,
                                },
                                {
                                    title: 'Добавлено',
                                    value: this.props.featureRequest.created_on,
                                    html: true,
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                    lg: 6,
                                },
                                {
                                    title: 'Описание',
                                    value: this.props.featureRequest.desc,
                                    html: true,
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                },
                            ]
                        },
                    ]
                }
            />
        )
    }
}


export default FeatureRequestTab