import React from 'react';
import CommonSidebar from "../Common/CommonSidebar";

//icons
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import KeyIcon from '@mui/icons-material/Key';
import CastIcon from '@mui/icons-material/Cast';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GroupIcon from '@mui/icons-material/Group';


class AdminSidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={[
                    {
                        title: 'Администрирование',
                        items: [
                            {
                                title: 'Бизнес-процессы',
                                link: '/admin/delivery/',
                                icon: AccountTreeIcon,
                            },
                            {
                                title: 'Статистика БП',
                                link: '/admin/delivery_stats/',
                                icon: QueryStatsIcon,
                            },
                            {
                                title: 'Маршруты',
                                link: '/admin/route/',
                                icon: ForkLeftIcon,
                            },
                            {
                                title: 'Права',
                                link: '/admin/permission/',
                                icon: KeyIcon,
                            },
                        ]
                    },
                ]}
            />
        )
    }
}

export default AdminSidebar;