import React from "react";

// components
import CommonInfoTab from "../Common/CommonInfoTab";

// icons
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";


class ContractReadOnlyDetail extends React.Component {
    render() {
        return(
            <CommonInfoTab
                groups={
                    [
                        {
                            title: 'Общая информация',
                            icon: <InfoIcon/>,
                            fields: [
                                {
                                    title: 'Номер',
                                    value: this.props.contract.contract_number,
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                },
                                {
                                    title: 'Дата',
                                    value: dayjs(this.props.contract.contract_date).format('DD.MM.YYYY'),
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                },
                                {
                                    title: 'Тема',
                                    value: this.props.contract.contract_theme,
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                },
                                {
                                    title: 'Порядок сдачи работ',
                                    value: this.props.contract.contract_delivery_order,
                                    html: true,
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                },
                                {
                                    title: 'Количество печатных экз. отчета',
                                    value: this.props.contract.contract_print_number,
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                },
                                {
                                    title: 'Форма отчетности',
                                    value: this.props.contract.contract_report_form,
                                    fieldFromArray: 'title',
                                },
                                {
                                    title: 'Форма передачи электронных экземпляров',
                                    value: this.props.contract.contract_elec_report_form,
                                    fieldFromArray: 'title',

                                },
                            ]
                        }
                    ]
                }
            />
        )
    }
}

export default ContractReadOnlyDetail
