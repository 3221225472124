// components
import CommonForm from "../../Common/CommonForm";
import BaseApi from "../../../utils/BaseApi";
import CommonActionModal from "../../Common/CommonActionModal";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableViewIcon from "@mui/icons-material/TableView";
import {setFormFieldKey} from "../../../utils/misc";


class TeamCreate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Добавление в команду',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        let url = `opp_team/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let formFields = response.data
        url = `subscriber/`;
        response = await api.get(
            url,
            {
                active: true,
            }
        );
        formFields = setFormFieldKey(
            formFields,
            'subscriber',
            'options',
            response.data.results.map((each) => (
                {'value': each.id, 'label': `${each.user.first_name} ${each.user.last_name}`}
            ))
        )
        return formFields
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.opportunity = this.props.opportunity.id
        const url = `opp_team/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            await this.props.callBack();
            this.setModalOpenFalse()
        }
    }
}

export default TeamCreate
