// components
import CommentSidebar from "../Comment/CommentSidebar";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import CommentIcon from '@mui/icons-material/Comment';


class IdeaNodeComments extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: CommentIcon,
            buttonLabel: 'Комментарии',
            buttonColor: this.props.color,
            ready: true,
        })
    }

    async beforeModalOpen(){
        return {
            modalLabel: 'Добавление идеи',
            modalIcon: CommentIcon,
            mainComponent: CommentSidebar,
            mainComponentProps: {
                appName: 'IdeaNode',
                appId: this.props.ideaNode.id,
            },
        }
    }
}

export default IdeaNodeComments
