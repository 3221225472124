import React from "react";

import {Chip, Stack} from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";

// components
import CommonEditModal from "./CommonEditModal";
import {generatePath} from "react-router-dom";


class CommonInfoTab extends React.Component {
    render() {
        return(
            <Stack>
                {this.props.groups.map((group) => (
                    <Card
                        sx={{
                            mb: 2,
                            border: this.props.flat?0:null,
                            p: this.props.flat?0:null,
                            backgroundColor: this.props.flat?'rgba(0,0,0,0)':null,
                        }}
                    >
                        <CardContent>
                            {
                                (group.title || group.icon)?
                                    <Stack
                                        direction={'row'}
                                        spacing={2}
                                        sx={{pb: 2}}
                                    >
                                        {
                                            group.icon?
                                                group.icon:
                                                null
                                        }
                                        <Typography level="title-md">
                                            {
                                                group.linkPattern?
                                                    <Link
                                                        href={
                                                            group.linkPattern&&group.linkParam?generatePath(group.linkPattern, { linkParam: group.linkParam}):null
                                                        }
                                                    >
                                                        {group.title}
                                                    </Link>:
                                                    group.title
                                            }
                                        </Typography>
                                    </Stack>:
                                    null
                            }
                            <Grid
                                container={true}
                                spacing={group.flat?1:2}
                                sx={{
                                    flexGrow: 1
                                }}
                            >
                                {group.fields.map((field) => (
                                    <Grid
                                        xs={field.xs?field.xs:12}
                                        sm={field.sm?field.sm:12}
                                        md={field.md?field.md:6}
                                        lg={field.lg?field.lg:6}
                                    >
                                        <Card
                                            variant={field.flat?null:'soft'}
                                            sx={{
                                                border: field.flat?0:null,
                                                p: field.flat?0:null,
                                                backgroundColor: field.flat?'rgba(0,0,0,0)':null,
                                            }}
                                        >
                                            <Stack
                                                direction={'row'}
                                                spacing={1}
                                            >
                                                <Typography level="body-xs">
                                                    {field.title}
                                                </Typography>
                                                {
                                                    field.edit && field.edit.permission?
                                                        <CommonEditModal
                                                            fields={field.edit.fields}
                                                            m2mFields={field.edit.m2mFields}
                                                            baseUrl={field.edit.baseUrl}
                                                            obj={field.edit.obj}
                                                            callBack={field.edit.callBack?field.edit.callBack:this.props.callBack}
                                                            buttonIconOnly={true}
                                                            fieldUpdates={field.edit.fieldUpdates}
                                                        />:
                                                        null
                                                }
                                            </Stack>
                                            {
                                                field.value===null || field.value===''?
                                                <Chip
                                                    color="danger"
                                                >
                                                    Не указано
                                                </Chip>:
                                                    Array.isArray(field.value)?
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={1}
                                                            useFlexGap
                                                            sx={{ flexWrap: 'wrap' }}
                                                        >
                                                            {field.value.map((v) => (
                                                                <Chip
                                                                    color={'primary'}
                                                                    sx={{
                                                                        maxWidth: '100%',
                                                                    }}
                                                                >
                                                                    {v[field.fieldFromArray]}
                                                                </Chip>
                                                            ))}
                                                        </Stack>:
                                                        <Box>
                                                            {
                                                                field.html?
                                                                    <div dangerouslySetInnerHTML={{__html: field.value}} />:
                                                                    field.linkPattern?
                                                                        <Link
                                                                            href={
                                                                                field.linkPattern&&field.linkParam?generatePath(field.linkPattern, { linkParam: field.linkParam}):field.linkPattern?field.linkPattern:null
                                                                            }
                                                                            target={field.linkTarget?field.linkTarget:null}
                                                                        >
                                                                            {field.value}
                                                                        </Link>:
                                                                            typeof field.value === 'boolean'?
                                                                                field.value?<Chip color="success">Да</Chip>:<Chip color="danger">Нет</Chip>:
                                                                                field.value
                                                            }
                                                        </Box>
                                            }
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
            </Stack>
        )
    }
}

export default CommonInfoTab;
