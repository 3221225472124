import React from 'react';

import CssBaseline from "@mui/joy/CssBaseline";
import {CssVarsProvider} from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";

import FormLabel, {formLabelClasses} from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

// components
import AuthContext from "../../context/AuthContext";
import ColorSchemeToggle from "../Common/ColorSchemeToggle";
import withColorScheme from "../../utils/withColorScheme";


class LoginInner extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            error: '',
        }
    }

    async onFormSubmit(e){
        e.preventDefault();
        const userData = {
            username: e.target.elements[0].value,
            password: e.target.elements[1].value,
        };
        const response = await this.context.loginUser(userData);
        if (response.status !== 201){
            this.setState(
                {
                    error: response.data.detail
                }
            )
        }
        setTimeout(()=>{
            this.setState(
                {
                    error: ""
                }
            )
        },2000)
    }

    render() {
        return (
            <>
                <CssBaseline/>
                <GlobalStyles
                    styles={{
                        ':root': {
                            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                            '--Cover-width': '40vw', // must be `vw` only
                            '--Form-maxWidth': '700px',
                            '--Transition-duration': '0.4s', // set to `none` to disable transition
                        },
                    }}
                />
                <Box
                    sx={(theme) => ({
                        width:
                            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                        transition: 'width var(--Transition-duration)',
                        transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                        position: 'relative',
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        backdropFilter: 'blur(4px)',
                        backgroundColor: 'rgba(255 255 255 / 0.6)',
                        [theme.getColorSchemeSelector('dark')]: {
                            backgroundColor: 'rgba(19 19 24 / 0.4)',
                        },
                    })}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '100dvh',
                            width:
                                'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                            maxWidth: '100%',
                            px: 2,
                        }}
                    >
                        <Box
                            component="header"
                            sx={{
                                py: 3,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <img
                                src={'https://multiphysics.ru/static/logo_web_h34px.svg'}
                                style={{
                                    filter: (
                                        this.props.colorScheme.colorScheme === 'dark'?
                                            'brightness(0%) contrast(100%) invert(100%)':
                                            ''
                                    ),
                                }}
                                alt={'logo'}
                            />
                            <ColorSchemeToggle/>
                        </Box>
                        <Box
                            component="main"
                            sx={{
                                my: 'auto',
                                py: 2,
                                pb: 5,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                width: 400,
                                maxWidth: '100%',
                                mx: 'auto',
                                borderRadius: 'sm',
                                '& form': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                },
                                [`& .${formLabelClasses.asterisk}`]: {
                                    visibility: 'hidden',
                                },
                            }}
                        >
                            <div>
                                <Typography component="h2" fontSize="xl2" fontWeight="lg">
                                    Добро пожаловать!
                                </Typography>
                            </div>
                            <form onSubmit={this.onFormSubmit.bind(this)}>
                                <FormControl required>
                                    <FormLabel>Имя пользователя</FormLabel>
                                    <Input placeholder="" name="username"/>
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Пароль</FormLabel>
                                    <Input placeholder="•••••••" type="password" name="password"/>
                                </FormControl>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        color: "red",
                                    }}
                                >
                                    {this.state.error}
                                </Box>
                                <Button type="submit" fullWidth>
                                    Войти
                                </Button>
                            </form>
                        </Box>
                        <Box component="footer" sx={{py: 3}}>
                            <Typography level="body3" textAlign="center">
                                © АО "ЦИФРА" {new Date().getFullYear()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        height: '100%',
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                        transition:
                            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                        transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                        backgroundColor: 'background.level1',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: (() => {
                            if (this.props.colorScheme.colorScheme === 'dark'){
                                return 'url(https://images.unsplash.com/photo-1465101162946-4377e57745c3)'
                            }
                            return 'url(https://images.unsplash.com/photo-1525490829609-d166ddb58678)'
                        })()
                    }}
                />
            </>
        )
    }
}

export default withColorScheme(LoginInner);
