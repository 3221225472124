// components
import BaseApi from "../../utils/BaseApi";
import LeadConvert from "./LeadConvert";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import FastForwardIcon from '@mui/icons-material/FastForward';


class LeadConvertModal extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: FastForwardIcon,
            buttonLabel: 'Конвертировать',
            buttonColor: 'success',
            ready: true,
        })
    }

    async beforeModalOpen(){
        return {
            modalLabel: 'Конвертация лида',
            modalIcon: FastForwardIcon,
            mainComponent: LeadConvert,
            mainComponentProps: {
                callBack: this.convert.bind(this),
            },
        }
    }

    async convert(data) {
        const url = `lead/${this.props.lead.id}/convert/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            data
        );
        if (response.status === 200) {
            if (typeof this.props.callBack == 'function') {
                await this.props.callBack();
            }
            this.setModalOpenFalse();
        }
    }
}

export default LeadConvertModal
