import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';
import withRouter from "../../utils/withRouter";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";
import FeatureRequestCreateModal from "./FeatureRequestCreateModal";


class FeatureRequestIdeaList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feature_requests: [],
            pages: null,
            filters: {},
        };
    }

    async componentDidMount() {
        await this.retrieveFeatureRequests();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveFeatureRequests();
        }
    }

    async filterCallBack(filters){
        await this.setState({
            filters: filters
        });
        await this.retrieveFeatureRequests();
    }

    async retrieveFeatureRequests() {
        const url = 'feature_request/';
        const api = new BaseApi();
        let params = {
            xtype: 'IDEA',
        };
        params = {...params, ...this.state.filters};
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                feature_requests: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.feature_requests) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedTable
                    title={'Идеи'}
                    url={'feature_request/idea'}
                    createComponent = {FeatureRequestCreateModal}
                    createComponentProps={{
                        xtype: 'IDEA'
                    }}
                    callBack={this.retrieveFeatureRequests.bind(this)}
                    filterFields={[
                        {
                                name: 'work_status',
                                label: 'Статус',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: null,
                                type: 'select',
                                options: [
                                    {value: 'PSD', label: 'На рассмотрении'},
                                    {value: 'WRK', label: 'В работе'},
                                    {value: 'COM', label: 'Завершена'},
                                    {value: 'CLD', label: 'Отклонена'},
                                ]
                            },
                    ]}
                    filterCallBack={this.filterCallBack.bind(this)}
                    data={this.state.feature_requests}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    fields={[
                        {
                            title: 'Заголовок',
                            name: 'title',
                            visible: true,
                            linkPattern: '/feature_request/detail/:linkParam',
                            linkParam: 'id',
                            linkTarget: '_blank',
                        },
                        {
                            title: 'Автор',
                            name: 'owner.first_name',
                            visible: true,
                        },
                        {
                            title: '',
                            name: 'owner.last_name',
                            visible: true,
                        },
                        {
                            title: 'Статус',
                            name: 'work_status_human',
                            visible: true,
                            chip: true,
                            color_rule: (value) => (value),
                            color_value: 'work_status_color',
                        },
                        {
                            title: 'Создано',
                            name: 'created_on',
                            visible: true,
                        },
                        {
                            title: 'Обновлено',
                            name: 'updated_on',
                            visible: true,
                        },
                    ]
                    }
                />
            </Box>
        )
    }
}

export default withRouter(FeatureRequestIdeaList)