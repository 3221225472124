import React from 'react';

import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import {Slider} from "@mui/joy";


// components
import BaseApi from "../../utils/BaseApi";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class TaskDuration extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            task: this.props.task
        };
    }

    async handleSlider(event, newValue){
        const api = new BaseApi();
        const url = `task/${this.props.task.id}/`;
        let response = await api.patch(
            url,
            {
                complete: newValue
            }
        );
        this.setState(
            {
                task: response.data
            }
        );
    }

    render() {
        return(
            <Card>
                <CardContent>
                    <Typography
                        level={'body-md'}
                        sx={{mb: 2}}
                    >
                        <strong>Процент выполнения:</strong>
                    </Typography>
                    <Slider
                        disabled={
                            ['COM', 'DEL', 'CLD', 'DCL'].includes(this.state.task.work_status) ||
                            this.state.task.executor.id !== this.context.userObject.id
                        }
                        defaultValue={this.state.task.complete}
                        max={100}
                        valueLabelDisplay="on"
                        marks
                        step={1}
                        onChange={this.handleSlider.bind(this)}
                    />
                </CardContent>
            </Card>
        )
    }
}

export default TaskDuration