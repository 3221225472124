import BaseApi from "./BaseApi";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";


export function setAuthTokens(access, refresh){
    localStorage.setItem(
        'authTokens',
        JSON.stringify({
            access: access,
            refresh: refresh
        })
    );
}


export function getNestedField(obj, name, booleanObject=true, preProcess=null){
    let fields = name.split('.');
    let value = obj;
    for (let field of fields) {
        if (!value) {
            return 'N/A'
        }
        value = value[field];
    }
    if (typeof value === 'boolean' && booleanObject){
        if (value) {
            return <CheckCircleIcon color={"success"}/>
        }
        return <HighlightOffIcon color={"error"}/>
    }
    if (preProcess){
        return preProcess(value)
    }
    return value
}

export function customRange(start, stop, step) {
    let a = [start], b = start;
    while (b < stop) {
        a.push(b += step || 1);
    }
    return a;
}


export function reorder(list, startIndex, endIndex){
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}


export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function compareNumbers(a, b) {
  return a - b;
}

export function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return dates;
}

export function compareDates(a, b) {
    let keyA = new Date(a.date);
    let keyB = new Date(b.date);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
}

export function splitToList(value, delimiter) {
    if (value === '') {
        value = [];
    }
    else{
        value = value.split(delimiter);
    }
    return value
}

export async function retrieveModelField(model, params, field, fieldLabel, optionValue, optionLabelList, emptyLabel, required){
    const url = model + '/';
    const api = new BaseApi();
    let response = await api.get(url, params);
    return {
        name: field,
        label: fieldLabel,
        xs: 12,
        sm: null,
        md: null,
        lg: null,
        xl: null,
        required: required,
        default: !!emptyLabel?'NaN':null,
        type: 'select',
        emptyLabel: emptyLabel,
        empty: !!emptyLabel,
        options: response.data.results.map(each => {
            let label = '';
            for (let optionLabel of optionLabelList) {
                label += getNestedField(each, optionLabel);
                label += ' ';
            }
            label = label.slice(0, -1);
            return(
                {
                    value: getNestedField(each, optionValue),
                    label: label
                }
            )
        })
    }
}


export function arrayUniqueByKey(arr, key){
    return [
        ...new Map(
            arr.map(
                item => [getNestedField(item, key), item]
            )
        ).values()
    ];
}


export function setFormFieldKey(formFields, name, key, value){
    for (let field of formFields){
        if (field.name === name) {
            field[key] = value
        }
    }
    return formFields
}


export function deleteArrayItemByKeyValue(array, key, value){
    return array.filter((item) => (item[key] !== value))
}


export function findArrayItemsByKeyValue(array, key, value){
    return array.filter((item) => (item[key] === value))
}


export function b64toBlob(b64Data, sliceSize = 512){
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return byteArrays;
}


export function downloadBlob(data, filename, type='text/plain') {
    let blob = new Blob(data, {
        type: type
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}


export async function retrieveImage(model, field, id){
    const url = 'image/';
    const params = {
        model: model,
        field: field,
        id: id,
    };
    const api = new BaseApi();
    let response = await api.get(
        url,
        params
    );
    return response.data
}
