import dayjs from "dayjs";

// components
import CommonForm from "../Common/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import LightbulbIcon from '@mui/icons-material/Lightbulb';


class IdeaSolve extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: LightbulbIcon,
            buttonLabel: 'Принять решение',
            buttonColor: 'warning',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = [
            {
                name: 'decision',
                label: 'Решение',
                xs: 12,
                sm: null,
                md: null,
                lg: null,
                xl: null,
                required: true,
                default: null,
                type: 'select',
                options: [
                    {value: 'END', label: 'Завершить'},
                    {value: 'TASK', label: 'Конвертировать в задачу'},
                    {value: 'BRANCH', label: 'Добавить ветвление'},
                ]
            },
            {
                type: 'submit',
                label: 'Применить',
            },
        ]
        return {
            modalLabel: 'Принять решение',
            modalIcon: LightbulbIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async createTask(){
        const api = new BaseApi();
        const url = 'task/'
        const today = new Date();
        const data = {
            title: this.props.ideaNode.title,
            start_dt: dayjs(today).format('YYYY-MM-DD'),
            end_dt: dayjs(today).format('YYYY-MM-DD'),
            duration_plan: 0,
            desc: this.props.ideaNode.description,
            important_bool:true,
            account: 1,
            executor: this.props.ideaNode.owner.user.id,
        };
        let response = await api.post(
            url,
            data
        );
        return response.data.id
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        if (formDataObj.decision === 'TASK'){
            formDataObj.task = await this.createTask();
            formDataObj.decision = 'END'
        }
        const url = `idea_node/${this.props.ideaNode.id}/`;
        const api = new BaseApi();
        await api.patch(
            url,
            formDataObj
        );
        if (typeof this.props.callBack == 'function') {
            await this.props.callBack();
        }
        await this.setModalOpenFalse();
    }
}

export default IdeaSolve
