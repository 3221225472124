import React from 'react';

import {Checkbox, CircularProgress, Option, Select, Stack,} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

import {PieChart} from "@mui/x-charts/PieChart";
import {BarChart} from "@mui/x-charts/BarChart";

// components
import BaseApi from "../../utils/BaseApi";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import YearBarChartWidget from "./YearBarChartWidget";
import CalculatorItemData from "./CalculatorItemData";


class CalculatorItem extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            report: null,
            groupsConfig: null,
        };
    }

    async componentDidMount() {
        await this.retrieveReport();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps.item !== this.props.item){
            await this.retrieveReport();
        }
    }
    
    async repairGroupsConfig(data, oldConfig) {
        let currentYear = new Date().getFullYear()
        let groupsConfig = {}
        if (oldConfig){
            groupsConfig = oldConfig;
        }
        for (let group of Object.entries(data.group_stats)){
            if (!(group[0] in groupsConfig)){
                    groupsConfig[group[0]] = {
                    currentCategory: Object.keys(data.categories)[0],
                    year: currentYear,
                    legendIsHidden: false,
                }
            }
        }
        return groupsConfig
    }
    
    async updateGroupsConfig(groupsConfig){
        let newItem = this.props.item;
        newItem.groupsConfig = groupsConfig;
        this.setState({groupsConfig: groupsConfig});
        await this.props.updateItemCallBack(newItem);
    }
    
    async handleGroupYearChange(newValue, group){
        let groupsConfig = this.state.groupsConfig;
        groupsConfig[group].year = newValue;
        await this.updateGroupsConfig(groupsConfig);
    }

    async handleGroupCurrentCategoryChange(newValue, group){
        let groupsConfig = this.state.groupsConfig;
        groupsConfig[group].currentCategory = newValue;
        await this.updateGroupsConfig(groupsConfig);
    }

    async handleGroupLegendIsHiddenChange(group){
        let groupsConfig = this.state.groupsConfig;
        groupsConfig[group].legendIsHidden= !groupsConfig[group].legendIsHidden;
        await this.updateGroupsConfig(groupsConfig);
    }

    async retrieveReport() {
        this.setState(
            {
                report: null,
            }
        )
        const url = 'calculator/report/';
        const api = new BaseApi();
        let response = await api.post(
            url,
            this.props.item,
        );
        let groupsConfig = await this.repairGroupsConfig(
            response.data,
            this.props.item.groupsConfig
        );
        this.setState(
            {
                report: response.data,
                groupsConfig: groupsConfig,
            }
        );
    }

    async updateChartConfig(chartConfig, index){
        let newItem = this.props.item;
        newItem.charts[index] = chartConfig;
        await this.props.updateItemCallBack(newItem);
    }
    
    async deleteChart(index){
        let newCharts = [];
        for (let i = 0; i < this.props.item.charts.length; i++) {
            let chart = this.props.item.charts[i]
            if (i !== index){
                newCharts.push(
                    chart
                )
            }
        }
        let newItem = this.props.item;
        newItem.charts = newCharts;
        await this.props.updateItemCallBack(newItem);
    }

    render() {
        if (!this.state.report || !this.state.groupsConfig) {
            return (<CircularProgress/>)
        }
        return(

            <Stack
                spacing={2}
            >
                <CalculatorItemData
                    report={this.state.report}
                />
                <Stack
                    sx={{
                        width: '100%',
                    }}
                    direction={'row'}
                    spacing={2}
                >
                    {this.props.item.charts.map(
                        (chart, index) => (
                            <Box
                                width={100/this.props.item.charts.length + '%'}
                                key={'YearBarChartWidget_'+this.props.item.id+'-'+index}
                            >
                                <YearBarChartWidget
                                    stats={this.state.report}
                                    updateChartConfigCallBack={this.updateChartConfig.bind(this)}
                                    index={index}
                                    colors={this.props.colors}
                                    chartConfig={chart}
                                    groupsConfig={this.props.item.groupsConfig}
                                    deleteChartCallBack={this.deleteChart.bind(this)}
                                />
                            </Box>
                        ))
                    }
                </Stack>
                {
                    Object.keys(this.state.report.group_stats).length > 0?
                        <>
                            <Typography
                                level={'title-md'}
                            >
                                Группировки
                            </Typography>
                            <Stack
                                spacing={2}
                                direction={'row'}
                                useFlexGap
                                sx={{
                                    flexWrap: 'wrap',
                                }}
                            >
                            {
                                Object.entries(this.state.report.group_stats).map(
                                    (group) => (
                                        <Stack
                                            sx={{
                                                flex: 1
                                            }}
                                        >
                                            <Typography
                                                level={'body-md'}
                                                sx={{
                                                    pt: 1,
                                                    pb: 1,
                                                }}
                                            >
                                                {group[0]}
                                            </Typography>
                                            <Stack
                                                direction={'row'}
                                                spacing={1}
                                            >
                                                <Select
                                                    defaultValue={this.state.groupsConfig[group[0]].year}
                                                    onChange={(event, newValue) => {
                                                        this.handleGroupYearChange.bind(this)(newValue, group[0])
                                                    }}
                                                >
                                                    {
                                                        Object.entries(this.state.report.stats).map(
                                                            (each) => (<Option value={parseInt(each[0])}>{each[0]}</Option>)
                                                        )
                                                    }
                                                </Select>
                                                <Select
                                                    defaultValue={this.state.groupsConfig[group[0]].currentCategory}
                                                    onChange={(event, newValue) => {
                                                        this.handleGroupCurrentCategoryChange.bind(this)(newValue, group[0])
                                                    }}
                                                >
                                                    {
                                                        Object.entries(this.state.report.categories).map(
                                                            (each) => (<Option value={each[0]}>{each[1]}</Option>)
                                                        )
                                                    }
                                                </Select>
                                            </Stack>
                                            <Stack
                                                direction={'row'}
                                                spacing={1}
                                                sx={{
                                                    mt: 1,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Checkbox
                                                    checked={this.state.groupsConfig[group[0]].legendIsHidden}
                                                    onChange={(event) => {
                                                        this.handleGroupLegendIsHiddenChange(group[0])
                                                    }}
                                                    sx={{mr: 1}}
                                                />
                                                <Typography>
                                                    Скрыть легенду
                                                </Typography>
                                            </Stack>
                                            {
                                                ['ave', 'median'].includes(this.state.groupsConfig[group[0]].currentCategory.split('_').pop())?
                                                    <BarChart
                                                        series={
                                                            [
                                                                {
                                                                    data: Object.entries(this.state.report.group_stats[group[0]]['total']).map(
                                                                        (each) => (each[1][this.state.groupsConfig[group[0]].year][this.state.groupsConfig[group[0]].currentCategory])
                                                                    ),
                                                                }
                                                            ]
                                                        }
                                                        xAxis={
                                                            [
                                                                {
                                                                    data: (() => {
                                                                        if (this.state.report.group_stats[group[0]]['total'][this.state.groupsConfig[group[0]].year] === undefined){
                                                                            return []
                                                                        }
                                                                        return(
                                                                            Object.entries(this.state.report.group_stats[group[0]]['total']).map(
                                                                                (each) => (each[0])
                                                                            )
                                                                        )
                                                                    })(),
                                                                    scaleType: 'band',
                                                                    tickPlacement: 'middle',
                                                                    tickLabelPlacement: 'middle',
                                                                }
                                                            ]
                                                        }
                                                        height={290}
                                                        margin={{top: 10, bottom: 30, left: 100, right: 10}}
                                                    />:
                                                    <PieChart
                                                        series={[
                                                            {
                                                                data: (
                                                                    () => {
                                                                        if (this.state.report.group_stats[group[0]]['total'] === undefined){
                                                                            return []
                                                                        }
                                                                        return(
                                                                            Object.entries(this.state.report.group_stats[group[0]]['total']).map((each)=>(
                                                                                {
                                                                                    label: each[0],
                                                                                    value: each[1][this.state.groupsConfig[group[0]].year][this.state.groupsConfig[group[0]].currentCategory],
                                                                                }
                                                                            ))
                                                                        )
                                                                    }
                                                                )(),
                                                                innerRadius: 30,
                                                                outerRadius: 100,
                                                                paddingAngle: 5,
                                                                cornerRadius: 5,
                                                                startAngle: -45,
                                                                endAngle: 315,
                                                                cx: 150,
                                                                cy: 150,
                                                                highlightScope: {fade: 'global', highlight: 'item'},
                                                                faded: {innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                                            },
                                                        ]}
                                                        height={290}
                                                        slotProps={{
                                                            legend: {
                                                                direction: 'row',
                                                                position: {vertical: 'bottom', horizontal: 'left'},
                                                                padding: 0,
                                                                hidden: this.state.groupsConfig[group[0]].legendIsHidden,
                                                            },
                                                        }}
                                                    />

                                            }
                                        </Stack>
                                    )
                                )
                        }
                            </Stack>
                        </>:
                        null
                    }
            </Stack>
        )
    }
}

export default CalculatorItem