import React from "react";
import ReconnectingWebSocket from 'reconnecting-websocket';
import RenderIfVisible from 'react-render-if-visible'
import List from "@mui/joy/List";
import { CircularProgress } from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import CommentItem from "./CommentItem";

// context
import UserSubscriberContext from "../../context/UserSubscriberContext";
import {setAuthTokens} from "../../utils/misc";


class CommentList extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            favComments: null,
            comments: null,
        };
    }

    async componentDidMount() {
        await this.retrieveComments();
        await this.retrieveFavComments();
        this.client = new ReconnectingWebSocket(
            `${process.env.REACT_APP_WS_HOST}/ws/comment/${this.props.appName}/${this.props.appId}/`,
            [],
            {
                maxRetries: 10,
            }
        );
        this.client.onopen = () => {
            console.log("WebSocket Client Connected");
            this.setState(
                {
                    connected: true,
                }
            )
        //     this.timer = setInterval(
        //     () => {
        //             let tokens = JSON.parse(localStorage.getItem("authTokens"));
        //             this.client.send(
        //                 JSON.stringify(
        //                     {
        //                         type: "ping",
        //                         access: tokens.access,
        //                         refresh: tokens.refresh,
        //                     }
        //                 )
        //             )
        //         },
        //         20000
        //     );
        };
        this.client.onclose = () => {
            console.log("WebSocket Client Disconnected");
            this.setState(
                {
                    connected: false,
                }
            )
            // if (this.timer){
            //     clearInterval(this.timer);
            // }
        }
        this.client.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);
            if (dataFromServer) {
                // if (dataFromServer.type === 'tokens'){
                //     setAuthTokens(
                //         dataFromServer.access_token,
                //         dataFromServer.refresh_token
                //     )
                // }
                if (dataFromServer.type === 'comment'){
                    this.setState((state) => ({
                    comments: [
                            {
                                id: dataFromServer.id,
                            },
                            ...state.comments,
                        ],
                    }));
                }
            }
        };
    }

    componentWillUnmount() {
        if (this.client){
            this.client.close();
        }
        // if (this.timer){
        //     clearInterval(this.timer);
        // }
    }

    async retrieveLists() {
        await this.retrieveComments();
        await this.retrieveFavComments();
    }

    async retrieveFavComments(){
        const url = 'comment/';
        const params = {
            related_app_id: this.props.appId,
            related_app: this.props.appName,
            deleted_bool: 0,
            favorite: 1,
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                favComments: response.data.results
            }
        );
    }

    async retrieveComments(){
        const url = 'comment/';
        const params = {
            related_app_id: this.props.appId,
            related_app: this.props.appName,
            deleted_bool: 0,
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                comments: response.data.results,
            }
        );
    }

    render() {
        if (!this.state.comments) {
            return (<CircularProgress />)
        }
        if (!this.state.favComments) {
            return (<CircularProgress />)
        }
        return(
            <List
                sx={{
                    width: '100%',
                }}
            >
                {
                    this.state.favComments.map(each=>{
                        return (
                            <RenderIfVisible
                                defaultHeight={200}
                                stayRendered={true}
                                key={each.id}
                            >
                                <CommentItem
                                    replyCallBack={this.props.replyCallBack}
                                    comment={each}
                                    favorite={true}
                                    user={this.context.userObject}
                                    callBack={this.retrieveLists.bind(this)}
                                />
                            </RenderIfVisible>
                        )
                    })
                }
                {
                    this.state.comments.map(each=>{
                        return (
                            <RenderIfVisible
                                defaultHeight={200}
                                stayRendered={true}
                                key={each.id}
                            >
                                <CommentItem
                                    replyCallBack={this.props.replyCallBack}
                                    comment={each}
                                    callBack={this.retrieveLists.bind(this)}
                                />
                            </RenderIfVisible>
                        )
                    })
                }
            </List>
        )
    }
}

export default CommentList;
