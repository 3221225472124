import React from "react";
import {CircularProgress, Stack,} from "@mui/joy";

// components
import CommonPaginatedTable from "../../Common/CommonPaginatedTable";
import UserSubscriberContext from "../../../context/UserSubscriberContext";
import BaseApi from "../../../utils/BaseApi";
import dayjs from "dayjs";
import CommonCallBackButton from "../../Common/CommonCallBackButton";
import DownloadIcon from "@mui/icons-material/Download";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {b64toBlob, downloadBlob} from "../../../utils/misc";


class PaymentCertificateTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            payments: null,
            certificates: null,
        };
    }

    async componentDidMount() {
        await this.retrievePayments();
        await this.retrieveCertificates();
    }

    async retrievePayments() {
        const url = 'payment/';
        const api = new BaseApi();
        let params = {
            page_size: 10000,
            opportunity: this.props.opportunity.id,
        }
        let response = await api.get(url, params);
        this.setState(
            {
                payments: response.data.results,
            }
        );
    }

    async retrieveCertificates() {
        const url = 'certificate/';
        const api = new BaseApi();
        let params = {
            page_size: 10000,
            opportunity: this.props.opportunity.id,
        }
        let response = await api.get(url, params);
        this.setState(
            {
                certificates: response.data.results,
            }
        );
    }

    async generate(generateParams, id){
        const api = new BaseApi();
        const url = `certificate/${id}/generate/`;
        let params = {
        }
        if (generateParams[0]){
            params.service = true
        }
        if (generateParams[1]){
            params.download = true
        }
        if (generateParams[2]){
            params.attach = true
        }
        let response = await api.get(url, params);
        downloadBlob(
            b64toBlob(response.data),
            `certificate_${id}.docx`,
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        )
    }

    render() {
        if (!this.state.certificates || !this.state.payments) {
            return (<CircularProgress/>)
        }
        return (
            <Stack>
                <CommonPaginatedTable
                    title={'Входящие платежи'}
                    data={this.state.payments}
                    url={'payment'}
                    fields={[
                        {
                            title: 'Дата',
                            name: 'date',
                            visible: true,
                            preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value),
                            edit: {
                                permission: true,
                                fields: ['date', ],
                                baseUrl: 'payment',
                                callBack: this.retrievePayments.bind(this),
                            },
                        },
                        {
                            title: 'Сумма',
                            name: 'revenue',
                            visible: true,
                            edit: {
                                permission: true,
                                fields: ['revenue', ],
                                baseUrl: 'payment',
                                callBack: this.retrievePayments.bind(this),
                            },
                        },
                        {
                            title: 'Подтверждено',
                            name: 'approval',
                            visible: true,
                            edit: {
                                permission: true,
                                fields: ['approval', ],
                                baseUrl: 'payment',
                                callBack: this.retrievePayments.bind(this),
                            },
                        },
                    ]}
                />
                <CommonPaginatedTable
                    title={'Акты'}
                    data={this.state.certificates}
                    url={'certificate'}
                    fields={[
                        {
                            title: 'Дата',
                            name: 'date',
                            visible: true,
                            preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value),
                            edit: {
                                permission: true,
                                fields: ['date', ],
                                baseUrl: 'certificate',
                                callBack: this.retrieveCertificates.bind(this),
                            },
                        },
                        {
                            title: 'Сумма',
                            name: 'revenue',
                            visible: true,
                            edit: {
                                permission: true,
                                fields: ['revenue', ],
                                baseUrl: 'certificate',
                                callBack: this.retrieveCertificates.bind(this),
                            },
                        },
                        {
                            title: 'Подтверждено',
                            name: 'approval',
                            visible: true,
                            edit: {
                                permission: true,
                                fields: ['approval', ],
                                baseUrl: 'certificate',
                                callBack: this.retrieveCertificates.bind(this),
                            },
                        },
                        {
                            title: 'Оригиналы',
                            name: 'papers',
                            visible: true,
                            edit: {
                                permission: true,
                                fields: ['papers', ],
                                baseUrl: 'certificate',
                                callBack: this.retrieveCertificates.bind(this),
                            },
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                label: 'Услуги',
                                icon: DownloadIcon,
                                color: 'neutral',
                            },
                            callBack: this.generate.bind(this, [true, true, false]),
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                label: 'Услуги',
                                icon: AttachFileIcon,
                                color: 'neutral',
                            },
                            callBack: this.generate.bind(this, [true, false, true]),
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                label: 'Работы',
                                icon: DownloadIcon,
                                color: 'neutral',
                            },
                            callBack: this.generate.bind(this, [false, true, false]),
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                label: 'Работы',
                                icon: AttachFileIcon,
                                color: 'neutral',
                            },
                            callBack: this.generate.bind(this, [false, false, true]),
                        },
                    ]}
                />
            </Stack>
        )
    }
}

export default PaymentCertificateTab
