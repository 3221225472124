// components
import CommonForm from "../Common/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";


class RequisitesCreateModal extends CommonActionModal {
    async componentDidMount() {
        let formFields = await this.retrieveFields();
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            modalLabel: 'Создание реквизитов',
            modalIcon: AssignmentIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
            ready: true,
        })
    }

    async retrieveFields() {
        const url = `requisites/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return (response.data)
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        formDataObj.account = this.props.account.id;
        const url = `requisites/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            this.setModalOpenFalse();
        }
    }
}

export default RequisitesCreateModal