import React from "react";

import {CircularProgress, Modal, ModalClose, ModalDialog, Stack} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import Grid from "@mui/joy/Grid";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";

// components
import DeliveryStateConditionsTable from "./DeliveryStateConditionsTable";
import DeliveryStateCreate from "./DeliveryStateCreate";
import DeliveryStateCard from "./DeliveryStateCard";
import DeliveryGraph from "./DeliveryGraph";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import BaseApi from "../../utils/BaseApi";
import DeliveryStateCardInner from "./DeliveryStateCardInner";

// icons
import WebAssetIcon from '@mui/icons-material/WebAsset';
import AccountTreeIcon from "@mui/icons-material/AccountTree";


class DeliveryDetail extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            delivery: Object.hasOwn(this.props, 'delivery')?this.props.delivery:null,
            states: Object.hasOwn(this.props, 'delivery')?this.props.delivery.states:null,
            modalOpen: false,
        };
    }

    async componentDidMount() {
        if (!this.state.delivery || !this.state.states){
            await this.retrieveDelivery();
        }
    }

    async retrieveDelivery() {
        this.setState(
            {
                delivery: null,
                states: null,
            }
        );
        let url;
        if (Object.hasOwn(this.props, 'componentObj')){
            url = `delivery/${this.props.componentObj.delivery.id}/`;
        }
        else {
            url = `delivery/${this.props.delivery.id}/`;
        }
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                delivery: response.data,
                states: response.data.states
            }
        );
    }

    setModalOpenTrue(){
        this.setState(
            {
                modalOpen: true,
            }
        );
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        )
        if(typeof this.props.callBack == 'function') {
            await this.props.callBack();
        }
    }

    render() {
        if (!this.state.delivery || !this.state.states){
            return (
                <CircularProgress/>
            )
        }
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <IconButton
                    size={'sm'}
                    color="neutral"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <WebAssetIcon />
                </IconButton>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    height: '100%'
                                }}
                            >
                                <Grid
                                    md={8}
                                    sx={{
                                        height: '100%',
                                        overflowY: 'scroll'
                                    }}
                                >
                                    <Card
                                        sx={{mb: 1}}
                                        variant={'soft'}
                                    >
                                        <CardContent>
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={2}
                                                sx={{
                                                    mb: 2
                                                }}
                                            >
                                                <AccountTreeIcon />
                                                <Typography level={'h3'}>
                                                    {this.state.delivery.title}
                                                </Typography>
                                                <Chip
                                                    variant="soft"
                                                    color={'primary'}
                                                    sx={{ml: 1}}
                                                >
                                                    {this.state.delivery.xtype_human}
                                                </Chip>
                                                <DeliveryGraph
                                                    deliveryId={this.state.delivery.id}
                                                />
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={2}
                                                sx={{
                                                    mb: 2
                                                }}
                                            >
                                                <Typography
                                                    level={'h4'}
                                                >
                                                    {this.state.delivery.completed?'Последняя стадия':'Текущая стадия'}
                                                </Typography>
                                                <Chip
                                                    variant="soft"
                                                    color={this.state.states[0].stage.color}
                                                    sx={{ml: 1}}
                                                >
                                                    {this.state.states[0].stage.title}
                                                </Chip>
                                            </Stack>
                                            <DeliveryStateCardInner
                                                state={this.state.states[0]}
                                                hideCompleted={true}
                                            />
                                        </CardContent>
                                    </Card>
                                    {!this.state.delivery.completed?
                                        <>
                                            <Card
                                        sx={{mb: 1}}
                                        variant={'soft'}
                                    >
                                        <CardContent>
                                            <Typography
                                                level={'h3'}
                                                sx={{
                                                    mb: 2
                                                }}
                                            >
                                                Подсказка
                                            </Typography>
                                            {this.state.states[0].stage.help_text?
                                                <div dangerouslySetInnerHTML={{__html: this.state.states[0].stage.help_text}} />:
                                                <Typography level={'body-md'}>Не указана</Typography>
                                            }
                                        </CardContent>
                                    </Card>
                                    <Card
                                        sx={{mb: 1}}
                                        variant={'soft'}
                                    >
                                        <CardContent>
                                            <Typography
                                                level={'h3'}
                                                sx={{
                                                    mb: 2
                                                }}
                                            >
                                                Условия стадии
                                            </Typography>
                                            <DeliveryStateConditionsTable
                                                deliveryStateId={this.state.states[0].id}
                                                callBack={this.retrieveDelivery.bind(this)}
                                            />
                                        </CardContent>
                                    </Card>
                                            {
                                                this.context.userObject.id === this.state.states[0].operator.user.id ?
                                                    <Card
                                                        sx={{mb: 1}}
                                                        variant={'soft'}
                                                    >
                                                        <CardContent>
                                                            <Typography
                                                                level={'h3'}
                                                                sx={{
                                                                    mb: 2
                                                                }}
                                                            >
                                                                Продвижение по процессу
                                                            </Typography>
                                                            <DeliveryStateCreate
                                                                deliveryState={this.state.states[0]}
                                                                previousDeliveryState={this.state.states[1]}
                                                                opportunityId={this.state.delivery.opportunity.id}
                                                                xtype={this.state.delivery.xtype}
                                                                callBack={this.retrieveDelivery.bind(this)}
                                                            />
                                                        </CardContent>
                                                    </Card> :
                                                    <Card
                                                        color={'warning'}
                                                        variant="soft"
                                                    >
                                                        <CardContent>
                                                            <Typography
                                                                level={'body-md'}
                                                            >
                                                                Вы не являетесь исполнителем этой стадии
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                            }
                                        </>:
                                        <Card
                                            color={'success'}
                                            variant="soft"
                                        >
                                            <CardContent>
                                                <Typography
                                                    level={'body-md'}
                                                >
                                                    Бизнес-процесс завершен
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    }
                                </Grid>
                                <Grid
                                    md={4}
                                    sx={{
                                        height: '100%',
                                        overflowY: 'scroll'
                                    }}
                                >
                                    <Card
                                        variant="soft"
                                        overflowY={'scroll'}
                                    >
                                        <CardContent>
                                            <Typography
                                                level={'h3'}
                                                sx={{
                                                    mb: 2
                                                }}
                                            >
                                                История стадий
                                            </Typography>
                                            {this.state.states.map(
                                                (each, index) => {
                                                    if (index === 0){
                                                        return null
                                                    }
                                                    return (
                                                        <DeliveryStateCard
                                                            state={each}
                                                            key={'delivery-state-card-'+each.id}
                                                        />
                                                    )
                                                }
                                                )
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default DeliveryDetail
