import React from 'react';
import dayjs from "dayjs";

import {CircularProgress, Stack, Switch} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import Input from "@mui/joy/Input";
import {LineChart} from "@mui/x-charts";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Box from "@mui/joy/Box";
import Grid from "@mui/joy/Grid";

// components
import BaseApi from "../../utils/BaseApi";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import CommonForm from "../Common/CommonForm";
import FinancialModelPeriodExpenseCreate from "./FinancialModelPeriodExpenseCreate";
import FinancialModelOnceExpenseCreate from "./FinancialModelOnceExpenseCreate";
import {compareDates, getDatesInRange} from "../../utils/misc";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";
import FinancialModelOpportunityCardWrapper from "./FinancialModelOpportunityCardWrapper";
import FinancialModelSalaryCreate from "./FinancialModelSalaryCreate";
import FinancialModelConfigValueUpdate from "./FinancialModelConfigValueUpdate";
import FinancialModelForecastParameters from "./FinancialModelForecastParameters";
import FinancialTotals from "./FinancialTotals";
import {calculateTotals} from "./utils";

// icons
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from '@mui/icons-material/Refresh';


dayjs.locale('ru');


class FinancialModel extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            opportunities: [],
            forecast_revenues: [],
            forecast_incomes: [],
            payments: null,
            certificates: null,
            statuses: ['OPENX',],
            xtypes_choices: null,
            xtypes: [],
            needRefresh: false,
            ready: false,
            config: null,
            report: null,
            total: null,
            quarters: null,
        }
        this.year = new Date().getFullYear()
    }

    async componentDidMount() {
        await this.retrieveConfig();
        await this.retrievePayments();
        await this.retrieveCertificates();
        await this.retrieveXtypes();
        await this.calculateReport();
    }

    async sanitizeConfig(config){
        let sanitize = false;
        if (config.length === 0){
            sanitize = true;
        }
        if (config.keys?.length === 0){
            sanitize = true;
        }
        if (sanitize){
            config = {
                onceExpenses: {},
                periodExpenses: {},
                salaries: {},
                startCash: 0,
            };
            await this.postConfig(config);
        }
        return config
    }

    async retrieveXtypes() {
        const api = new BaseApi();
        let url = 'attachment/subscriber_xtypes/';
        let response = await api.get(
            url,
            {},
        );
        let xtypes_choices = [];
        for (const category of response.data.all_xtypes) {
            if (response.data.available_xtypes.includes(category[0])) {
                xtypes_choices.push(category);
            }
        }
        this.setState({
            xtypes_choices: xtypes_choices,
        });
    }

    async postConfig(config) {
        const url = `subscriber/financial_model/`;
        const api = new BaseApi();
        await api.post(
            url,
            config
        );
    }

    async retrieveConfig() {
        const url = `subscriber/financial_model/`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        let config = response.data;
        config = await this.sanitizeConfig(config);
        this.setState({
            config: config,
        });
    }

    setOpportunityValue(value){
        let opportunities = this.state.opportunities;
        for (let opportunity of opportunities){
            if (opportunity.opportunity.id === value.id){
                opportunity.opportunity = value
            }
        }
        this.setState({
            opportunities: opportunities,
            needRefresh: true,
        })
    }

    async retrieveOpportunities() {
        const url = 'opportunity/report/';
        const api = new BaseApi();
        let params = {
            xtypes: this.state.xtypes.join(','),
            cards: true,
        };
        let opportunities = [];
        for (let status of this.state.statuses){
            params.status = status;
            let response = await api.get(
                url,
                params
            );
            opportunities = opportunities.concat(response.data.cards)
        }
        this.setState({
            opportunities: opportunities,
        });
    }

    async retrievePayments() {
        const url = 'payment/';
        const api = new BaseApi();
        let params = {
            page_size: 10000,
            year: this.year,
        }
        let response = await api.get(url, params);
        this.setState(
            {
                payments: response.data.results,
            }
        );
    }
    async retrieveCertificates() {
        const url = 'certificate/';
        const api = new BaseApi();
        let params = {
            page_size: 10000,
            year: this.year,
        }
        let response = await api.get(url, params);
        this.setState(
            {
                certificates: response.data.results,
            }
        );
    }

    async addSalary(data) {
        let config = this.state.config;
        let salaries = [];
        for (let i = 0; i < 12; i++) {
            if (i === 11){
                salaries.push(
                    {
                        date: (new Date(this.year, i, 25)).toLocaleDateString('en-US'),
                        count: data.count,
                        value: data.value / 2,
                    }
                )
                salaries.push(
                    {
                        date: (new Date(this.year, i, 28)).toLocaleDateString('en-US'),
                        count: data.count,
                        value: data.value / 2,
                    }
                )
            }
            else {
                salaries.push(
                    {
                        date: (new Date(this.year, i, 25)).toLocaleDateString('en-US'),
                        count: data.count,
                        value: data.value / 2,
                    }
                )
                salaries.push(
                    {
                        date: (new Date(this.year, i+1, 10)).toLocaleDateString('en-US'),
                        count: data.count,
                        value: data.value / 2,
                    }
                )
            }

        }
        data.active = true;
        let id = crypto.randomUUID();
        if (id in config.salaries) {
            id = crypto.randomUUID();
        }
        config.salaries[id] = {
            config: data,
            salaries: salaries,
        }
        await this.updateConfig(config);
    }

    async changeSalaryActive(id){
        let config = this.state.config;
        config.salaries[id].config.active = !config.salaries[id].config.active
        await this.updateConfig(config);
    }

    async deleteSalary(id){
        let config = this.state.config;
        delete config.salaries[id]
        await this.updateConfig(config);
    }

    async addPeriodExpense(data){
        let config = this.state.config;
        let expenses = [];
        if (data.period === 'month'){
            for (let i = 0; i < 12; i++){
                expenses.push(
                    {
                        date: (new Date(this.year, i, data.day)).toLocaleDateString('en-US'),
                        count: data.count,
                        value: data.value,
                    }
                )
            }
        }
        else if (data.period === 'quarter'){
            for (let i = 0; i < 12; i+=3){
                expenses.push(
                    {
                        date: (new Date(this.year, i, data.day)).toLocaleDateString('en-US'),
                        count: data.count,
                        value: data.value,
                    }
                )
            }
        }
        data.active = true;
        let id = crypto.randomUUID();
        if (id in config.periodExpenses){
            id = crypto.randomUUID();
        }
        config.periodExpenses[id] = {
            config: data,
            expenses: expenses,
        }
        await this.updateConfig(config);
    }

    async changePeriodExpenseActive(id){
        let config = this.state.config;
        config.periodExpenses[id].config.active = !config.periodExpenses[id].config.active
        await this.updateConfig(config);
    }

    async changePeriodExpense(id, c, target){
        let config = this.state.config;
        let formData = new FormData(target);
        config.periodExpenses[id].expenses[c].value = formData.get('value')
        config.periodExpenses[id].expenses[c].count = formData.get('count')
        await this.updateConfig(config);
    }

    async deletePeriodExpense(id){
        let config = this.state.config;
        delete config.periodExpenses[id]
        await this.updateConfig(config);
    }

    async addOnceExpense(data){
        let config = this.state.config;
        data.active = true;
        let id = crypto.randomUUID();
        if (id in config.onceExpenses){
            id = crypto.randomUUID();
        }
        config.onceExpenses[id] = {
            config: data,
            expense: {
                date: dayjs(data.date).toDate().toLocaleDateString('en-US'),
                count: 1,
                value: data.value,
            },
        }
        await this.updateConfig(config);
    }

    async changeOnceExpenseActive(id){
        let config = this.state.config;
        config.onceExpenses[id].config.active = !config.onceExpenses[id].config.active
        await this.updateConfig(config);
    }

    async deleteOnceExpense(id){
        let config = this.state.config;
        delete config.onceExpenses[id]
        await this.updateConfig(config);
    }

    async processOpportunityFilter(form) {
        await this.setState({
            statuses: [],
            xtypes: [],
        })
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        await this.setState({
            statuses: formDataObj.statuses.split(','),
            xtypes: formDataObj.xtypes.split(','),
        });
        await this.retrieveOpportunities();
    }

    async preRefresh(){
        this.setState({
            needRefresh: true
        })
    }

    async refresh(){
        await this.retrievePayments();
        await this.retrieveCertificates();
        await this.calculateReport();
    }

    async updateStartCash(data){
        let config = this.state.config;
        config.startCash = parseInt(data.startCash);
        await this.updateConfig(config);
    }

    async updateTypicalContractFee(data){
        let config = this.state.config;
        config.typicalContractFee = parseInt(data.typicalContractFee);
        await this.updateConfig(config);
    }

    async updateProfitPercent(data){
        let config = this.state.config;
        config.profitPercent = parseInt(data.profitPercent);
        await this.updateConfig(config);
    }

    async updateWorkPercent(data){
        let config = this.state.config;
        config.workPercent = parseInt(data.workPercent);
        await this.updateConfig(config);
    }

    async calculateReport() {
        this.setState({
            ready: false,
        })
        let url;
        let params;
        let response;
        const api = new BaseApi();
        let report = {};
        let startDate = new Date(this.year, 0, 1)
        let endDate = new Date(this.year,11, 31)
        let dates = getDatesInRange(startDate, endDate)
        for (let date of dates) {
            report[date.toLocaleDateString('en-US')] = {
                incomeFact: 0,
                incomeForecast: 0,
                incomeLeadTail: 0,
                revenueFact: 0,
                revenueForecast: 0,
                revenueLeadTail: 0,
                expense: 0,
                salaries: 0,
                tax: 0,
                taxForecast: 0,
                workDays: 0,
                balance: 0,
                cash: 0,
                date: date.toLocaleDateString('en-US'),
            }
        }
        for (let e of Object.entries(this.state.config.onceExpenses)) {
            if (e[1].config.active){
                report[e[1].expense.date].expense += e[1].expense.value * e[1].expense.count
            }
        }
        for (let es of Object.entries(this.state.config.periodExpenses)) {
            if (es[1].config.active) {
                for (let e of es[1].expenses) {
                    report[e.date].expense += e.value * e.count
                }
            }
        }
        for (let es of Object.entries(this.state.config.salaries)) {
            if (es[1].config.active) {
                for (let e of es[1].salaries) {
                    let value = 0;
                    if (dayjs(e.date).toDate().getDate() === 25){
                        let minusMrot = e.value - 33660;
                        if (minusMrot > 0){
                            value = 10098 + minusMrot * 0.15 + e.value
                        }
                        else {
                            value = e.value * 1.3
                        }
                    }
                    else {
                        value = e.value * 1.15
                    }
                    report[e.date].salaries += value * e.count
                    report[e.date].workDays += 10 * e.count
                }
            }
        }
        for (let i of this.state.payments){
            if (i.forecast) {
                report[dayjs(i.date).toDate().toLocaleDateString('en-US')].incomeFact += i.revenue
                report[dayjs(i.date).toDate().toLocaleDateString('en-US')].tax += i.revenue * 0.03
            }
        }
        for (let r of this.state.certificates){
            if (r.forecast) {
                report[dayjs(r.date).toDate().toLocaleDateString('en-US')].revenueFact += r.revenue
            }
        }
        url = 'opportunity/forecast';
        params = {
            year: this.year,
        }
        response = await api.get(url, params);
        for (let r of response.data.by_start_date.revenue){
            report[dayjs(r.date).toDate().toLocaleDateString('en-US')].revenueForecast += r.value
        }
        for (let r of response.data.by_start_date.income){
            report[dayjs(r.date).toDate().toLocaleDateString('en-US')].incomeForecast += r.value
            report[dayjs(r.date).toDate().toLocaleDateString('en-US')].taxForecast += r.value * 0.03
        }
        for (let r of response.data.by_lead_tail.revenue){
            report[dayjs(r.date).toDate().toLocaleDateString('en-US')].revenueLeadTail += r.value
        }
        for (let r of response.data.by_lead_tail.income){
            report[dayjs(r.date).toDate().toLocaleDateString('en-US')].incomeLeadTail += r.value
            report[dayjs(r.date).toDate().toLocaleDateString('en-US')].taxForecast += r.value * 0.03
        }
        let reportList = []
        for (let day of Object.entries(report)){
            reportList.push(
                day[1]
            )
        }
        reportList.sort(
            function(a, b) {
                return compareDates(a, b)
            }
        )
        let prevCash = this.state.config.startCash;
        let prevCashForecast = this.state.config.startCash;
        for (let day of reportList){
            day.balanceForecast = day.incomeFact + day.incomeForecast + day.incomeLeadTail - day.expense - day.salaries - day.tax - day.taxForecast;
            day.balance = day.incomeFact - day.expense - day.salaries - day.tax;
            day.cashForecast = prevCashForecast + day.balanceForecast;
            day.cash = prevCash + day.balance;
            prevCash = day.cash;
            prevCashForecast = day.cashForecast;
        }
        let [total, quarters] = calculateTotals(
            reportList,
            'incomeFact',
            'incomeForecast',
            'incomeLeadTail',
            'revenueFact',
            'revenueForecast',
            'revenueLeadTail',
            ['expense', ],
            ['salaries', ],
            'tax',
            'taxForecast',
            'balance',
            'balanceForecast',
        )
        total.workDays = 0;
        for (let day of reportList){
            total.workDays += day.workDays;
        }
        total.cash = this.state.config.startCash + total.balance;
        total.expectedRevenue = (100 + 3 + this.state.config.profitPercent) / 100 * (total.expense + total.salaries);
        total.expectedDayRate = total.expectedRevenue / total.workDays / this.state.config.workPercent * 100;
        let forecast_revenues = response.data.by_start_date.revenue;
        forecast_revenues.sort(
            function(a, b) {
                return compareDates(a, b)
            }
        )
        let forecast_incomes = response.data.by_start_date.income;
        forecast_incomes.sort(
            function(a, b) {
                return compareDates(a, b)
            }
        )
        this.setState({
            forecast_revenues: forecast_revenues,
            forecast_incomes: forecast_incomes,
            report: reportList,
            total: total,
            quarters: quarters,
            ready: true,
        });
    }

    async updateConfig(config){
        this.setState(
            {
                config: config
            }
        );
        await this.postConfig(config);
        await this.calculateReport(config);
    }

    render() {
        let numberFormat = new Intl.NumberFormat('ru', {maximumFractionDigits: 2})
        if (!this.state.ready) {
            return (
                <CircularProgress/>
            )
        }
        return (
            <Stack
                spacing={2}
                sx={{
                    width: '100%',
                }}
            >
                <Stack
                    direction={'row'}
                    spacing={2}
                >
                    <Typography
                        level="h3"
                    >
                        Финансовая модель
                    </Typography>
                    {
                        this.state.needRefresh?
                            <Button
                                size={'sm'}
                                color={'success'}
                                onClick={this.refresh.bind(this)}
                                startDecorator={<RefreshIcon size={'sm'}/> }
                            >
                                Обновить
                            </Button>:
                            null
                    }
                </Stack>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid xs={12} lg={8}>
                                <Card>
                                    <CardContent>
                                        <FinancialTotals
                                            total={this.state.total}
                                            quarters={this.state.quarters}
                                            miles={true}
                                        />
                                        <Stack
                                            direction={'row'}
                                            spacing={1}
                                            sx={{
                                                alignItems: "center",
                                                mb: 1,
                                            }}
                                        >
                                            <Card
                                                color="lime"
                                                variant="soft"
                                            >
                                                <CardContent>
                                                    <Typography
                                                        level={'body-xs'}
                                                    >
                                                        Остаток на конец года
                                                    </Typography>
                                                    <Typography>
                                                        {numberFormat.format(this.state.total.cash)}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                            <Typography
                                                level={'h4'}
                                            >
                                               =
                                            </Typography>
                                            <Card
                                                color="lime"
                                            >
                                                <CardContent>
                                                    <Typography
                                                        level={'body-xs'}
                                                    >
                                                        Начальный остаток
                                                    </Typography>
                                                    <Stack
                                                        direction={'row'}
                                                        spacing={1}
                                                    >
                                                        <Typography>
                                                            {numberFormat.format(this.state.config.startCash)}
                                                        </Typography>
                                                        <FinancialModelConfigValueUpdate
                                                            name={'startCash'}
                                                            title={'Начальный остаток'}
                                                            defaultValue={this.state.config.startCash}
                                                            callBack={this.updateStartCash.bind(this)}
                                                        />
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                            <Typography
                                                level={'h4'}
                                            >
                                               +
                                            </Typography>
                                            <Card
                                                color="lime"
                                            >
                                                <CardContent>
                                                    <Typography
                                                        level={'body-xs'}
                                                    >
                                                        Баланс платежей
                                                    </Typography>
                                                    <Typography>
                                                        {numberFormat.format(this.state.total.balance)}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid xs={12} lg={4} sx={{height: '100%'}}>
                                <Card>
                                    <CardContent>
                                        <Typography
                                            level={'h4'}
                                            sx={{
                                                mb: 2
                                            }}
                                        >
                                            Модельные показатели
                                        </Typography>
                                        <Stack
                                            spacing={2}
                                        >
                                            <Stack
                                                direction={'row'}
                                                spacing={1}
                                                sx={{
                                                    alignItems: "center",
                                                    mb: 1,
                                                }}
                                            >
                                                <Card
                                                    color="lime"
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            level={'body-xs'}
                                                        >
                                                            Процент прибыли
                                                        </Typography>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={1}
                                                        >
                                                            <Typography>
                                                                {numberFormat.format(this.state.config.profitPercent)}
                                                            </Typography>
                                                            <FinancialModelConfigValueUpdate
                                                                name={'profitPercent'}
                                                                title={'Процент прибыли'}
                                                                defaultValue={this.state.config.profitPercent}
                                                                callBack={this.updateProfitPercent.bind(this)}
                                                            />
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                                <Card
                                                    color="lime"
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            level={'body-xs'}
                                                        >
                                                            Процент загрузки
                                                        </Typography>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={1}
                                                        >
                                                            <Typography>
                                                                {numberFormat.format(this.state.config.workPercent)}
                                                            </Typography>
                                                            <FinancialModelConfigValueUpdate
                                                                name={'workPercent'}
                                                                title={'Процент загрузки'}
                                                                defaultValue={this.state.config.workPercent}
                                                                callBack={this.updateWorkPercent.bind(this)}
                                                            />
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                                <Card
                                                    color="lime"
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            level={'body-xs'}
                                                        >
                                                            Типичная стоимость сделки
                                                        </Typography>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={1}
                                                        >
                                                            <Typography>
                                                                {numberFormat.format(this.state.config.typicalContractFee)}
                                                            </Typography>
                                                            <FinancialModelConfigValueUpdate
                                                                name={'typicalContractFee'}
                                                                title={'Типичная стоимость сделки'}
                                                                defaultValue={this.state.config.typicalContractFee}
                                                                callBack={this.updateTypicalContractFee.bind(this)}
                                                            />
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                            </Stack>
                                            <Stack
                                                direction={'row'}
                                                spacing={1}
                                                sx={{
                                                    alignItems: "center",
                                                    mb: 1,
                                                }}
                                            >
                                                <Card
                                                    color="lime"
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            level={'body-xs'}
                                                        >
                                                            Цена человеко-дня
                                                        </Typography>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={1}
                                                        >
                                                            <Typography>
                                                                {numberFormat.format(this.state.total.expectedDayRate)}
                                                            </Typography>
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                                <Typography
                                                    level={'h4'}
                                                >
                                                   =
                                                </Typography>
                                                <Card
                                                    color="lime"
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            level={'body-xs'}
                                                        >
                                                            Искомая выручка
                                                        </Typography>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={1}
                                                        >
                                                            <Typography>
                                                                {numberFormat.format(this.state.total.expectedRevenue)}
                                                            </Typography>
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                                <Typography
                                                    level={'h4'}
                                                >
                                                   /
                                                </Typography>
                                                <Card
                                                    color="lime"
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            level={'body-xs'}
                                                        >
                                                            Доступно человеко-дней
                                                        </Typography>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={1}
                                                        >
                                                            <Typography>
                                                                {numberFormat.format(this.state.total.workDays * this.state.config.workPercent / 100)}
                                                            </Typography>
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <LineChart
                            dataset={this.state.report}
                            series={[
                                {
                                    dataKey: 'cash',
                                    label: 'Остаток',
                                    color: '#4e79a7',
                                },
                                {
                                    dataKey: 'cashForecast',
                                    label: 'Остаток (прогноз)',
                                    color: '#edc949',
                                },
                            ]}
                            grid={
                                {
                                    horizontal: true
                                }
                            }
                            margin={{ left: 100, right: 30, top: 30, bottom: 30 }}
                            height={400}
                        />
                    </CardContent>
                </Card>
                <Stack
                    direction={'row'}
                    spacing={2}
                >
                    <Card
                        sx={{
                            height: '300px',
                            overflowY: 'scroll',
                        }}
                    >
                        <CardContent
                        >
                            <CommonPaginatedTable
                                title={'Входящие платежи'}
                                data={this.state.payments}
                                url={'payment'}
                                fields={[
                                    {
                                        title: 'Дата',
                                        name: 'date',
                                        visible: true,
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                                    },
                                    {
                                        title: 'Сделка',
                                        name: 'opportunity.short_name',
                                        visible: true,
                                        linkPattern: '/opportunity/detail/:linkParam',
                                        linkParam: 'opportunity.id',
                                        linkTarget: '_blank'
                                    },
                                    {
                                        title: 'Сумма',
                                        name: 'revenue',
                                        visible: true,
                                    },
                                    {
                                        title: 'В прогнозе',
                                        name: 'forecast',
                                        visible: true,
                                        edit: {
                                            permission: true,
                                            fields: ['forecast', ],
                                            baseUrl: 'payment',
                                            callBack: this.preRefresh.bind(this),
                                        },
                                    },
                                ]}
                            />
                        </CardContent>
                    </Card>
                    <Card
                        sx={{
                            height: '300px',
                            overflowY: 'scroll',
                        }}
                    >
                        <CardContent
                        >
                            <CommonPaginatedTable
                                title={'Акты'}
                                data={this.state.certificates}
                                url={'certificate'}
                                fields={[
                                    {
                                        title: 'Дата',
                                        name: 'date',
                                        visible: true,
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value),
                                    },
                                    {
                                        title: 'Сделка',
                                        name: 'opportunity.short_name',
                                        visible: true,
                                        linkPattern: '/opportunity/detail/:linkParam',
                                        linkParam: 'opportunity.id',
                                        linkTarget: '_blank'
                                    },
                                    {
                                        title: 'Сумма',
                                        name: 'revenue',
                                        visible: true,
                                    },
                                    {
                                        title: 'В прогнозе',
                                        name: 'forecast',
                                        visible: true,
                                        edit: {
                                            permission: true,
                                            fields: ['forecast', ],
                                            baseUrl: 'payment',
                                            callBack: this.preRefresh.bind(this),
                                        },
                                    },
                                ]}
                            />
                        </CardContent>
                    </Card>
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={2}
                >
                    <Card
                        sx={{
                            height: '300px',
                            overflowY: 'scroll',
                        }}
                    >
                        <CardContent
                        >
                            <CommonPaginatedTable
                                title={'Входящие платежи (прогноз)'}
                                data={this.state.forecast_incomes}
                                url={'payment'}
                                fields={[
                                    {
                                        title: 'Дата',
                                        name: 'date',
                                        visible: true,
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                                    },
                                    {
                                        title: 'Сделка',
                                        name: 'opportunity.short_name',
                                        visible: true,
                                        linkPattern: '/opportunity/detail/:linkParam',
                                        linkParam: 'opportunity.id',
                                        linkTarget: '_blank'
                                    },
                                    {
                                        title: 'Сумма',
                                        name: 'value',
                                        visible: true,
                                    },

                                ]}
                            />
                        </CardContent>
                    </Card>
                    <Card
                        sx={{
                            height: '300px',
                            overflowY: 'scroll',
                        }}
                    >
                        <CardContent
                        >
                            <CommonPaginatedTable
                                title={'Акты (прогноз)'}
                                data={this.state.forecast_revenues}
                                url={'certificate'}
                                fields={[
                                    {
                                        title: 'Дата',
                                        name: 'date',
                                        visible: true,
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                                    },
                                    {
                                        title: 'Сделка',
                                        name: 'opportunity.short_name',
                                        visible: true,
                                        linkPattern: '/opportunity/detail/:linkParam',
                                        linkParam: 'opportunity.id',
                                        linkTarget: '_blank'
                                    },
                                    {
                                        title: 'Сумма',
                                        name: 'value',
                                        visible: true,
                                    },
                                ]}
                            />
                        </CardContent>
                    </Card>
                </Stack>
                <Typography
                    level={'h4'}
                >
                    Сделки в прогнозе
                </Typography>
                <CommonForm
                    processForm={this.processOpportunityFilter.bind(this)}
                    fields={[
                        {
                            name: 'statuses',
                            label: 'Статусы сделки',
                            xs: 12,
                            sm: null,
                            md: 6,
                            lg: null,
                            xl: null,
                            required: true,
                            default: ['OPENX',],
                            type: 'multiselect',
                            options: [
                                {'value': 'OPENX', 'label': 'В работе'},
                                {'value': 'DELAY', 'label': 'Ожидает'},
                                {'value': 'CLOSE', 'label': 'Закрыта'},
                                {'value': 'LOSTX', 'label': 'Потеряна'},
                            ]
                        },
                        {
                            'name': 'xtypes',
                            'label': 'Типы процессов',
                            'xs': 12,
                            'sm': null,
                            'md': 6,
                            'lg': null,
                            'xl': null,
                            'required': false,
                            'default': [],
                            'type': 'multiselect',
                            'options': this.state.xtypes_choices.map((each) => {return(
                                {
                                    'value': each[0],
                                    'label': each[1]
                                }
                            )})
                        },
                        {
                            'type': 'submit',
                            'label': 'Отобрать'
                        }
                    ]}
                />
                <Box
                    sx={{
                        height: '600px',
                        overflowY: 'scroll',
                    }}
                >
                    <CommonPaginatedTable
                        title={''}
                        data={this.state.opportunities}
                        fields={[
                            {
                                title: 'Название',
                                name: 'opportunity.short_name',
                                visible: true,
                                linkPattern: '/opportunity/detail/:linkParam',
                                linkParam: 'opportunity.id',
                                linkTarget: '_blank'
                            },
                            {
                                title: 'Компания',
                                name: 'opportunity.account.nameshort',
                                visible: true,
                                linkPattern: '/account/detail/:linkParam',
                                linkParam: 'opportunity.account.id',
                                linkTarget: '_blank'
                            },
                            {
                                title: 'Карточка',
                                visible: true,
                                use_component: true,
                                component: FinancialModelOpportunityCardWrapper,
                                // callBack: this.calculateReport.bind(this)
                            },
                            {
                                title: 'Текущее ТКП - цена',
                                name: 'opportunity.current_quote.price',
                                visible: true,
                            },
                            {
                                title: 'Текущее ТКП - рабочие дни',
                                name: 'opportunity.current_quote.work_days',
                                visible: true,
                            },
                            {
                                title: 'Параметры в прогнозе',
                                visible: true,
                                use_component: true,
                                component: FinancialModelForecastParameters,
                                callBack: this.setOpportunityValue.bind(this),
                                width: '40%',
                            },
                            {
                                title: 'Прогнозная дата начала',
                                visible: true,
                                name: 'opportunity.forecast_start_date',
                                preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value),
                                edit: {
                                    permission: true,
                                    fields: ['forecast_start_date', ],
                                    baseUrl: 'opportunity',
                                    obj: 'opportunity',
                                    callBack: this.setOpportunityValue.bind(this),
                                },
                                width: '20%',
                            },
                        ]}
                    />
                </Box>

                <Stack
                    direction={'row'}
                    spacing={1}
                >
                    <Typography
                        level={'h4'}
                    >
                        Периодические траты
                    </Typography>
                    <FinancialModelPeriodExpenseCreate
                        callBack={this.addPeriodExpense.bind(this)}
                    />
                </Stack>
                <Stack
                    spacing={2}
                >
                    {
                        Object.entries(this.state.config.periodExpenses).map(
                            es => (
                                <Stack
                                    spacing={2}
                                >
                                    <Stack
                                        direction={'row'}
                                        spacing={1}
                                        sx={{
                                            flexWrap: 'wrap',

                                        }}
                                    >
                                        <Typography
                                            level={'body-lg'}
                                        >
                                            {es[1].config.title}
                                        </Typography>
                                        <Switch
                                            checked={es[1].config.active}
                                            onChange={
                                                () => {
                                                    this.changePeriodExpenseActive.bind(this, es[0])()
                                                }
                                            }
                                        />
                                        <DeleteIcon
                                            color={'error'}
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={
                                                () => {
                                                    this.deletePeriodExpense.bind(this, es[0])()
                                                }
                                            }
                                        />

                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        sx={{
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {
                                            es[1].expenses.map(
                                                (e, c) => (
                                                    <Card
                                                        sx={{
                                                            mr: 1,
                                                            mt: 1,
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <Typography
                                                                level={'body-xs'}
                                                            >
                                                                {dayjs(e.date).format('DD MMM YYYY')}
                                                            </Typography>
                                                                <form
                                                                    onSubmit={
                                                                        (event) => {
                                                                            this.changePeriodExpense.bind(this, es[0], c, event.target)()
                                                                        }
                                                                    }
                                                                >
                                                                    <Stack
                                                                        direction={'row'}
                                                                        spacing={1}
                                                                        sx={{
                                                                            flexWrap: 'wrap',
                                                                        }}
                                                                    >
                                                                        <Input
                                                                            name={'value'}
                                                                            size="sm"
                                                                            sx={{width: 80}}
                                                                            defaultValue={e.value}
                                                                        />
                                                                        <Input
                                                                            name={'count'}
                                                                            size="sm"
                                                                            sx={{width: 40}}
                                                                            defaultValue={e.count}
                                                                        />
                                                                        <Button
                                                                            size={'sm'}
                                                                            type={'submit'}
                                                                            color={'success'}
                                                                            sx={{
                                                                                m: 0,
                                                                                p: 0,
                                                                                pl: 0.5,
                                                                                pr: 0.5,
                                                                            }}
                                                                        >
                                                                            <DoneIcon size={'sm'}/>
                                                                        </Button>
                                                                    </Stack>
                                                                </form>
                                                        </CardContent>
                                                    </Card>
                                                )
                                            )
                                        }
                                    </Stack>
                                    <Divider />
                                </Stack>
                            )
                        )
                    }
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={1}
                >
                    <Typography
                        level={'h4'}
                    >
                        Разовые траты
                    </Typography>
                    <FinancialModelOnceExpenseCreate
                        callBack={this.addOnceExpense.bind(this)}
                        year={this.year}
                    />
                </Stack>
                <Stack
                    direction={'row'}
                    sx={{
                        flexWrap: 'wrap',
                    }}
                >
                    {
                        Object.entries(this.state.config.onceExpenses).map(
                            e => (
                                <Card
                                    sx={{
                                        mr: 1,
                                        mt: 1
                                    }}
                                >
                                    <CardContent>
                                        <Stack
                                            direction={'row'}
                                            spacing={1}
                                        >
                                            <Typography
                                                level={'body-sm'}
                                            >
                                                <strong>{e[1].config.title}</strong>
                                            </Typography>
                                            <Switch
                                                checked={e[1].config.active}
                                                onChange={
                                                    () => {
                                                        this.changeOnceExpenseActive.bind(this, e[0])()
                                                    }
                                                }
                                            />
                                            <DeleteIcon
                                                color={'error'}
                                                sx={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={
                                                    () => {
                                                        this.deleteOnceExpense.bind(this, e[0])()
                                                    }
                                                }
                                            />
                                        </Stack>
                                        <Typography
                                            level={'body-sm'}
                                        >
                                            {dayjs(e[1].expense.date).format('DD MMM YYYY')}
                                        </Typography>
                                        <Typography
                                            level={'body-sm'}

                                        >
                                            {e[1].expense.value} x {e[1].expense.count}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )
                        )
                    }
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={1}
                >
                    <Typography
                        level={'h4'}
                    >
                        Инженерные работники
                    </Typography>
                    <FinancialModelSalaryCreate
                        callBack={this.addSalary.bind(this)}
                    />
                </Stack>
                <Stack
                    spacing={2}
                >
                    {
                        Object.entries(this.state.config.salaries).map(
                            es => (
                                <Stack
                                    spacing={2}
                                >
                                    <Stack
                                        direction={'row'}
                                        spacing={1}
                                        sx={{
                                            flexWrap: 'wrap',

                                        }}
                                    >
                                        <Typography
                                            level={'body-lg'}
                                        >
                                            {es[1].config.title}
                                        </Typography>
                                        <Switch
                                            checked={es[1].config.active}
                                            onChange={
                                                () => {
                                                    this.changeSalaryActive.bind(this, es[0])()
                                                }
                                            }
                                        />
                                        <DeleteIcon
                                            color={'error'}
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={
                                                () => {
                                                    this.deleteSalary.bind(this, es[0])()
                                                }
                                            }
                                        />

                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        sx={{
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {
                                            es[1].salaries.map(
                                                (e, c) => (
                                                    <Card
                                                        sx={{
                                                            mr: 1,
                                                            mt: 1,
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <Typography
                                                                level={'body-xs'}
                                                            >
                                                                {dayjs(e.date).format('DD MMM YYYY')}
                                                            </Typography>
                                                                <form
                                                                    onSubmit={
                                                                        (event) => {
                                                                            this.changeSalary.bind(this, es[0], c, event.target)()
                                                                        }
                                                                    }
                                                                >
                                                                    <Stack
                                                                        direction={'row'}
                                                                        spacing={1}
                                                                        sx={{
                                                                            flexWrap: 'wrap',
                                                                        }}
                                                                    >
                                                                        <Input
                                                                            name={'value'}
                                                                            size="sm"
                                                                            sx={{width: 80}}
                                                                            defaultValue={e.value}
                                                                        />
                                                                        <Input
                                                                            name={'count'}
                                                                            size="sm"
                                                                            sx={{width: 40}}
                                                                            defaultValue={e.count}
                                                                        />
                                                                        <Button
                                                                            size={'sm'}
                                                                            type={'submit'}
                                                                            color={'success'}
                                                                            sx={{
                                                                                m: 0,
                                                                                p: 0,
                                                                                pl: 0.5,
                                                                                pr: 0.5,
                                                                            }}
                                                                        >
                                                                            <DoneIcon size={'sm'}/>
                                                                        </Button>
                                                                    </Stack>
                                                                </form>
                                                        </CardContent>
                                                    </Card>
                                                )
                                            )
                                        }
                                    </Stack>
                                    <Divider />
                                </Stack>
                            )
                        )
                    }
                </Stack>
            </Stack>
        )
    }
}

export default FinancialModel;