import React from 'react';

import {CircularProgress} from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

// components
import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Common/CommonForm";
import AttachmentCreate from "../Attachment/AttachmentCreate";
import {arrayUniqueByKey, splitToList} from "../../utils/misc";
import CommonStepper from "../Common/CommonStepper";


class DeliveryStateCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            conditions: null,
            total: null,
            stage: null,
            stages: this.props.deliveryState.stage.next_stages,
            preSelectedAttachments: [],
            operator: null,
            operators: null,
            attachments: null,
        };
        this.steps = [
            {
                order: 0,
                title: 'Следующий шаг',
            },
            {
                order: 1,
                title: 'Исполнитель',
            },
            {
                order: 2,
                title: 'Данные для шага',
            },
        ]
    }

    async componentDidMount() {
        await this.retrieveConditions();
        await this.retrieveAttachments();
        let state = {};
        if (this.state.stages.length === 0){
            state.activeStep = 2
        }
        state.preSelectedAttachments = this.props.previousDeliveryState?.attachments.map(
            (a) => (a.id)
        )
        this.setState(state)
    }

    async retrieveOperators(role) {
        const url = `opp_team/`;
        const api = new BaseApi();
        const params = {
            opportunity: this.props.opportunityId,
            role: role,
        };
        let response = await api.get(
            url,
            params
        );
        let uniqueSubscribers = arrayUniqueByKey(
            response.data.results, 'subscriber.id'
        )
        return (
           uniqueSubscribers.map(each => {
                return(
                    {
                        value: each.subscriber.id,
                        label: `${each.subscriber.user.first_name} ${each.subscriber.user.last_name}`
                    }
                )
            })
        )
    }

    async retrieveStage(id) {
        const url = `delivery_stage/${id}`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        return (
            response.data
        )
    }

    async retrieveConditions() {
        const url = `delivery_state/${this.props.deliveryState.id}/conditions/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                conditions: response.data.conditions,
                total: response.data.total
            }
        );
    }

    async retrieveAttachments(){
        this.setState(
            {
                attachments: null
            }
        )
        const url = `attachment/`;
        const api = new BaseApi();
        const params = {
            deleted_bool: false,
            related_app: 'Opportunity',
            related_app_id: this.props.opportunityId,
            xtype: this.props.xtype,
        }
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                attachments: response.data.results,
            }
        );
    }

    async updateAttachments(id){
        await this.retrieveAttachments();
        this.setState(
            {
                preSelectedAttachments: [...this.state.preSelectedAttachments, id],
            }
        );
    }

    async processStep0(form, event) {
        let stage
        if (!this.state.stage){
            stage = form.state.formData.get('stage');
        }
        else {
            stage = this.state.stage;
        }
        let stage_obj = await this.retrieveStage(stage);
        let operators = await this.retrieveOperators(stage_obj.operator);
        this.setState(
            {
                activeStep: 1,
                stage: stage,
                operators: operators,
            }
        );
    }

    async processStep1(form, event) {
        let operator = form.state.formData.get('operator');
        this.setState(
            {
                activeStep: 2,
                operator: operator,
            }
        );
    }

    async processStep2(form, event) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        if (this.state.operator){
            formDataObj.operator = this.state.operator
        }
        if (this.state.stage){
            formDataObj.stage = this.state.stage
        }
        formDataObj.delivery = this.props.deliveryState.delivery
        if ('attachments' in formDataObj) {
            formDataObj.attachments = splitToList(formDataObj.attachments, ',')
        }
        const url = `delivery_state/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj,
        );
        if (response.status === 201) {
            await this.props.callBack();
        }
    }

    async setStep(order) {
        let formData = new FormData();
        let formFake = {'state': {'formData': formData}}
        if (order === 0){
            await this.componentDidMount()
        }
        else {
            await this['processStep'+(order-1)](formFake)
        }
        this.setState(
            {activeStep: order}
        )
    }

    render() {
        if (this.state.conditions === null){
            return (<CircularProgress/>)
        }
        if (this.state.attachments === null){
            return (<CircularProgress/>)
        }
        if (!this.state.total){
            return (
                <Card
                    color={'warning'}
                    variant="soft"
                >
                    <CardContent>
                        <Typography
                            level={'body-md'}
                        >
                            Требуется выполнить все условия стадии
                        </Typography>
                    </CardContent>
                </Card>
            )
        }
        if (this.state.activeStep === 0){
            return(
                <Card
                    color={'success'}
                    variant={'soft'}
                >
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        key={'step-0-form'}
                        fields={[
                            {
                                name: 'stage',
                                label: 'Стадия',
                                size: 'sm',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: this.state.stages?this.state.stages[0].id:null,
                                type: 'radio',
                                options: this.state.stages.map((each) => {return(
                                    {'value': each.id, 'label': each.title}
                                )})
                            },
                            {
                                type: 'submit',
                                label: 'Далее',
                                color: 'success',
                            }
                        ]}
                        object={null}
                        processForm={this.processStep0.bind(this)}
                    />
                </Card>
            )
        }
        else if (this.state.activeStep === 1) {
            return(
                <Card
                    color={'success'}
                    variant={'soft'}
                >
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        key={'step-1-form'}
                        fields={[
                            {
                                name: 'operator',
                                label: 'Исполнитель',
                                size: 'sm',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: this.state.operators?this.state.operators[0].value:null,
                                type: 'radio',
                                options: this.state.operators
                            },
                            {
                                type: 'submit',
                                label: 'Далее',
                                color: 'success',
                            }
                        ]}
                        object={null}
                        processForm={this.processStep1.bind(this)}
                    />
                </Card>
            )
        }
        else if (this.state.activeStep === 2) {
            return(
                <Card
                    color={'success'}
                    variant={'soft'}
                >
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <AttachmentCreate
                        appId={this.props.opportunityId}
                        appName={'Opportunity'}
                        xtype={this.props.xtype}
                        callBack={this.updateAttachments.bind(this)}
                    />
                    <CommonForm
                        key={'step-2-form'}
                        fields={[
                            {
                                name: 'attachments',
                                label: 'Вложения',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: this.state.stage?this.state.stage.is_attach_needed:false,
                                default: this.state.preSelectedAttachments,
                                type: 'multiselect',
                                options: this.state.attachments.map((each) => {return(
                                    {'value': each.id, 'label': each.uploadname}
                                )})
                            },
                            {
                                name: 'note',
                                label: 'Комментарий',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: null,
                                type: 'textarea',
                                users: true,
                            },
                            {
                                type: 'submit',
                                label: 'Продвинуть',
                                color: 'success',
                            }
                        ]}
                        object={null}
                        processForm={this.processStep2.bind(this)}
                    />
                </Card>
            )
        }
        return (
            <></>
        )
    }
}

export default DeliveryStateCreate