// components
import CommonForm from "../Common/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TableViewIcon from "@mui/icons-material/TableView";


class ContactCreateModal extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Добавление контакта',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        const url = `contact/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return response.data
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        formDataObj.account = this.props.account.id;
        const url = `contact/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            await this.props.callBack();
            this.setModalOpenFalse();
        }
    }
}

export default ContactCreateModal