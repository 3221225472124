import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";

// components
import CommunicationsCard from "../Communication/CommunicationsCard";
import CommonInfoTab from "../Common/CommonInfoTab";

// icons
import InfoIcon from "@mui/icons-material/Info";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class AccountTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            updatePermission: null,
        };
    }

    async componentDidMount(){
        await this.retrieveUpdatePermission();
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'AccountViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        this.setState(
            {
                updatePermission: p
            }
        );
    }

    render() {
        if (this.state.updatePermission === null) {
            return (<CircularProgress/>)
        }
        return(
            <Stack
                spacing={2}
            >
                <CommonInfoTab
                    callBack={this.props.callBack}
                    groups={
                        [
                            {
                                title: 'Общая информация',
                                icon: <InfoIcon/>,
                                fields: [
                                    {
                                        title: 'Полное наименование',
                                        value: this.props.account.namelong,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['ownership', 'namelong', 'nameshort' ],
                                            baseUrl: 'account',
                                            obj: this.props.account
                                        }
                                    },
                                    {
                                        title: 'Описание',
                                        value: this.props.account.desc,
                                        html: true,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['desc', ],
                                            baseUrl: 'account',
                                            obj: this.props.account
                                        }
                                    },
                                    {
                                        title: 'Сайт',
                                        value: this.props.account.weburl,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['weburl', ],
                                            baseUrl: 'account',
                                            obj: this.props.account,
                                        },
                                        linkPattern: this.props.account.weburl,
                                        linkTarget: '_blank',
                                    },
                                    {
                                        title: 'Отрасли',
                                        value: this.props.account.industries,
                                        fieldFromArray: 'title',
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: [],
                                            m2mFields: ['industries', ],
                                            baseUrl: 'account',
                                            obj: {
                                                id: this.props.account.id,
                                                industries: this.props.account.industries.map((item) => (item.id))
                                            }
                                        }
                                    },
                                    {
                                        title: 'Специализации',
                                        value: this.props.account.specializations,
                                        fieldFromArray: 'title',
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: [],
                                            m2mFields: ['specializations', ],
                                            baseUrl: 'account',
                                            obj: {
                                                id: this.props.account.id,
                                                specializations: this.props.account.specializations.map((item) => (item.id))
                                            }
                                        }
                                    },
                                    {
                                        title: 'Работаем по ЭДО',
                                        value: this.props.account.is_edm,
                                        fieldFromArray: 'title',
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['is_edm', ],
                                            baseUrl: 'account',
                                            obj: this.props.account
                                        }
                                    },
                                ]
                            }
                        ]
                    }
                />
                <CommunicationsCard
                    related_app={'Account'}
                    related_app_id={this.props.account.id}
                />
            </Stack>
        )
    }
}

export default AccountTab