import React from 'react';
import Card from "@mui/joy/Card";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";
import {Stack} from "@mui/joy";
import Divider from "@mui/joy/Divider";

// components
import RouteStateConditionsTable from "./RouteStateConditionsTable";
import AttachmentListItem from "../Attachment/AttachmentListItem";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";


class RouteStateCardInner extends React.Component {
    render() {
        return (
            <Grid container spacing={2}>
                <Grid xs={12} md={6}>
                    <Typography level={'body-sm'} sx={{mb: 1}}>Исполнитель:</Typography>
                    <ProfileHorizontalByProps
                        user={this.props.state.operator.user}
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <Typography level={'body-sm'} sx={{mb: 1}}>Дней в работе:</Typography>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Chip
                            color={this.props.state.delay[1]?'success':'danger'}
                        >
                            {this.props.state.delay[0]}
                        </Chip>
                        <Typography level={'body-sm'} sx={{mb: 1}}>из</Typography>
                        <Chip
                            color={'primary'}
                        >
                            {this.props.state.stage.end_delay}
                        </Chip>
                    </Stack>
                </Grid>
                <Grid xs={12} md={6}>
                    <Typography level={'body-sm'} sx={{mb: 1}}>Создана:</Typography>
                    <Typography level={'body1'}>
                        {this.props.state.created_on}
                    </Typography>
                </Grid>
                {
                    !this.props.hideCompleted?
                        <>
                            <Grid xs={12} md={6}>
                                <Typography level={'body-sm'} sx={{mb: 1}}>Пройдена:</Typography>
                                <Typography level={'body1'}>
                                    {this.props.state.passed_by?this.props.state.updated_on:'-'}
                                </Typography>
                            </Grid>

                            <Grid xs={12} md={12}>
                                <Typography level={'body-sm'} sx={{mb: 1}}>Вложение:</Typography>
                                {this.props.state.attachment?
                                    <Card
                                        variant={'soft'}
                                    >
                                        <AttachmentListItem
                                            attachment={this.props.state.attachment}
                                        />
                                    </Card>
                                    :
                                    '-'
                                }
                            </Grid>
                            <Grid xs={12} md={12}>
                                <Typography level={'body-sm'} sx={{mb: 1}}>Комментарий:</Typography>
                                <div dangerouslySetInnerHTML={{__html: this.props.state.note?this.props.state.note:'-'}} />
                            </Grid>
                        </>:
                        null
                }
                {
                    this.props.conditions?
                        <Grid xs={12} md={12}>
                            <Divider>
                                <Chip
                                    variant="soft"
                                    color="neutral"
                                >
                                    Условия стадии
                                </Chip>
                            </Divider>
                            <RouteStateConditionsTable
                                routeStateId={this.props.state.id}
                            />
                        </Grid>:
                        null
                }
            </Grid>
        )
    }
}

export default RouteStateCardInner