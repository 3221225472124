import React from "react";

import {CircularProgress, ListDivider} from "@mui/joy";
import List from "@mui/joy/List";
import Box from "@mui/joy/Box";

// components
import AttachmentCreate from "./AttachmentCreate";
import BaseApi from "../../utils/BaseApi";


class AttachmentCategoryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attachments: null,
        };
    }

    async componentDidMount() {
        await this.retrieveAttachments();
    }

    async retrieveAttachments(){
        const url = `attachment/`;
        const api = new BaseApi();
        const params = {
            deleted_bool: false,
            related_app: this.props.appName,
            related_app_id: this.props.appId,
            xtype: this.props.category,
        }
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                attachments: response.data.results,
            }
        );
    }

    render() {
        if (!this.state.attachments){
            return (
                <>
                    <CircularProgress/>
                </>
            )
        }
        return(
            <Box>
                {
                    this.props.create?
                        <AttachmentCreate
                            appId={this.props.appId}
                            appName={this.props.appName}
                            xtype={this.props.category}
                            callBack={this.retrieveAttachments.bind(this)}
                        />:
                        null
                }
                <List
                    aria-labelledby="ellipsis-list-demo"
                    sx={{
                        '--ListItemDecorator-size': '56px',
                    }}
                >
                    {this.state.attachments.map(each => {
                        return(
                            <>
                                <this.props.itemComponent
                                    attachment={each}
                                    fsDirectory={this.props.fsDirectory}
                                    callBack={this.retrieveAttachments.bind(this)}
                                />
                                <ListDivider inset={'gutter'} />
                            </>
                        )
                    })}
                </List>
            </Box>
        )
    }
}

export default AttachmentCategoryList
