import React from "react";
import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Common/CommonForm";
import SearchResults from "./SearchResults";

class SearchInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            query: ''
        };
    }

    async performSearch(query) {
        const url = 'search/';
        const api = new BaseApi();
        const params = {
            query: query
        }
        let response = await api.get(url, params);
        if (response.status === 200){
            return response.data
        }
        else{
            return []
        }
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        let results = await this.performSearch(formDataObj.query);
        this.setState({
            results: results,
            query: formDataObj.query,
        });
    }

    render() {
        return(
            <>
                <CommonForm
                    fields={
                        [
                            {
                                'name': 'query',
                                'label': 'Запрос',
                                'xs': 12,
                                'sm': null,
                                'md': null,
                                'lg': null,
                                'xl': null,
                                'required': true,
                                'default': this.state.query,
                                'type': 'input',
                            },
                            {
                                'type': 'submit',
                                'label': 'Искать',
                            }
                        ]
                    }
                    processForm={this.processForm.bind(this)}
                />
                <SearchResults
                    results={this.state.results}
                    callBack={this.props.callBack}
                />
            </>
        )
    }
}

export default SearchInner
