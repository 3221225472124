import React from 'react';
import CommonSidebar from "../Common/CommonSidebar";

//icons
import BarChartIcon from '@mui/icons-material/BarChart';

class KpiSidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={[
                    {
                        title: 'КПЭ',
                        items: [
                            {
                                title: 'Мои',
                                link: '/kpi/',
                                icon: BarChartIcon,
                            },
                             {
                                title: 'Коллег',
                                link: '/kpi/subordinate/',
                                icon: BarChartIcon,
                            },
                        ],
                    }
                    ]
                }
            />
        )
    }
}

export default KpiSidebar;