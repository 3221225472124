import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import CardContent from '@mui/joy/CardContent';
import Divider from '@mui/joy/Divider';
import Grid from "@mui/joy/Grid";
import {CardActions} from "@mui/joy";
import Box from "@mui/joy/Box";
import {Stack} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import CalculatorItemCreate from "./CalculatorItemCreate";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import {reorder} from "../../utils/misc";
import CalculatorItem from "./CalculatorItem";
import CalculatorItemUpdate from "./CalculatorItemUpdate";
import withRouter from "../../utils/withRouter";

// icons
import IconButton from "@mui/joy/IconButton";
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import AddBoxIcon from '@mui/icons-material/AddBox';


class Calculator extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
        this.colors = [
            '#4e79a7',
            '#f28e2c',
            '#e15759',
            '#76b7b2',
            '#59a14f',
            '#edc949',
            '#af7aa1',
            '#ff9da7',
            '#9c755f',
            '#bab0ab',
            '#1f77b4',
            '#ff7f0e',
            '#2ca02c',
            '#d62728',
            '#9467bd',
            '#8c564b',
            '#e377c2',
            '#7f7f7f',
            '#bcbd22',
            '#17becf',
        ];
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    async retrieveItems(){
        const url = `calculator/${this.props.params.calculatorId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let items = response.data.config
        for (let item of items){
            if (!item.charts){
                item.charts= [{}, ]
            }
        }
        this.setState(
            {
                items: items,
            }
        );
    }

    async componentDidMount() {
        await this.retrieveItems();
    }

    async onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );
        this.setState({
            items: items,
        });
        await this.postItems(items);
    }

    async setItemFull(id) {
        let newItems = [];
        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i]
            if (item.id === id){
                item.size = 12;
                newItems.push(
                    item
                )
            }
            else {
                newItems.push(
                    item
                )
            }
        }
        await this.postItems(newItems);
        this.setState({
            items: newItems,
        });
    }

    async setItemHalf(id) {
        let newItems = [];
        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i]
            if (item.id === id){
                item.size = 6;
                newItems.push(
                    item
                )
            }
            else {
                newItems.push(
                    item
                )
            }
        }
        await this.postItems(newItems);
        this.setState({
            items: newItems,
        });
    }

    async removeItem(id){
        let newItems = [];
        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i]
            if (item.id !== id){
                newItems.push(
                    item
                )
            }
        }
        await this.postItems(newItems);
        this.setState({
            items: newItems,
        });
    }

    async postItems(items){
        const url = `calculator/${this.props.params.calculatorId}/`;
        const api = new BaseApi();
        await api.patch(
            url,
            {
                config: items
            }
        );
    }

    async addItem(item){
        let items = this.state.items;
        items.push(item);
        await this.postItems(items);
        this.setState({
            items: items,
        });
    }

    async addChart(item){
        let newItem = item
        newItem.charts.push(
            window.structuredClone(newItem.charts[newItem.charts.length - 1])
        )
        await this.updateItem(newItem);
    }

    async updateItem(updatedItem) {
        let newItems = [];
        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i]
            if (item.id === updatedItem.id){
                let mergedItem = Object.assign(item, updatedItem)
                newItems.push(
                    mergedItem
                )
            }
            else {
                newItems.push(
                    item
                )
            }
        }
        await this.postItems(newItems);
        this.setState({
            items: newItems,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return(
            <>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <Grid
                                container
                                spacing={2}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {this.state.items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <Grid
                                                md={item.size}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <Card>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={1}
                                                        >
                                                            <Typography
                                                                level="title-md"
                                                                sx={{pt: 0.5}}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            <IconButton
                                                                size={'sm'}
                                                                color="neutral"
                                                                sx={{'ml': 1,}}
                                                                onClick={this.addChart.bind(this, item)}
                                                            >
                                                                <AddBoxIcon />
                                                            </IconButton>
                                                        </Stack>
                                                        <CardActions buttonflex="0 1 120px">
                                                            <CalculatorItemUpdate
                                                                item={item}
                                                                callBack={this.updateItem.bind(this)}
                                                            />
                                                            <IconButton
                                                                size={'sm'}
                                                                color="neutral"
                                                                sx={{'ml': 1,}}
                                                                onClick={() => {
                                                                    if (item.size===6){
                                                                        this.setItemFull.bind(this, item.id)()
                                                                    }
                                                                    else {
                                                                        this.setItemHalf.bind(this, item.id)()
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                                    item.size===6?
                                                                        <OpenInFullIcon />:
                                                                        <CloseFullscreenIcon />
                                                                }
                                                            </IconButton>
                                                            <IconButton
                                                                size={'sm'}
                                                                color="neutral"
                                                                sx={{'ml': 3,}}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <ControlCameraIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                size={'sm'}
                                                                color="neutral"
                                                                sx={{'ml': 1,}}
                                                                onClick={this.removeItem.bind(this, item.id)}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </CardActions>
                                                    </Box>
                                                    <Divider inset="none" sx={{ mt: 1, mb: 1 }} />
                                                    <CardContent>
                                                        <CalculatorItem
                                                            item={item}
                                                            updateItemCallBack={this.updateItem.bind(this)}
                                                            colors={this.colors}
                                                        />
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
                <CalculatorItemCreate
                    callBack={this.addItem.bind(this)}
                    modules={this.modules}
                    colors={this.colors}
                />
            </>
        )
    }
}

export default withRouter(Calculator);
