import React from "react";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import Box from "@mui/joy/Box";
import {Stack} from "@mui/joy";
import Link from "@mui/joy/Link";

// icons
import CloseIcon from '@mui/icons-material/Close';


class CommentReplyCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    switchOpen(){
        this.setState(
            {open: !this.state.open}
        )
    }

    render() {
        return (
            <Card
                variant={'soft'}
                size="sm"
            >
                <Stack
                    direction={'row'}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        level={'body-sm'}
                    >
                        В ответ на:
                    </Typography>
                    {
                        this.props.deleteCallBack?
                            <CloseIcon
                                fontSize={'small'}
                                onClick={this.props.deleteCallBack}
                            />:
                            null
                    }
                </Stack>
                <Card
                    size="sm"
                >
                    <Typography
                        level="body-sm"
                    >
                        <strong>
                            {this.props.comment.owner.first_name} {this.props.comment.owner.last_name}
                        </strong> от&nbsp;
                        {this.props.comment.created_on}
                    </Typography>
                    <Box
                        sx={{
                            fontSize: 'var(--joy-fontSize-sm, 0.875rem)',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            height: this.state.open?null:'36px',
                        }}
                    >
                        <div dangerouslySetInnerHTML={{__html: this.props.comment.content}} />
                    </Box>
                    {
                            !this.props.deleteCallBack?
                                this.state.open?
                                    <Link
                                        onClick={this.switchOpen.bind(this)}
                                        level="body-sm"
                                    >
                                        Свернуть
                                    </Link>:
                                    <Link
                                        onClick={this.switchOpen.bind(this)}
                                        level="body-sm"
                                    >
                                        Смотреть полностью
                                    </Link>:
                                null
                        }
                </Card>
            </Card>
        )
    }
}

export default CommentReplyCard
