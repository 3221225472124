import React from 'react';

// components
import CommonInfoTab from "../Common/CommonInfoTab";

// icons
import ApartmentIcon from '@mui/icons-material/Apartment';


class LeadCard extends React.Component {
    render() {
        return(
            <CommonInfoTab
                groups={
                    [
                        {
                            title: this.props.lead.title,
                            icon: <ApartmentIcon/>,
                            linkPattern: '/lead/detail/:linkParam',
                            linkParam: this.props.lead.id,
                            flat: true,
                            fields: [
                                {
                                    title: 'Описание',
                                    value: this.props.lead.desc,
                                    html: true,
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                },
                            ]
                        }
                    ]
                }
            />
        )
    }
}

export default LeadCard
