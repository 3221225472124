import * as React from 'react';

import { Outlet } from 'react-router-dom';

import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import {joyTheme} from "../../theme";
import {CssBaseline} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Box from '@mui/joy/Box';

import { AuthProvider } from '../../context/AuthContext';
import ScrollToTop from "./ScrollToTop";
import {UserSubscriberProvider,} from "../../context/UserSubscriberContext";
import BaseHeader from "../Header/BaseHeader";
import BaseSidebar from "../Sidebar/BaseSidebar";


export default function Root(){
    return (
        <AuthProvider>
            <UserSubscriberProvider>
                <ScrollToTop />
                <JoyCssVarsProvider theme={joyTheme}>
                    <CssBaseline enableColorScheme />
                    <Sheet
                        id={'main'}
                        sx={
                        {
                            display: 'flex',
                            minHeight: '100dvh',
                            maxHeight: '100dvh',
                            overflow: 'auto'
                        }
                    }>
                        <BaseHeader />
                        <BaseSidebar />
                        <Box
                            component="main"
                            className="MainContent"
                            sx={(theme) => ({
                                px: {
                                    xs: 2,
                                    md: 3,
                                },
                                pt: {
                                    xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
                                    sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
                                    md: `calc(${theme.spacing(2)} + var(--Header-height))`,
                                    lg: 3,
                                },
                                pb: {
                                    xs: 2,
                                    sm: 2,
                                    md: 3,
                                },
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: 0,
                                height: '100dvh',
                                gap: 1,
                            })}
                        >
                            <Outlet />
                        </Box>
                    </Sheet>
                </JoyCssVarsProvider>
            </UserSubscriberProvider>
        </AuthProvider>
    )
}
