import React from 'react';
import dayjs from "dayjs";

import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';

// components
import BaseApi from "../../utils/BaseApi";
import withRouter from "../../utils/withRouter";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";
import {b64toBlob, customRange, downloadBlob} from "../../utils/misc";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import CommonCallBackButton from "../Common/CommonCallBackButton";


// icons
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";


class ContractList extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            updatePermission: 0,
            contracts: null,
            filters: {
                year: new Date().getFullYear()
            },
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveContracts();
        await this.retrieveUpdatePermission();
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'ContractViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        await this.setState(
            {
                updatePermission: p
            }
        );
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveContracts();
        }
    }

    async filterCallBack(filters){
        await this.setState({
            filters: filters
        });
        await this.retrieveContracts();
    }

    async retrieveContracts() {
        const url = 'contract/';
        const api = new BaseApi();
        let params = {}
        params = {...params, ...this.state.filters};
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                contracts: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    async generate(generateParams, id){
        const api = new BaseApi();
        const url = `contract/${id}/generate/`;
        let params = {
        }
        if (generateParams[0]){
            params.service = true
        }
        if (generateParams[1]){
            params.download = true
        }
        if (generateParams[2]){
            params.attach = true
        }
        let response = await api.get(url, params);
        let timestamp = dayjs().format('YYYYMMDDHHmmss');
        if (params.download) {
            downloadBlob(
                b64toBlob(response.data),
                `contract_${id}_${timestamp}.docx`,
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            )
        }
    }

    render() {
        if (!this.state.contracts) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedTable
                    title={'Договоры'}
                    url={'contract/contract'}
                    data={this.state.contracts}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    filterFields={[
                        {
                            name: 'year',
                            label: 'Год',
                            xs: 12,
                            sm: null,
                            md: null,
                            lg: null,
                            xl: null,
                            required: true,
                            default: this.state.filters.year,
                            type: 'select',
                            options: customRange(2015, new Date().getFullYear() + 2).map(value => (
                                {value: value, label: value}
                            ))
                        },
                    ]}
                    filterCallBack={this.filterCallBack.bind(this)}
                    fields={[
                        {
                            title: 'Номер',
                            name: 'contract_number',
                            visible: true,
                            edit: {
                                permission: this.state.updatePermission,
                                fields: ['contract_number', ],
                                baseUrl: 'contract',
                                callBack: this.retrieveContracts.bind(this),
                            },
                        },
                        {
                            title: 'Компания',
                            name: 'account.nameshort',
                            linkPattern: '/account/detail/:linkParam',
                            linkParam: 'account.id',
                            visible: true,
                        },
                        {
                            title: 'Сделка',
                            name: 'short_name',
                            linkPattern: '/opportunity/detail/:linkParam',
                            linkParam: 'id',
                            visible: true,
                        },
                        {
                            title: 'Дата',
                            name: 'contract_date',
                            visible: true,
                            preProcess: (value) => (value?dayjs(value).format('DD.MM.YYYY'):value),
                            edit: {
                                permission: this.state.updatePermission,
                                fields: ['contract_date', ],
                                baseUrl: 'contract',
                                callBack: this.retrieveContracts.bind(this),
                            },
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                label: 'Услуги',
                                icon: DownloadIcon,
                                color: 'neutral',
                            },
                            callBack: this.generate.bind(this, [true, true, false]),
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                label: 'Услуги',
                                icon: AttachFileIcon,
                                color: 'neutral',
                            },
                            callBack: this.generate.bind(this, [true, false, true]),
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                label: 'Работы',
                                icon: DownloadIcon,
                                color: 'neutral',
                            },
                            callBack: this.generate.bind(this, [false, true, false]),
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                label: 'Работы',
                                icon: AttachFileIcon,
                                color: 'neutral',
                            },
                            callBack: this.generate.bind(this, [false, false, true]),
                        },
                    ]
                    }
                />
            </Box>
        )
    }
}

export default withRouter(ContractList)
