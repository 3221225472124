import React from "react";

import UserSubscriberContext from "../../context/UserSubscriberContext";
import {CircularProgress} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";
import Card from "@mui/joy/Card";
import {b64toBlob, downloadBlob} from "../../utils/misc";
import Link from "@mui/joy/Link";


class CommentAttachment extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            attachment: null,
            download: false,
        }
    }

    async componentDidMount() {
        await this.retrieveAttachment();
    }

    async retrieveAttachment() {
        const url = `attachment/${this.props.id}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                attachment: response.data
            }
        );
    }

    async download(){
        this.setState({
            download: true
        })
        const api = new BaseApi();
        const url = `attachment/${this.props.id}/download/`;
        let response = await api.get(url, {});
        downloadBlob(
            b64toBlob(response.data.content),
            response.data.filename,
            ''
        )
        this.setState({
            download: false
        })
    }

    render(){
        if (!this.state.attachment){
            return (<CircularProgress/>)
        }
        return (
            <Card>

                {
                    this.props.link?
                        <Link
                            onClick={this.download.bind(this)}
                            noWrap
                        >
                            {this.state.attachment.uploadname}
                        </Link>:
                        <>{this.state.attachment.uploadname}</>
                }
                {
                    this.state.download?
                        <CircularProgress
                          size="sm"
                        />:
                        null
                }
            </Card>
        )
    }
}

export default CommentAttachment