import React from "react";

import Typography from "@mui/joy/Typography";
import {Stack} from "@mui/joy";
import Chip from "@mui/joy/Chip";

// components
import retrievePermission from "../../utils/retrievePermission";
import CommonEditModal from "../Common/CommonEditModal";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class LeadDetailHeader extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            lead: this.props.lead,
        };
    }

    async componentDidMount(){
        await this.retrieveUpdatePermission();
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'LeadViewSet',
            'partial_update',
            this.context.subscriberObject
        )
        this.setState(
            {
                updatePermission: p,
            }
        )
    }

    setLead(lead){
        this.setState({
            lead: lead,
        })
    }

    render() {
        return(
            <Stack
                direction={'row'}
                spacing={2}
                alignItems="center"
                flexWrap={'nowrap'}
            >
                <Typography level={'h4'}>
                    {this.state.lead.title}
                </Typography>
                {this.state.updatePermission?
                    <CommonEditModal
                        fields={
                            [
                                'title',
                            ]
                        }
                        baseUrl={'lead'}
                        obj={this.state.lead}
                        callBack={this.setLead.bind(this)}
                        flat={true}
                    />:
                    null
                }
                <Chip
                    color={((value) => {
                        switch (value){
                            case 'OPEN':
                                return 'warning'
                            case 'CLOSE':
                                return 'success'
                            case 'LOST':
                                return 'danger'
                            default:
                                return 'primary'
                        }
                    })(this.state.lead.status)}
                    sx={{
                        mr: 1
                    }}
                >
                    {this.state.lead.status_human}
                </Chip>
            </Stack>
        )
    }
}

export default LeadDetailHeader