import React from "react";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";

// icons
import LogoutIcon from "@mui/icons-material/Logout";

import withNavigate from "../../utils/withNavigate";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import UserAvatar from "../Common/UserAvatar";


class ProfileMini extends React.Component {
    static contextType = UserSubscriberContext

    render() {
        return(
            <Box sx={{ pl: 1, mt: 'auto', display: 'flex', alignItems: 'center' }}>
                <UserAvatar />
                <Box
                    sx={{ml: 2}}
                >
                    <Typography
                        fontWeight="lg"
                        level="body-sm"
                    >
                        {this.context.userObject.first_name} {this.context.userObject.last_name}
                    </Typography>
                    <Typography
                        level="body-sm"
                    >@{this.context.userObject.username}</Typography>
                </Box>
            </Box>
        )
    }
}

export default withNavigate(ProfileMini);
