import React from 'react';
import {CircularProgress, Table} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import TaskDetail from "../Task/TaskDetail";

// icons
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Typography from "@mui/joy/Typography";


class DeliveryStateConditionsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            conditions: null,
            total: null,
        };
    }

    async componentDidMount() {
        await this.retrieveConditions();
    }

    async retrieveConditions() {
        const url = `delivery_state/${this.props.deliveryStateId}/conditions/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                conditions: response.data.conditions,
                total: response.data.total
            }
        );
    }

    render() {
        if (!this.state.conditions) {
            return (<CircularProgress/>)
        }
        if (this.state.conditions.length === 0) {
            return (
                <Typography level={'body-md'}>
                    Стадия не предполагает условий для прохождения
                </Typography>
            )
        }
        return (
            <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                    '--td-headBackground': (theme) =>
                        theme.vars.palette.background.level1,
                    '--Table-headerUnderlineThickness': '1px',
                    '--tr-hoverBackground': (theme) =>
                        theme.vars.palette.background.level1,
                }}
            >
                <thead>
                    <tr>
                        <th>
                            Сущность
                        </th>
                        <th>
                            Поле
                        </th>
                        <th>
                            Ожидаемое значение
                        </th>
                        <th>
                            Текущее Значение
                        </th>
                        <th>
                             Результат
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.conditions.map(
                            each => {
                                return (
                                    <tr>
                                        <td>
                                            {each.instance_title}
                                            {
                                                each.instance_type === 'Task'?
                                                    <TaskDetail
                                                        taskId={each.instance_id}
                                                        callBack={this.props.callBack}
                                                    />
                                                    :
                                                    null

                                            }
                                        </td>
                                        <td>
                                            {each.field_name}
                                        </td>
                                        <td>
                                            {typeof each.expected_value === 'boolean'?each.expected_value?<CheckIcon color={'success'}/>:<ClearIcon color={'danger'}/>:each.expected_value}
                                        </td>
                                        <td>
                                            {typeof each.value === 'boolean'?each.value?<CheckIcon color={'success'}/>:<ClearIcon color={'danger'}/>:each.value}
                                        </td>
                                        <td>
                                            {each.result?<CheckIcon color={'success'}/>:<ClearIcon color={'danger'}/>}
                                        </td>
                                    </tr>
                                )
                            }
                        )
                    }
                </tbody>
            </Table>
        )
    }
}

export default DeliveryStateConditionsTable