import * as d3 from "d3";

const getFormattedNodes = (nodes, highlightCallBack) => {
    return nodes.map((node) => {
        node.obj.data.highlightCallBack = highlightCallBack
        return {
            id: `${node.obj.id}`,
            data: node.obj.data,
            position: {
                x: node.x,
                y: node.y,
            },
            type: 'mainNode',
            dragHandle: '.custom-drag-handle',
        };
    });
};

const getFormattedEdges = (edges) => {
    return edges.map((edge) => {
        return {
            id: `${edge.id}`,
            target: `${edge.target.id}`,
            source: `${edge.source.id}`,
            markerEnd: {type: "arrowclosed"},
            type: 'selfconnecting',
        };
    });
};

function simulateGraph(mockEdges, mockNodes, highlightCallBack) {
    const nodes = mockNodes.map((mNode) => {
        return(
            {
                id: mNode.id,
                x: mNode.position.x,
                y: mNode.position.y,
                obj: mNode,
            }
        )
    });
    const edges = mockEdges;
    const width = 1000;
    const height = 1000;

    const simulation = d3
        .forceSimulation()
        .force("charge", d3.forceManyBody().strength(5) )
        .force('y', d3.forceY().y(function(d) {
            return 0;
        }))
        .force("center", d3.forceCenter(width / 2, height / 2))
        .force(
            "link",
            d3.forceLink().id((d) => d.id).distance(function(d) {return 100;})
        ).force('collision', d3.forceCollide().radius(function(d) {
            return 350;
        }))


    // Add the nodes and edges to the simulation
    simulation.nodes(nodes);
    simulation.force("link").links(edges);
    for (let i = 0; i < 20; i++) {
        simulation.tick();
    }

    const formattedEdges = getFormattedEdges(edges);
    const formattedNodes = getFormattedNodes(simulation.nodes(), highlightCallBack);

    return {
        formattedEdges,
        formattedNodes
    };
}

export { simulateGraph };
