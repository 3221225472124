// components
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Common/CommonActionModal";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";

// icons
import BackupTableIcon from '@mui/icons-material/BackupTable';


class EstimationRevisionFastModal extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: BackupTableIcon,
            buttonLabel: '',
            buttonColor: 'dark',
            ready: true,
        })
    }

    async retrievePhases() {
        this.setState(
            {
                phases: null,
            }
        );
        const url = `estimation_phase/`;
        const api = new BaseApi();
        const params = {
            revision_fk: this.props.revision.id
        };
        let response = await api.get(
            url,
            params
        );
        return  response.data.results
    }

    async beforeModalOpen(){
        let data = await this.retrievePhases();
        return {
            modalLabel: `Трудоемкость ${this.props.revision.desc?this.props.revision.desc:'Ревизия '+this.props.revision.number}`,
            modalIcon: null,
            mainComponent: CommonPaginatedTable,
            mainComponentProps: {
                data: data,
                fields: [
                    {
                        title: 'Название',
                        name: 'title',
                        visible: true,
                        width: '60%',
                    },
                    {
                        title: 'Мин',
                        name: 'estimation_minimum',
                        visible: true,
                    },
                    {
                        title: 'Норм',
                        name: 'estimation_normal',
                        visible: true,
                    },
                    {
                        title: 'Макс',
                        name: 'estimation_maximum',
                        visible: true,
                    },
                ]
            },
        }
    }
}

export default EstimationRevisionFastModal
