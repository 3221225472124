import React from "react";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Avatar from "@mui/joy/Avatar";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Box from "@mui/joy/Box";
import {Stack} from "@mui/joy";

// components
import CommentRead from "./CommentRead";
import CommentLike from "./CommentLike";
import CommentFav from "./CommentFav";
import CommentDelete from "./CommentDelete";

import BaseApi from "../../utils/BaseApi";
import CommentReplyCard from "./CommentReplyCard";
import CommentAttachment from "./CommentAttachment";
import {retrieveImage} from "../../utils/misc";

// icons
import ReplyIcon from '@mui/icons-material/Reply';


class CommentItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: null,
        };
    }

    async retrieveImage(comment) {
        let image = await retrieveImage(
            'Subscriber',
            'image',
            comment.owner.subscriber
        )
        this.setState(
            {
                image: image
            }
        );
    }

    async componentDidMount() {
        if (this.props.comment.content === undefined) {
            await this.retrieveComment();
        }
        else {
            this.setState(
                {
                    comment: this.props.comment
                },
                await this.retrieveImage(this.props.comment)
            )
        }
    }

    async retrieveComment() {
        const url = `comment/${this.props.comment.id}/`;
        const params = {};
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                comment: response.data,
            },
            await this.retrieveImage(response.data)
        );
    }

    reply(){
        this.props.replyCallBack(this.state.comment)
    }

    render() {
        if (!this.state.comment){
            return null
        }
        return (
            <ListItem
                key={this.state.comment.id}
                color={this.props.favorite?'warning':'default'}
                variant={'soft'}
                sx={{
                    pl: 1,
                    pr: 1,
                    pt: 2,
                    pb: 1,
                    mr: 2,
                    borderRadius: 'md'
                }}
            >
                <ListItemDecorator sx={{alignSelf: 'flex-start', mr: 2}}>
                    <Avatar
                        src={`data:image/jpeg;base64,${this.state.image}`}
                    />
                </ListItemDecorator>
                <ListItemContent>
                    <Typography
                        level="body-sm"
                    >
                        <strong>
                            {this.state.comment.owner.first_name} {this.state.comment.owner.last_name}
                        </strong>
                        {
                            this.props.replyCallBack?
                                <ReplyIcon
                                    fontSize={'small'}
                                    color={'info'}
                                    sx={{
                                        ml: 1,
                                        mt: '2px',
                                        "&:hover": {
                                            cursor: 'pointer',
                                        }
                                    }}
                                    onClick={this.reply.bind(this)}
                                />:
                                null
                        }

                    </Typography>
                    <Typography
                        level="body-sm"
                    >
                        {this.state.comment.created_on}
                    </Typography>
                    <Card variant="outlined"
                        size={'sm'}
                        sx={{
                            mb: 1
                        }}
                    >
                        <CardContent
                            sx={{
                                overflowY: 'scroll',
                                width: '100%',
                            }}
                        >
                            {
                                this.state.comment.reply_to?
                                    <CommentReplyCard
                                        comment={this.state.comment.reply_to}
                                    />:
                                    null
                            }
                            <Box
                                sx={{
                                    fontSize: 'var(--joy-fontSize-sm, 0.875rem)',
                                }}
                            >
                                <div
                                    className={'commentItem'}
                                    dangerouslySetInnerHTML={{__html: this.state.comment.content}}
                                    style={{width: '100%', overflowY: 'scroll',}}
                                />
                            </Box>
                            <Stack
                                spacing={1}
                            >
                                {
                                    this.state.comment.attachments.map(
                                        (a) => (
                                            <CommentAttachment
                                                id={a}
                                                link={true}
                                            />
                                        )
                                    )
                                }
                            </Stack>
                        </CardContent>
                    </Card>
                    <Stack
                        direction={'row'}
                        spacing={1}
                    >
                        <CommentRead
                            commentID={this.state.comment.id}
                        />
                        <CommentLike
                            liked={this.state.comment.liked}
                            commentID={this.state.comment.id}
                        />
                        <CommentFav
                            comment={this.state.comment}
                            callBack={this.props.callBack}
                        />
                        <CommentDelete
                            comment={this.state.comment}
                            callBack={this.props.callBack}
                        />
                    </Stack>
                </ListItemContent>
            </ListItem>
        )
    }
}

export default CommentItem;
