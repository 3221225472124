import React from 'react';
import CommonSidebar from "../Common/CommonSidebar";

//icons
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CalculateIcon from '@mui/icons-material/Calculate';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';


class FinancialSidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={
                    [
                        {
                            title: 'Анализ',
                            items: [
                                {
                                    title: 'Фин. отчет',
                                    link: '/financial/report/',
                                    icon: AssessmentIcon,
                                },
                                {
                                    title: 'Вычислитель',
                                    link: '/financial/calculator/',
                                    icon: CalculateIcon,
                                },
                                {
                                    title: 'Статистика сделок',
                                    link: '/financial/opportunity_financial_report/',
                                    icon: QueryStatsIcon,
                                },
                            ],
                        },
                        {
                        title: 'Моделирование',
                        items: [
                            {
                                title: 'Фин. модель',
                                link: '/financial/model/',
                                icon: ModelTrainingIcon,
                            },
                        ],
                    }
                    ]
                }
            />
        )
    }
}

export default FinancialSidebar;
