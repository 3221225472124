import React from 'react';

import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import {Stack} from "@mui/joy";

// components
import DeliveryDetail from "./DeliveryDetail";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import retrievePermission from "../../utils/retrievePermission";
import DeliveryControlledEntityModal from "./DeliveryControlledEntityModal";
import CommonEditModal from "../Common/CommonEditModal";


class DeliveryCard extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            delivery: this.props.delivery,
            dcePermission: 0,
            updatePermission: 0,
        };
    }

    async componentDidMount(){
        await this.retrieveDcePermission();
        await this.retrieveUpdatePermission();
    }

    async retrieveDcePermission() {
        let p = await retrievePermission(
            'DeliveryControlledEntityViewSet',
            'create',
            this.context.subscriberObject
        )
        this.setState(
            {
                dcePermission: p,
            }
        )
    }
    
    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'DeliveryViewSet',
            'partial_update',
            this.context.subscriberObject
        )
        this.setState(
            {
                updatePermission: p,
            }
        )
    }

    setDelivery(delivery){
        this.setState({
            delivery: delivery,
        })
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Stack
                            direction={'row'}
                            alignItems="center"
                            spacing={2}
                            flexWrap={'nowrap'}
                        >
                            <Chip
                                color={'primary'}
                            >
                                {this.state.delivery.xtype_human}
                            </Chip>
                            <Typography
                                level={'body2'}
                                sx={{display: 'inline-flex'}}
                            >
                                {this.state.delivery.title}
                            </Typography>
                            {this.state.updatePermission?
                                <CommonEditModal
                                    fields={
                                        [
                                            'title',
                                        ]
                                    }
                                    baseUrl={'delivery'}
                                    obj={this.state.delivery}
                                    callBack={this.setDelivery.bind(this)}
                                    flat={true}
                                />:
                                null
                            }
                            <Chip
                                color={this.state.delivery.states[0].stage.color}
                            >
                                {this.state.delivery.states[0].stage.title}
                            </Chip>
                        </Stack>
                        <Stack
                            direction={'row'}
                        >
                            {this.state.dcePermission > 0?
                                <DeliveryControlledEntityModal
                                    delivery={this.state.delivery}
                                />:
                                null
                            }
                            <DeliveryDetail
                                delivery={this.state.delivery}
                                callBack={this.props.callBack}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        )
    }
}

export default DeliveryCard