import React from "react";
import {CircularProgress} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";

// icons
import DoneIcon from "@mui/icons-material/Done";
import CommonForm from "../Common/CommonForm";


class FinancialModelForecastParameters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunity: this.props.componentObj.opportunity,
        };
        this.formFields = [
            {
                'name': 'forecast_revenue',
                'label': 'Выручка',
                'xs': 4,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 0,
                'type': 'number',
            },
            {
                'name': 'forecast_prepay_rate',
                'label': 'Предоплата %',
                'xs': 2,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 50,
                'type': 'number',
            },
            {
                'name': 'forecast_workdays',
                'label': 'Загрузка (дней)',
                'xs': 2,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 0,
                'type': 'number',
            },
            {
                'name': 'forecast_workers',
                'label': 'Исполнители',
                'xs': 2,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 1,
                'type': 'float',
            },
            {
                type: 'submit',
                label: '',
                icon: <DoneIcon />,
                color: 'success',
                xs: 2
            }
        ]
    }

    async processForm(form) {
        this.setState(
            {
                opportunity: null,
            }
        );
        const api = new BaseApi();
        const url = `opportunity/${this.state.opportunity.id}/`;
        let response = await api.patch(
            url,
            form.state.formData
        );
        this.setState(
            {
                opportunity: response.data,
            }
        );
        this.props.callBack(response.data);
    }

    render() {
        if (!this.state.opportunity) {
            return (<CircularProgress />)
        }
        return (
            <CommonForm
                size={'sm'}
                fields={this.formFields}
                processForm={this.processForm.bind(this)}
                object={this.state.opportunity}
            />
        )
    }
}

export default FinancialModelForecastParameters