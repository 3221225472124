// components
import CommonActionModal from "../Common/CommonActionModal";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableViewIcon from "@mui/icons-material/TableView";
import DeliveryCreateInner from "./DeliveryCreateInner";


class DeliveryCreateModal extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить бизнес-процесс',
            buttonColor: 'neutral',
            buttonVariant: 'soft',
            ready: true,
        })
    }

    async beforeModalOpen(){
        return {
            modalLabel: 'Добавление бизнес-процесса',
            modalIcon: TableViewIcon,
            mainComponent: DeliveryCreateInner,
            mainComponentProps: {
                opportunity: this.props.opportunity,
                callBack: this.process.bind(this)
            },
        }
    }

    async process(id){
        await this.props.callBack(id);
        this.setModalOpenFalse()
    }
}

export default DeliveryCreateModal
