import React from 'react';

import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';

// components
import BaseApi from "../../utils/BaseApi";
import withRouter from "../../utils/withRouter";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";
import dayjs from "dayjs";


class IncomingLetterList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            letters: null,
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveLetters();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveLetters();
        }
    }

    async retrieveLetters() {
        const url = 'incoming_letter/';
        const api = new BaseApi();
        let params = {}
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                letters: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.letters) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedTable
                    title={'Входящие письма'}
                    url={'incoming_letter'}
                    data={this.state.letters}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    fields={[
                        {
                            title: 'Номер',
                            name: 'number',
                            visible: true,
                        },
                        {
                            title: 'Тема',
                            name: 'title',
                            linkPattern: '/incoming_letter/detail/:linkParam',
                            linkParam: 'id',
                            linkTarget: '_blank',
                            visible: true,
                        },
                        {
                            title: 'Компания',
                            name: 'account.nameshort',
                            linkPattern: '/account/detail/:linkParam',
                            linkParam: 'id',
                            linkTarget: '_blank',
                            visible: true,
                        },
                        {
                            title: 'Сделка',
                            name: 'opportunity.short_name',
                            linkPattern: '/opportunity/detail/:linkParam',
                            linkParam: 'id',
                            linkTarget: '_blank',
                            visible: true,
                        },
                        {
                            title: 'Вложение',
                            name: 'attachment.uploadname',
                            // linkPattern: '/opportunity/detail/:linkParam',
                            // linkParam: 'id',
                            // linkTarget: '_blank',
                            visible: true,
                        },
                        {
                            title: 'Создано',
                            name: 'created_on',
                            visible: true,
                            preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                        },
                        {
                            title: 'Обновлено',
                            name: 'updated_on',
                            visible: true,
                            preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                        },
                    ]
                    }
                />
            </Box>
        )
    }
}

export default withRouter(IncomingLetterList)