// component Cards
import TaskCard from "../Task/TaskCard";
import OpportunityCard from "../Opportunity/OpportunityCard";
import AccountCard from "../Account/AccountCard";
import ContactCard from "../Contact/ContactCard";
import DeliveryCard from "../Delivery/DeliveryCard";
import RouteCard from "../Route/RouteCard";
import FeatureRequestCard from "../FeatureRequest/FeatureRequestCard";
import BaseApi from "../../utils/BaseApi";
import ComponentCard from "./ComponentCard";
import LeadCard from "../Lead/LeadCard";


export const openSidebar = () => {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
};

export const closeSidebar = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
};

export const toggleSidebar = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
};


export function camelToSnakeCase(str){
    let s = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    if (Array.from(s)[0] === '_'){
        s = s.substring(1);
    }
    return s
}


export async function retrieveEntity(entity, id) {
    const url = `${camelToSnakeCase(entity)}/${id}`;
    const api = new BaseApi();
    let response = await api.get(url, {});
    if (response.status < 300){
        return response.data
    }
}


export async function retrieveComponentCard(entity, entity_id) {
    let component;
    switch (entity) {
        case 'Task':
            let task = await retrieveEntity(
                entity,
                entity_id
            )
            if (task){
                component = <TaskCard
                    task={task}
                />
            }
            break;
        case 'Comment':
            let comment = await retrieveEntity(
                entity,
                entity_id
            )
            if (comment){
                component = <ComponentCard
                    entity={comment.related_app}
                    entity_id={comment.related_app_id}
                />
            }

            break;
        case 'Opportunity':
            component = <OpportunityCard
                opportunityID={entity_id}
            />
            break;
        case 'Account':
            let account = await retrieveEntity(
                entity,
                entity_id
            )
            if (account){
                component = <AccountCard
                    account={account}
                />
            }
            break;
        case 'Contact':
            let contact = await retrieveEntity(
                entity,
                entity_id
            )
            if (contact){
                component = <ContactCard
                    contact={contact}
                />
            }
            break;
        case 'DeliveryState':
            let delivery;
            let deliveryState = await retrieveEntity(
                entity,
                entity_id
            )
            if (deliveryState){
                delivery = await retrieveEntity(
                    'Delivery',
                    deliveryState.delivery.id
                )
            }
            if (delivery){
                component = <DeliveryCard
                    delivery={delivery}
                />
            }
            break;
        case 'RouteState':
            let routeState = await retrieveEntity(
                entity,
                entity_id
            )
            if (routeState){
                component = <RouteCard
                    route={routeState.route}
                />
            }
            break;
        case 'FeatureRequest':
            let featureRequest = await retrieveEntity(
                entity,
                entity_id
            )
            if (featureRequest) {
                component = <FeatureRequestCard
                    featureRequest={featureRequest}
                />
            }
            break;
        case 'Lead':
            let lead = await retrieveEntity(
                entity,
                entity_id
            )
            if (lead){
                component = <LeadCard
                    lead={lead}
                />
            }

            break;
        default:
            break;
    }
    if (component){
        return component
    }
    return (
        <></>
    )
}
