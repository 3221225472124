import React from 'react';

// components
import QualificatorCreate from "./QualificatorCreate";
import QualificatorCard from "./QualificatorCard";


class QualificatorTab extends React.Component {
    constructor(props) {
        super(props);
        if (!this.props.qualificatorId) {
            this.state = {
                needCreate: true
            }
        }
        else {
            this.state = {
                qualificatorId: this.props.qualificatorId,
                needCreate: false
            }
        }
    }

    setNeedCreateFalse(qualificatorId){
        this.setState(
            {
                qualificatorId: qualificatorId,
                needCreate: false,
            }
        )
    }

    render() {
        if (this.state.needCreate) {
            return(
                <QualificatorCreate
                    opportunity={this.props.opportunity}
                    lead={this.props.lead}
                    callBack={this.setNeedCreateFalse.bind(this)}
                />
            )
        }
        return(
            <QualificatorCard
                qualificatorId={this.state.qualificatorId}
            />
        )
    }
}

export default QualificatorTab