import React from "react";

import {ThemeProvider, extendTheme as extendMaterialTheme} from '@mui/material/styles';
import {InputAdornment, ListSubheader, MenuItem, Select, TextField} from "@mui/material";

import {styled} from "@mui/joy/styles";
import dayjs from "dayjs";
import {stateToHTML} from "draft-js-export-html";
import ContentState from "draft-js/lib/ContentState";
import {convertFromHTML, } from "draft-js";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

import FormLabel from "@mui/joy/FormLabel";
import {Stack, Switch} from "@mui/joy";
import Input from "@mui/joy/Input";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Grid from "@mui/joy/Grid";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";

// components
import BaseApi from "../../utils/BaseApi";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";
import MUIRichTextEditor from '../MuiRte/MUIRichTextEditor.tsx';
import {deleteArrayItemByKeyValue, findArrayItemsByKeyValue} from "../../utils/misc";

// icons
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from "@mui/joy/Tooltip";


export const StyledInput = styled('input')(
    {
        border: 'none',
        minWidth: 0,
        outline: 0,
        padding: 0,
        paddingTop: '1em',
        flex: 1,
        color: 'inherit',
        backgroundColor: 'transparent',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontStyle: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        textOverflow: 'ellipsis',
        '&::placeholder': {
        opacity: 0,
        transition: '0.1s ease-out',
    },
        '&:focus::placeholder': {
        opacity: 1,
    },
        '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
        top: '0.5rem',
        fontSize: '0.75rem',
    },
        '&:focus ~ label': {
        //color: 'var(--Input-focusedHighlight)',
    },
        '&:-webkit-autofill': {
        alignSelf: 'stretch', // to fill the height of the root slot
    },
        '&:-webkit-autofill:not(* + &)': {
        marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
        paddingInlineStart: 'var(--Input-paddingInline)',
        borderTopLeftRadius:
          'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
        borderBottomLeftRadius:
          'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    },
});


export const StyledLabel = styled('label')(({ theme }) => ({
    position: 'absolute',
    lineHeight: 1,
    top: 'calc((var(--Input-minHeight) - 1em) / 2)',
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));


export const InnerInput = React.forwardRef(function InnerInput(props, ref) {
    const id = React.useId();
    return (
        <React.Fragment>
            <StyledInput {...props} ref={ref} id={id} />
            <StyledLabel htmlFor={id}>{props.label} {props.required?'*':null}</StyledLabel>
        </React.Fragment>
    );
});


export class CustomDateWrapperField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            api_value: this.props.defaultValue?dayjs(this.props.defaultValue).format(this.props.format):null,
            value: this.props.defaultValue?dayjs(this.props.defaultValue):null
        }
        if (localStorage.getItem("joy-mode") === 'dark'){
            this.theme = extendMaterialTheme({
                colorSchemes:{
                    dark: true,
                }
            })
        }
        else {
            this.theme = extendMaterialTheme({
            })
        }
    }

    set_api_value(value){
        this.setState(
            {
                api_value: value?dayjs(value).format(this.props.format):null,
                value: value?value:null,
            }
        )
    }

    render() {
        return(
            <ThemeProvider theme={this.theme}>
                <Stack
                    direction={'row'}
                    useFlexGap
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: 'wrap'
                    }}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="ru"
                    >
                        <this.props.component
                            sx={{
                                width: '90%'
                            }}
                            label={this.props.label}
                            required={this.props.required}
                            value={this.state.value}
                            onChange={(newValue, context) => {
                                this.set_api_value(newValue);
                            }}
                        />
                    </LocalizationProvider>
                    <CancelIcon
                        color={'warning'}
                        onClick={() => (this.set_api_value(null))}
                    />
                </Stack>
                <input
                    type={'text'}
                    value={this.state.api_value?this.state.api_value:''}
                    name={this.props.name}
                    style={{
                        visibility: 'hidden',
                        width: '1px',
                        height: '1px',
                        margin: 0,
                        padding: 0,
                    }}
                />
            </ThemeProvider>
        )
    }
}


export class CustomSelectField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'displayedOptions': []
        }
        this.defaultValue = null;
        if (this.props.options.length > 0){
            if (typeof this.props.options[0].value === 'number'){
                let intValue = parseInt(this.props.defaultValue);
                if (isNaN(intValue)){
                    this.defaultValue = this.props.defaultValue;
                }
                else {
                    this.defaultValue = intValue;
                }
            }
            else {
                this.defaultValue = this.props.defaultValue;
            }
        }
        if (localStorage.getItem("joy-mode") === 'dark'){
            this.theme = extendMaterialTheme({
                colorSchemes:{
                    dark: true,
                }
            })
        }
        else {
            this.theme = extendMaterialTheme({
            })
        }
    }

    componentDidMount() {
        this.setState(
            {
                'displayedOptions': this.props.options
            }
        );
    }

    containsText(text, searchText) {
        return(
            text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
        )
    }

    filterOptions(text) {
        this.setState(
            {
                'displayedOptions': this.props.options.filter((option) => this.containsText(option.label, text))
            },
        );
    }

    render() {
        return (
            <ThemeProvider theme={this.theme}>
                <Select
                    sx={{
                        padding: 1,
                    }}
                    slots={{ input: InnerInput }}
                    slotProps={{
                        input:
                            {
                                label: this.props.label,
                                placeholder: '',
                                type: 'text',
                                required: this.props.required,
                                name: this.props.name,
                            }
                        }}
                    MenuProps={{ autoFocus: false }}
                    required={this.props.required}
                    label={this.props.label}
                    name={this.props.name}
                    onClose={() => this.filterOptions.bind(this, '')}
                    defaultValue={
                        this.defaultValue?this.defaultValue:''
                    }
                >
                    <ListSubheader>
                        <TextField
                            size="small"
                            autoFocus
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => this.filterOptions(e.target.value)}
                            onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                                    e.stopPropagation();
                                }
                            }}
                        />
                    </ListSubheader>
                    {this.props.empty?
                        <MenuItem key={'empty-value'} value={'NaN'}>
                            {this.props.emptyLabel?this.props.emptyLabel:'---'}
                        </MenuItem>:
                        null
                    }
                    {
                        this.state.displayedOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))
                    }
                </Select>
            </ThemeProvider>
        )
    }
}


export class CustomMultiSelectField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'displayedOptions': []
        }
        if (localStorage.getItem("joy-mode") === 'dark'){
            this.theme = extendMaterialTheme({
                colorSchemes:{
                    dark: true,
                }
            })
        }
        else {
            this.theme = extendMaterialTheme({
            })
        }
    }

    componentDidMount() {
        this.setState(
            {
                'displayedOptions': this.props.options
            }
        );
    }

    containsText(text, searchText) {
        return(
            text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
        )
    }

    filterOptions(text) {
        this.setState(
            {
                'displayedOptions': this.props.options.filter((option) => this.containsText(option.label, text))
            },
        );
    }

    render() {
        return (
            <ThemeProvider theme={this.theme}>
                <Select
                    sx={{
                        padding: 1,
                    }}
                    MenuProps={{ autoFocus: false }}
                    defaultValue={this.props.defaultValue?this.props.defaultValue:[]}
                    required={this.props.required}
                    label={this.props.label}
                    name={this.props.name}
                    onClose={() => this.filterOptions.bind(this, '')}
                    multiple={true}
                    slots={{ input: InnerInput }}
                    slotProps={{ input:
                        {
                            label: this.props.label,
                            placeholder: '',
                            type: 'text',
                            required: this.props.required,
                            name: this.props.name,
                            // defaultValue: value,
                        }
                    }}
                >
                    <ListSubheader>
                        <TextField
                            size="small"
                            autoFocus
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => this.filterOptions(e.target.value)}
                            onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                                e.stopPropagation();
                            }
                            }}
                        />
                    </ListSubheader>
                    {
                        this.state.displayedOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))
                    }
                </Select>
            </ThemeProvider>
        )
    }
}


export class CustomMultiSelectBoxesField extends React.Component {
    constructor(props) {
        super(props);
        let options = this.props.options;
        let selectedOptions = [];
        for (let value of this.props.defaultValue?this.props.defaultValue:[]) {
            selectedOptions = [
                ...selectedOptions,
                ...findArrayItemsByKeyValue(options, 'value', value)
            ]
            options = deleteArrayItemByKeyValue(
                options,
                'value',
                value
            );
        }
        this.state = {
            availableOptions: options,
            displayedOptions: options,
            selectedOptions: selectedOptions,
            filterText: null,
        }
    }

    containsText(text, searchText) {
        return(
            text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
        )
    }

    filterOptions(text) {
        this.setState(
            {
                filterText: text,
                displayedOptions: this.state.availableOptions.filter(
                    (option) => this.containsText(option.label, text)
                )
            },
        );
    }

    selectOption(option){
        let selectedOptions = this.state.selectedOptions;
        selectedOptions.push(option);
        let availableOptions = this.state.availableOptions;
        availableOptions = deleteArrayItemByKeyValue(
            availableOptions,
            'value',
            option.value
        );
        this.setState(
            {
                availableOptions: availableOptions,
                selectedOptions: selectedOptions,
                displayedOptions: this.state.filterText?availableOptions.filter(
                    (option) => this.containsText(option.label, this.state.filterText)
                ):availableOptions,
            }
        );
    }

    unselectOption(option){
        let availableOptions = this.state.availableOptions;
        availableOptions.push(option);
        let selectedOptions = this.state.selectedOptions;
        selectedOptions = deleteArrayItemByKeyValue(
            selectedOptions,
            'value',
            option.value
        );
        this.setState(
            {
                availableOptions: availableOptions,
                selectedOptions: selectedOptions,
                displayedOptions: this.state.filterText?availableOptions.filter(
                    (option) => this.containsText(option.label, this.state.filterText)
                ):availableOptions,
            }
        );
    }

    render() {
        return (
            <Stack
                spacing={1}
            >
                <FormLabel>
                    {this.props.label}
                </FormLabel>
                <Grid
                    container
                    spacing={0}
                >
                    <Grid xs={12}>
                        <Input
                            size="small"
                            fullWidth
                            startDecorator={
                                <SearchIcon sx={{ml: 1}} />
                            }
                            onChange={(e) => this.filterOptions(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                    e.stopPropagation();
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid xs={6}
                        sx={{pl: 0}}
                    >
                        <Typography
                            sx={{mb: 1}}
                        >
                            Варианты:
                        </Typography>
                        <Card
                            sx={{
                                height: '200px',
                                overflowY: 'scroll'
                            }}
                        >
                            <CardContent>
                                {
                                    this.state.displayedOptions.map(option => (
                                        <Tooltip
                                            color="warning"
                                            title={option.label}
                                            variant="solid"
                                            size={'sm'}
                                            placement="right-start"
                                        >
                                            <Chip
                                                key={option.value}
                                                onClick={() => (this.selectOption(option))}
                                            >
                                                {option.label}
                                            </Chip>
                                        </Tooltip>
                                    ))
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        xs={6}
                        sx={{pr: 0}}
                    >
                        <Typography
                            sx={{mb: 1}}
                        >
                            Выбрано:
                        </Typography>
                        <Card
                            sx={{
                                height: '200px',
                                overflowY: 'scroll'
                            }}
                        >
                            <CardContent>

                                {
                                    this.state.selectedOptions.map(option => (
                                        <Tooltip
                                            color="warning"
                                            title={option.label}
                                            variant="solid"
                                            size={'sm'}
                                            placement="right-start"
                                        >
                                            <Chip
                                                key={option.value}
                                                onClick={() => (this.unselectOption(option))}
                                            >
                                                {option.label}
                                            </Chip>
                                        </Tooltip>
                                    ))
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <input
                    type={'text'}
                    value={this.state.selectedOptions.map((o) => (o.value)).join(",")}
                    name={this.props.name}
                    style={{
                        visibility: 'hidden',
                        width: '1px',
                        height: '1px',
                        margin: 0,
                        padding: 0,
                    }}
                />
            </Stack>
        )
    }
}


export class CustomSwitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.value
        }
    }

    setChecked(){
        this.setState({
            checked: !this.state.checked
        });
    }

    render() {
        return(
            <>
                <FormLabel>{this.props.label}</FormLabel>
                <Switch
                    checked={this.state.checked}
                    onChange={this.setChecked.bind(this)}
                    slots={{
                        input: null,
                    }}
                />
                <Input
                    sx={{
                        display: 'none',
                    }}
                    type={'input'}
                    required={this.props.required}
                    name={this.props.name}
                    checked={this.state.checked}
                    value={this.state.checked?'true':'false'}
                />
            </>
        )
    }
}


export class CustomTextarea extends React.Component {
    constructor(props) {
        super(props);
        let blocksFromHTML = convertFromHTML('');
        if (this.props.defaultValue){
            blocksFromHTML = convertFromHTML(this.props.defaultValue);
        }
        this.state = {
            value: this.props.defaultValue,
            defaultValue: ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            )
        };
    }

    async componentDidMount() {
        if (this.props.users){
            await this.retrieveUsers();
        }
    }

    async retrieveUsers() {
        const url = 'user/';
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        )
        this.setState(
            {
                users: response.data.results.map(each => {
                    return (
                        {
                            keys: [each.username],
                            value: '@' + each.username,
                            content: <ProfileHorizontalByProps
                                user={each}
                            />,
                        }
                    )
                })
            }
        );
    }

    handleEditorChange(state) {
        this.setState(
            {
                value: stateToHTML(state.getCurrentContent()),
            }
        );
    }

    render() {
        return(
            <>
                <FormLabel>{this.props.label}</FormLabel>
                <Card>
                    <Box
                        sx={{
                            minHeight: '200px'
                        }}
                    >
                        <MUIRichTextEditor
                            id={'muirichtexteditor-'+this.props.name}
                            controls={[
                                "title",
                                "bold",
                                "italic",
                                "numberList",
                                "bulletList",
                                "clear",
                            ]}
                            defaultValue={this.state.defaultValue}
                            label="Начните вводить здесь"
                            onChange={this.handleEditorChange.bind(this)}
                            autocomplete={
                                this.props.users?
                                    {
                                        strategies: [
                                            {
                                                items: this.state.users,
                                                triggerChar: "@",
                                                insertSpaceAfter: true
                                            },
                                        ]
                                    }:
                                    null
                            }
                        />
                        <Input
                            sx={{
                                display: 'none',
                            }}
                            type={'input'}
                            required={this.props.required}
                            name={this.props.name}
                            value={this.state.value}
                        />
                    </Box>
                </Card>
            </>

        )
    }
}


export class StringListField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.defaultValue?JSON.parse(this.props.defaultValue):[],
            value: this.props.defaultValue,
        }
    }

    handleChange(event){
        let formDataEntries= (new FormData(event.target.form)).entries();
        let value = [];
        for (let field of formDataEntries) {
            value.push(field[1]);
        }
        this.setState({
            fields: value,
            value: JSON.stringify(value),
        });
    }

    deleteRow(index){
        let newFields = [];
        for (let i = 0; i < this.state.fields.length; i++) {
            if (i !== index){
                newFields.push(
                    this.state.fields[i]
                )
            }
        }
        this.setState({
            fields: newFields,
            value: JSON.stringify(newFields),
        });
    }

    addRow(){
        let newFields = this.state.fields;
        newFields.push('');
        this.setState({
            fields: newFields,
            value: JSON.stringify(newFields),
        });
    }

    render() {
        return(
            <>
                <FormLabel>{this.props.label}</FormLabel>
                <form
                    onChange={this.handleChange.bind(this)}
                >
                    {
                        this.state.fields.map(
                            (field, index) => (
                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                >
                                    <Input
                                        type={'input'}
                                        required={this.props.required}
                                        name={index+'_key'}
                                        defaultValue={field}
                                    />
                                    <IconButton
                                        size={'sm'}
                                        color="danger"
                                        sx={{'ml': 3,}}
                                        onClick={this.deleteRow.bind(this, index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            )
                        )
                    }
                    <IconButton
                        size={'sm'}
                        color="success"
                        sx={{'ml': 3,}}
                        onClick={this.addRow.bind(this)}
                    >
                        <AddIcon />
                    </IconButton>
                </form>
                <Input
                    sx={{
                        display: 'none',
                    }}
                    type={'input'}
                    required={this.props.required}
                    name={this.props.name}
                    value={this.state.value}
                />
            </>
        )
    }
}


export class KeyValuePairsField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.defaultValue?Object.entries(JSON.parse(this.props.defaultValue)):{},
            value: this.props.defaultValue?this.props.defaultValue:'{}',
        }
    }

    handleChange(event){
        let formDataObj = Object.fromEntries(
            (new FormData(event.target.form)).entries()
        );
        let value = {};
        for (let i = 0; i < Math.floor(Object.entries(formDataObj).length / 2); i++) {
            value[formDataObj[i+'_key']] = formDataObj[i+'_value']
        }
        this.setState({
            value: JSON.stringify(value),
        });
    }

    deletePair(index){
        let newFields = [];
        for (let i = 0; i < this.state.fields.length; i++) {
            if (i !== index){
                newFields.push(
                    this.state.fields[i]
                )
            }
        }
        this.setState({
            fields: newFields,
        });
    }

    addPair(){
        let newFields = this.state.fields;
        newFields.push(
            ['', '']
        )
        this.setState({
            fields: newFields,
        });
    }

    render() {
        return(
            <>
                <FormLabel>{this.props.label}</FormLabel>
                <form
                    onChange={this.handleChange.bind(this)}
                >
                    {
                        this.state.fields.map(
                            (field, index) => (
                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                >
                                    <Input
                                        type={'input'}
                                        required={this.props.required}
                                        name={index+'_key'}
                                        defaultValue={field[0]}
                                    />
                                    <Input
                                        type={'input'}
                                        required={this.props.required}
                                        name={index+'_value'}
                                        defaultValue={field[1]}
                                    />
                                    <IconButton
                                        size={'sm'}
                                        color="danger"
                                        sx={{'ml': 3,}}
                                        onClick={this.deletePair.bind(this, index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            )
                        )
                    }
                    <IconButton
                        size={'sm'}
                        color="success"
                        sx={{'ml': 3,}}
                        onClick={this.addPair.bind(this)}
                    >
                        <AddIcon />
                    </IconButton>
                </form>
                <Input
                    sx={{
                        display: 'none',
                    }}
                    type={'input'}
                    required={this.props.required}
                    name={this.props.name}
                    value={this.state.value}
                />
            </>
        )
    }
}