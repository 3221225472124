import {
    extendTheme as extendJoyTheme,
} from "@mui/joy/styles";
import {
    extendTheme as extendMaterialTheme,
} from "@mui/material/styles";
import {indigo, lime, orange, purple, teal, cyan, grey, pink, deepPurple, lightBlue, lightGreen, red} from "@mui/material/colors";


function createLightColorConfig(name){
    return(
        {
            solidBg: `var(--joy-palette-${name}-400)`,
            solidActiveBg: `var(--joy-palette-${name}-500)`,
            outlinedBorder: `var(--joy-palette-${name}-700)`,
            outlinedColor: `var(--joy-palette-${name}-600)`,
            outlinedActiveBg: `var(--joy-palette-${name}-900)`,
            softColor: `var(--joy-palette-${name}-100)`,
            softBg: `var(--joy-palette-${name}-900)`,
            softActiveBg: `var(--joy-palette-${name}-900)`,
            plainColor: `var(--joy-palette-${name}-100)`,
            plainActiveBg: `var(--joy-palette-${name}-900)`
        }
    )
}

function createDarkColorConfig(name){
    return(
        {
            solidBg: `var(--joy-palette-${name}-400)`,
            solidActiveBg: `var(--joy-palette-${name}-500)`,
            outlinedBorder: `var(--joy-palette-${name}-700)`,
            outlinedColor: `var(--joy-palette-${name}-600)`,
            outlinedActiveBg: `var(--joy-palette-${name}-900)`,
            softColor: `var(--joy-palette-${name}-100)`,
            softBg: `var(--joy-palette-${name}-900)`,
            softActiveBg: `var(--joy-palette-${name}-900)`,
            plainColor: `var(--joy-palette-${name}-100)`,
            plainActiveBg: `var(--joy-palette-${name}-900)`
        }
    )
}

export const materialTheme = extendMaterialTheme({
    colorSchemes:{
        dark: true,
    }
})

export const joyTheme = extendJoyTheme(
    {
        transitions: materialTheme.transitions,
        shape: materialTheme.shape,
        shadows: materialTheme.shadows,
        typography: {
            'body-sm': {
                fontSize: "0.8rem"
            },
            'body-md': {
                fontSize: "0.9rem"
            },
            'title-md': {
                fontSize: "0.9rem"
            },
            'title-sm': {
                fontSize: "0.8rem"
            },
            'title-lg': {
                fontSize: "1.2rem"
            },
        },
        fontFamily: {
            display: 'Roboto',
            body: 'Roboto',
        },
        colorSchemes: {
            light: {
                palette: {
                    info: Object.assign(cyan, createLightColorConfig('info')),
                    lime: Object.assign(lime, createLightColorConfig('lime')),
                    purple: Object.assign(purple, createLightColorConfig('purple')),
                    teal: Object.assign(teal, createLightColorConfig('teal')),
                    orange: Object.assign(orange, createLightColorConfig('orange')),
                    indigo: Object.assign(indigo, createLightColorConfig('indigo')),
                    grey: Object.assign(grey, createLightColorConfig('grey')),
                    gray: Object.assign(grey, createDarkColorConfig('gray')),
                    pink: Object.assign(pink, createLightColorConfig('pink')),
                    dark: Object.assign(deepPurple, createLightColorConfig('dark')),
                    olive: Object.assign(lightGreen, createLightColorConfig('olive')),
                    'light-blue': Object.assign(lightBlue, createLightColorConfig('light-blue')),
                    action: Object.assign(lightBlue, createLightColorConfig('action')),

                    // default: Object.assign(grey, createLightColorConfig('default')),
                    text: Object.assign(grey, createLightColorConfig('text')),
                    common: Object.assign(grey, createLightColorConfig('common')),
                    // background: Object.assign(grey, createLightColorConfig('background')),
                    secondary: Object.assign(grey, createLightColorConfig('secondary')),

                    error: Object.assign(red, createLightColorConfig('error')),
                }
            },
            dark: {
                palette: {
                    info: Object.assign(cyan, createDarkColorConfig('info')),
                    lime: Object.assign(lime, createDarkColorConfig('lime')),
                    purple: Object.assign(purple, createDarkColorConfig('purple')),
                    teal: Object.assign(teal, createDarkColorConfig('teal')),
                    orange: Object.assign(orange, createDarkColorConfig('orange')),
                    indigo: Object.assign(indigo, createDarkColorConfig('indigo')),
                    grey: Object.assign(grey, createDarkColorConfig('grey')),
                    gray: Object.assign(grey, createDarkColorConfig('gray')),
                    pink: Object.assign(pink, createDarkColorConfig('pink')),
                    dark: Object.assign(deepPurple, createDarkColorConfig('dark')),
                    olive: Object.assign(lightGreen, createDarkColorConfig('olive')),
                    'light-blue': Object.assign(lightBlue, createDarkColorConfig('light-blue')),
                    action: Object.assign(lightBlue, createDarkColorConfig('light-blue')),

                    // default: Object.assign(grey, createDarkColorConfig('grey')),
                    text: Object.assign(grey, createDarkColorConfig('text')),
                    common: Object.assign(grey, createDarkColorConfig('common')),
                    // background: Object.assign(grey, createDarkColorConfig('background')),
                    secondary: Object.assign(grey, createDarkColorConfig('secondary')),

                    error: Object.assign(red, createDarkColorConfig('error')),
                }
            }
        }
    }
);

joyTheme.vars.opacity = materialTheme.vars.opacity;
joyTheme.vars.fontWeight = materialTheme.vars.fontWeight;
