import React from "react";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import Chip from "@mui/joy/Chip";
import Box from "@mui/joy/Box";
import Tooltip from "@mui/joy/Tooltip";

// components
import UserSubscriberContext from "../../context/UserSubscriberContext";
import BaseApi from "../../utils/BaseApi";
import {b64toBlob, downloadBlob} from "../../utils/misc";

//icons
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import TableViewIcon from '@mui/icons-material/TableView';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import DeleteIcon from "@mui/icons-material/Delete";
import {CircularProgress, Stack} from "@mui/joy";


class AttachmentListItem extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            download: false,
        }
        this.fileTypeIcons = {
            'doc': <InsertDriveFileIcon color={'primary'}/>,
            'docx': <InsertDriveFileIcon color={'primary'}/>,
            'xls':  <TableViewIcon color={'success'}/>,
            'xlsx': <TableViewIcon color={'success'}/>,
            'zip': <FolderZipIcon color={'primary'} />,
            'pdf': <PictureAsPdfIcon color={'danger'}/>,
        }
        this.fileType = this.props.attachment.uploadname.split('.').slice(-1)
    }

    async destroy() {
        const url = `attachment/${this.props.attachment.id}/`;
        const api = new BaseApi();
        await api.delete(url, {});
        await this.props.callBack();
    }

    async download(){
        this.setState({
            download: true
        })
        const api = new BaseApi();
        const url = `attachment/${this.props.attachment.id}/download/`;
        let response = await api.get(url, {});
        downloadBlob(
            b64toBlob(response.data.content),
            response.data.filename,
            ''
        )
        this.setState({
            download: false
        })
    }

    render() {
        return(
            <ListItem
                sx={{
                    width: '100%',
                }}
            >
                <ListItemDecorator
                    sx={{
                        mr: 2,
                    }}
                >
                    {
                        this.fileType in this.fileTypeIcons?
                        this.fileTypeIcons[this.fileType]:
                        <FilePresentIcon color={'primary'}/>
                    }
                </ListItemDecorator>
                <ListItemContent>
                    <Typography
                        level="title-sm"
                        sx={{
                            mb: 1,
                        }}
                    >
                        <Tooltip
                            title={this.props.attachment.uploadname}
                        >
                            <Stack
                                direction={'row'}
                                spacing={1}
                            >
                                {
                                    (this.props.attachment.upload || this.props.attachment.file_storage)?
                                         <Link
                                            onClick={this.download.bind(this)}
                                            noWrap
                                        >
                                            {this.props.attachment.uploadname}
                                        </Link>:
                                        <Link
                                            href={this.props.attachment.uploadname}
                                            target={'_blank'}
                                            noWrap
                                        >
                                            {this.props.attachment.uploadname}
                                        </Link>

                                }

                                {
                                    this.state.download?
                                        <CircularProgress
                                          size="sm"
                                        />:
                                        null
                                }
                            </Stack>
                        </Tooltip>
                    </Typography>
                    <Typography
                        level={'body-sm'}
                        sx={{mb: 1}}
                    >
                        {this.props.attachment.created_on}
                    </Typography>
                    {
                        this.props.fsDirectory!==null&&this.props.attachment.file_storage?
                            <Tooltip
                                title={`\\\\fs\\crm-prod\\${this.props.fsDirectory}\\${this.props.attachment.xtype}\\${this.props.attachment.uploadname}`}
                            >
                                <Typography
                                    level={'body-sm'}
                                    sx={{mb: 1}}
                                    noWrap
                                >
                                    \\fs\crm-prod\{this.props.fsDirectory}\{this.props.attachment.xtype}\{this.props.attachment.uploadname}
                                </Typography>
                            </Tooltip>
                            :null
                    }
                    {
                        this.props.attachment.desc?
                            <Typography
                                level={'body-sm'}
                                sx={{mb: 1}}
                                noWrap
                            >
                                {this.props.attachment.desc}
                            </Typography>:
                            null
                    }
                    <Box
                        sx={{
                            mb: 1,
                            display: 'inline-block',
                        }}
                    >
                        <ProfileHorizontalByProps
                            user={this.props.attachment.owner}
                        />
                    </Box>
                    <Chip
                        color={this.props.attachment.conf_type==='OPEN'?'primary':'danger'}
                    >
                        {this.props.attachment.conf_type_human}
                    </Chip>
                    {
                        (this.props.attachment.owner.id === this.context.userObject.id && !this.props.hideDelete)?
                            <Link
                                level="body-xs"
                                color={'danger'}
                                onClick={this.destroy.bind(this)}
                                sx={{ml: 1}}
                            >
                                <DeleteIcon sx={{mr: 1}}/>
                                Удалить
                            </Link>:
                            null
                    }

                </ListItemContent>
            </ListItem>
        )
    }
}

export default AttachmentListItem