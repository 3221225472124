import React from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import MUIRichTextEditor from '../MuiRte/MUIRichTextEditor.tsx'
import {stateToHTML} from "draft-js-export-html";
import TelegramIcon from "@mui/icons-material/Telegram";
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";

// components
import BaseApi from "../../utils/BaseApi";
import AuthContext from "../../context/AuthContext";


class Messenger extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            value: '',
            roomId: 1,
        };
    }

    async componentDidMount() {
        await this.retrieveMessages();
        let token = this.context.authTokens.access;
        this.client = new W3CWebSocket(
            `ws://127.0.0.1:7999/ws/room/${this.state.roomId}/?token=${token}`
        );
        this.client.onopen = () => {
            console.log("WebSocket Client Connected");
        };
        this.client.onclose = () => {
            console.log("WebSocket Client Disconnected");
        }
        this.client.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);
            if (dataFromServer) {
                this.setState((state) => ({
                    messages: [
                        {
                            content: dataFromServer.content,
                            owner: dataFromServer.owner,
                        },
                        ...state.messages,
                    ],
                }));
            }
        };
    }

    async retrieveMessages(){
        const url = 'message/';
        const api = new BaseApi();
        let params = {
            room: this.state.roomId,
        };
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                messages: response.data.results,
            }
        );
    }

    handleEditorChange(state) {
        this.setState(
            {
                value: stateToHTML(state.getCurrentContent()),
            }
        );
    }

    async postMessage(){
        let data = {
            type: "message",
            content: this.state.value,
        };
        await this.client.send(
            JSON.stringify(data)
        );
        this.setState(
            {
                value: ''
            }
        );
    }

    render() {
        return(
            <Sheet
                variant="plain"
                color="neutral"
                sx={
                    {
                        p: 2,
                        height: '100%',
                        alignContent: 'space-between',
                        display: 'flex',
                        flexWrap: 'wrap',
                        borderRadius: 'md',
                    }
                }
            >
                <Box sx={
                    {
                        height: '100%',
                        maxHeight: 600,
                        overflow: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }
                }>
                    {this.state.messages.map(each => {
                        return(
                            <Card
                                sx={{mt: 1}}
                            >
                                <CardContent>
                                    <Typography level={'body-md'}>
                                        <strong>
                                            {each.owner.first_name} {each.owner.last_name} написал {each.created_on}:
                                        </strong>
                                    </Typography>
                                    <div dangerouslySetInnerHTML={{__html: each.content}} />
                                </CardContent>
                            </Card>
                        )
                    })}
                </Box>
                <Box sx={
                    {
                        display: 'flex',
                        alignSelf: 'flex-end',
                        width: '100%',
                        flexDirection: 'column',
                        height: '200px',
                            overflowY: 'scroll',
                    }
                }>

                    <MUIRichTextEditor
                        controls={[
                            "title",
                            "bold",
                            "italic",
                            "numberList",
                            "bulletList",
                            "clear",
                        ]}
                        defaultValue={''}
                        label="Начните набор..."
                        onChange={this.handleEditorChange.bind(this)}
                        // autocomplete={{
                        //     strategies: [
                        //         {
                        //             items: this.state.users,
                        //             triggerChar: "@",
                        //             insertSpaceAfter: true
                        //         },
                        //     ]
                        // }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignSelf: 'flex-end',
                    }}
                >
                    <IconButton
                        size={'sm'}
                        color="primary"
                        onClick={this.postMessage.bind(this)}
                        sx={{mt: 1}}
                    >
                        <TelegramIcon />
                    </IconButton>
                </Box>
            </Sheet>
        )
    }
}

export default Messenger