import React from 'react';
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {Stack} from "@mui/joy";
import Card from "@mui/joy/Card";

// components
import CommonPaginatedTable from "../Common/CommonPaginatedTable";


class FinancialTotals extends React.Component {
    render() {
        let numberFormat = new Intl.NumberFormat('ru', {maximumFractionDigits: 2})
        return(
            <Stack>
                <Typography
                    level={'h4'}
                    sx={{
                        mb: 2
                    }}
                >
                   Итоги
                </Typography>
                <Stack
                    direction={'row'}
                    spacing={1}
                    sx={{
                        alignItems: "center",
                        mb: 1,
                    }}
                >
                    <Card
                        color="teal"
                        variant="soft"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Выручка
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.revenueFact + this.props.total.revenueForecast + this.props.total.revenueLeadTail)}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        level={'h4'}
                    >
                       =
                    </Typography>
                    <Card
                        color="teal"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Заключенные договоры
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.revenueFact)}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        level={'h4'}
                    >
                       +
                    </Typography>
                    <Card
                        color="teal"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Прогноз
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.revenueForecast)}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        level={'h4'}
                    >
                       +
                    </Typography>
                    <Card
                        color="teal"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Хвост лидов
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.revenueLeadTail)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={1}
                    sx={{
                        alignItems: "center",
                        mb: 1,
                    }}
                >
                    <Card
                        color="success"
                        variant="soft"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Входящие платежи
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.incomeFact + this.props.total.incomeForecast + this.props.total.incomeLeadTail)}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        level={'h4'}
                    >
                       =
                    </Typography>
                    <Card
                        color="success"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Заключенные договоры
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.incomeFact)}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        level={'h4'}
                    >
                       +
                    </Typography>
                    <Card
                        color="success"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Прогноз
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.incomeForecast)}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        level={'h4'}
                    >
                       +
                    </Typography>
                    <Card
                        color="success"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Хвост лидов
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.incomeLeadTail)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={1}
                    sx={{
                        alignItems: "center",
                        mb: 1,
                    }}
                >
                    <Card
                        color="danger"
                        variant="soft"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Траты
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.expense + this.props.total.salaries + this.props.total.tax + this.props.total.taxForecast)}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        level={'h4'}
                    >
                       =
                    </Typography>
                    <Card
                        color="danger"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Накладные расходы
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.expense)}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        level={'h4'}
                    >
                       +
                    </Typography>
                    <Card
                        color="danger"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                ФОТ
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.salaries)}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        level={'h4'}
                    >
                       +
                    </Typography>
                    <Card
                        color="danger"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Налог УСН
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.tax)}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        level={'h4'}
                    >
                       +
                    </Typography>
                    <Card
                        color="danger"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Налог УСН (прогноз)
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.taxForecast)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={1}
                    sx={{
                        alignItems: "center",
                        mb: 1,
                    }}
                >
                    <Card
                        color="purple"
                        variant="soft"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Прибыль
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.profit)} ({numberFormat.format(this.props.total.profitForecast)})
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        color="purple"
                        variant="soft"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Прибыль %
                            </Typography>
                            <Typography>
                                {numberFormat.format(100 * this.props.total.profit / (this.props.total.expense + this.props.total.salaries + this.props.total.tax))}&nbsp;
                                ({numberFormat.format(100 * this.props.total.profitForecast / (this.props.total.expense + this.props.total.salaries + this.props.total.tax + this.props.total.taxForecast))})
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        color="indigo"
                        variant="soft"
                    >
                        <CardContent>
                            <Typography
                                level={'body-xs'}
                            >
                                Баланс платежей
                            </Typography>
                            <Typography>
                                {numberFormat.format(this.props.total.balance)} ({numberFormat.format(this.props.total.balanceForecast)})
                            </Typography>
                        </CardContent>
                    </Card>
                </Stack>
                <Typography
                    level={'h4'}
                    sx={{
                        mb: 2
                    }}
                >
                   Кварталы
                </Typography>
                <CommonPaginatedTable
                        title={''}
                        data={
                            Object.entries(this.props.quarters).map((each) => {
                                let quarter = each[1];
                                if (this.props.miles){
                                    for (let k of Object.keys(quarter)){
                                        quarter[k] = quarter[k]/1000;
                                    }
                                }
                                quarter.number = each[0];
                                return quarter
                            })
                        }
                        fields={[
                            {
                                title: 'Квартал',
                                name: 'number',
                                visible: true,
                            },
                            {
                                title: 'Выручка (факт)',
                                name: 'revenueFact',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Выручка (прогноз)',
                                name: 'revenueForecast',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Выручка (хвост)',
                                name: 'revenueLeadTail',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Вх. платеж (факт)',
                                name: 'incomeFact',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Вх. платеж (прогноз)',
                                name: 'incomeForecast',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Вх. платеж (хвост)',
                                name: 'incomeLeadTail',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Накладные',
                                name: 'expense',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'ФОТ (сумма)',
                                name: 'salaries',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Баланс (факт)',
                                name: 'balance',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Баланс (прогноз + хвост)',
                                name: 'balanceForecast',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Прибыль (факт)',
                                name: 'profit',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Прибыль (прогноз + хвост)',
                                name: 'profitForecast',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                        ]}
                    />
            </Stack>
        )
    }
}

export default FinancialTotals
