import React from 'react';
import RenderIfVisible from 'react-render-if-visible'
import OpportunityCard from "./OpportunityCard";
import {Accordion, AccordionDetails, AccordionGroup, AccordionSummary, CircularProgress, Stack} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";
import Chip from "@mui/joy/Chip";


class OpportunityList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunities: null,
        }
    }

    async retrieveOpportunities() {
        const url = 'opportunity/report/';
        const params = {
            status: this.props.status,
            team: this.props.team.join(','),
            xtypes: this.props.xtypes.join(','),
            my_roles: this.props.myRoles,
            group: this.props.group,
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        this.setState({
            opportunities: response.data.report,
        });
    }

    async componentDidMount() {
        await this.retrieveOpportunities();
    }

    render() {
        if (!this.state.opportunities) {
            return (
                <CircularProgress/>
            )
        }
        return (
            <Stack>
                {
                    this.props.group === 'true'?
                        <AccordionGroup>
                            {
                                 this.state.opportunities.map(
                                    each => {
                                        return(
                                            <Accordion>
                                                <AccordionSummary>
                                                    <Stack
                                                        direction={'row'}
                                                        spacing={2}
                                                    >
                                                        <Chip
                                                            color={each.color}
                                                        >
                                                            {each.title}
                                                        </Chip>
                                                        <Chip>
                                                            {each.opportunities.length}
                                                        </Chip>
                                                    </Stack>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {each.opportunities.map(
                                                        opportunity => {
                                                            return (
                                                                <RenderIfVisible defaultHeight={400} stayRendered={true} key={each[0]}>
                                                                    <OpportunityCard
                                                                        opportunityID={opportunity[0]}
                                                                        color={opportunity[2]}
                                                                        showRange={true}
                                                                    />
                                                                </RenderIfVisible>
                                                            )
                                                        })}
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    }
                                )
                            }
                        </AccordionGroup>:
                        this.state.opportunities.map(
                            each => {
                                return (
                                    <RenderIfVisible defaultHeight={400} stayRendered={true} key={each[0]}>
                                        <OpportunityCard
                                            opportunityID={each[0]}
                                            color={each[2]}
                                            showRange={true}
                                        />
                                    </RenderIfVisible>
                                )
                            })
                }

            </Stack>
        )
    }
}

export default OpportunityList;