import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";

// components
import TaskDetailNextStatus from "./TaskDetailNextStatus";
import TaskDuration from "./TaskDuration";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import TaskDetailDelegate from "./TaskDetailDelagate";
import CommonInfoTab from "../Common/CommonInfoTab";
import dayjs from "dayjs";
import BaseApi from "../../utils/BaseApi";


class TaskTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            task: null,
        };
    }

    async componentDidMount(){
        await this.retrieveTask();
    }

    async retrieveTask() {
        const url = `task/${this.props.task.id}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                task: response.data
            }
        );
    }

    render() {
        if (!this.state.task) {
            return (<CircularProgress/>)
        }
        return(
            <Stack
                spacing={2}
            >
                <Stack
                    spacing={2}
                    direction={'row'}
                >
                    {
                        (
                            !['COM', 'DEL', 'CLD', 'DCL'].includes(this.state.task.work_status) && (
                                (this.state.task.executor.id === this.context.userObject.id && ['WRK', 'PSD'].includes(this.state.task.work_status)) ||
                                (this.state.task.owner.id === this.context.userObject.id && ['OAC',].includes(this.state.task.work_status))
                            )
                        )?
                            <TaskDetailNextStatus
                                taskId={this.state.task.id}
                                callBack={this.props.callBack}
                            />:
                            null
                    }
                    {
                        (['WRK',].includes(this.state.task.work_status) && !this.context.subscriberObject.external)?
                            <TaskDetailDelegate
                                taskId={this.state.task.id}
                                callBack={this.props.callBack}
                            /> :
                            null
                    }
                </Stack>
                <CommonInfoTab
                    callBack={this.props.callBack}
                    groups={
                        [
                            {
                                title: '',
                                icon: null,
                                hideCard: true,
                                fields: [
                                    {
                                        title: 'Описание',
                                        value: this.state.task.desc,
                                        html: true,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: !['COM', 'DEL', 'CLD', 'DCL'].includes(this.state.task.work_status) && this.state.task.owner.id === this.context.userObject.id,
                                            fields: ['desc', ],
                                            baseUrl: 'task',
                                            obj: this.state.task
                                        }
                                    },
                                    {
                                        title: 'Дата начала',
                                        value: this.state.task.start_dt?dayjs(this.state.task.start_dt).format('DD MMM YYYY'):null,
                                        xs: 12,
                                        sm: 6,
                                        md: 3,
                                        lg: 3,
                                        edit: {
                                            permission: !['COM', 'DEL', 'CLD', 'DCL'].includes(this.state.task.work_status) && this.state.task.owner.id === this.context.userObject.id,
                                            fields: ['start_dt', ],
                                            baseUrl: 'task',
                                            obj: this.state.task
                                        }
                                    },
                                    {
                                        title: 'Дата окончания',
                                        value: this.state.task.end_dt?dayjs(this.state.task.end_dt).format('DD MMM YYYY'):null,
                                        xs: 12,
                                        sm: 6,
                                        md: 3,
                                        lg: 3,
                                        edit: {
                                            permission: !['COM', 'DEL', 'CLD', 'DCL'].includes(this.state.task.work_status),
                                            fields: ['end_dt', ],
                                            baseUrl: 'task',
                                            obj: this.state.task
                                        }
                                    },
                                    {
                                        title: 'Плановая трудоемкость',
                                        value: this.state.task.duration_plan,
                                        xs: 12,
                                        sm: 6,
                                        md: 3,
                                        lg: 3,
                                        edit: {
                                            permission: !['COM', 'DEL', 'CLD', 'DCL'].includes(this.state.task.work_status) && this.state.task.owner.id === this.context.userObject.id,
                                            fields: ['duration_plan', ],
                                            baseUrl: 'task',
                                            obj: this.state.task
                                        }
                                    },
                                    {
                                        title: 'Фактическая трудоемкость',
                                        value: this.state.task.duration_fact,
                                        xs: 12,
                                        sm: 6,
                                        md: 3,
                                        lg: 3,
                                        edit: {
                                            permission: ['WRK', ].includes(this.state.task.work_status) && this.state.task.executor.id === this.context.userObject.id,
                                            fields: ['duration_fact', ],
                                            baseUrl: 'task',
                                            obj: this.state.task
                                        }
                                    },
                                ]
                            }
                        ]
                    }
                />
                <TaskDuration
                    task={this.state.task}
                />
            </Stack>
        )
    }
}

export default TaskTab