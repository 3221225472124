// components
import CommonActionModal from "../Common/CommonActionModal";
import CommonForm from "../Common/CommonForm";

// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TableViewIcon from "@mui/icons-material/TableView";
import BaseApi from "../../utils/BaseApi";
import {setFormFieldKey} from "../../utils/misc";


class NewQuoteEstimationRevisionCreateModal extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Добавление ТКП',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveRevisions(){
        const url = 'estimation_revision/';
        const params = {
            opportunity: this.props.quote.opportunity.id
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        return response.data.results
    }

    async retrieveFields(){
        const url = `new_quote_estimation_revisions/create_form/`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {
                fields: JSON.stringify([
                    'revision',
                    'add_text',
                    'order',
                ])
            }
        );
        let formFields = response.data
        let revisions = await this.retrieveRevisions()
        formFields = setFormFieldKey(
            formFields,
            'revision',
            'options',
            revisions.map((each) => (
                {'value': each.id, 'label': each.desc?each.desc:`Ревизия ${each.number}`}
            ))
        )
        return formFields
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.quote = this.props.quote.id
        const url = `new_quote_estimation_revisions/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            await this.props.callBack();
            await this.setModalOpenFalse();
        }
    }
}

export default NewQuoteEstimationRevisionCreateModal