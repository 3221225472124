import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';
import DeliveryTable from "./DeliveryTable";


class DeliverySubordinateList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveries: [],
        };
    }

    async componentDidMount() {
        await this.retrieveDeliveries();
    }

    async retrieveDeliveries() {
        const url = 'delivery_state/delay_sorted/';
        const params = {
            operator: this.props.subscriber.id,
            passed_by__isnull: true,
            delivery__isnull: false,
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                deliveries: response.data.results
            }
        );
    }

    render() {
        if (!this.state.deliveries) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <DeliveryTable
                    deliveries={this.state.deliveries}
                    showAccount={true}
                    showOpportunity={true}
                    callBack={this.retrieveDeliveries}
                />
            </Box>
        )
    }
}

export default DeliverySubordinateList;
