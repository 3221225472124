// components
import NewQuoteCreateInner from "./NewQuoteCreateInner";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TableViewIcon from "@mui/icons-material/TableView";


class NewQuoteCreateModal extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            ready: true,
        })
    }

    async callBack(){
        await this.props.callBack();
        this.setModalOpenFalse()
    }

    async beforeModalOpen(){
        return {
            modalLabel: 'Добавление ТКП',
            modalIcon: TableViewIcon,
            mainComponent: NewQuoteCreateInner,
            mainComponentProps: {
                opportunity: this.props.opportunity,
                callBack: this.callBack.bind(this),
            },
        }
    }
}

export default NewQuoteCreateModal