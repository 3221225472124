import React from "react";
import {CircularProgress,} from "@mui/joy";

// components
import UserSubscriberContext from "../../../context/UserSubscriberContext";
import CommonPaginatedTable from "../../Common/CommonPaginatedTable";
import TeamCreate from "./TeamCreate";
import BaseApi from "../../../utils/BaseApi";
import TeamDelete from "./TeamDelete";


class TeamTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            team: null,
        };
    }

    async componentDidMount() {
        await this.retrieveTeam();
    }

    async retrieveTeam() {
        const url = `opp_team/`;
        const api = new BaseApi();
        const params = {
            opportunity: this.props.opportunity.id,
            ordering: 'subscriber',
        };
        let response = await api.get(url, params);
        this.setState(
            {
                team: response.data.results
            }
        );
    }

    render() {
        if (!this.state.team) {
            return (<CircularProgress/>)
        }
        return (
            <CommonPaginatedTable
                data={this.state.team}
                createComponent={TeamCreate}
                createComponentProps={{
                    opportunity: this.props.opportunity
                }}
                callBack={this.retrieveTeam.bind(this)}
                fields={[
                    {
                        title: 'Сотрудник',
                        name: 'subscriber.user.first_name',
                        linkPattern: '/contact/detail/:linkParam',
                        linkParam: 'contact.id',
                        linkTarget: '_blank',
                        visible: true,
                    },
                    {
                        title: '',
                        name: 'subscriber.user.last_name',
                        visible: true,
                    },
                    {
                        title: 'Роль',
                        name: 'role_human',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => (value),
                        color_value: 'color',
                    },
                    {
                        title: '',
                        visible: true,
                        use_component: true,
                        component: TeamDelete,
                        callBack: this.retrieveTeam.bind(this),
                    },
                ]}
            />
        )
    }
}

export default TeamTab
