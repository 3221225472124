import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';
import withRouter from "../../utils/withRouter";
import CommonPaginatedComponentList from "../Common/CommonPaginatedComponentList";
import TaskCard from "./TaskCard";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class TaskOwnerCompleted extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            pages: null,
            filters: {},
            subscribers: null,
        };
    }

    async componentDidMount() {
        await this.retrieveTasks();
        await this.retrieveSubscribers();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveTasks();
        }
    }

    async filterCallBack(filters){
        this.setState({
            filters: filters
        });
        await this.retrieveTasks();
    }

    async retrieveSubscribers(){
        const url = 'subscriber/';
        const api = new BaseApi();
        const params = {
            active: true
        };
        let response = await api.get(url, params);
        this.setState(
            {
                subscribers: response.data.results,
            }
        );
    }

    async retrieveTasks() {
        const url = 'task';
        const api = new BaseApi();
        let params = {
            work_status: 'COM',
            page_size: 10,
            ordering: '-end_dt',
            owner: this.context.userObject.id,
            executor__not: this.context.userObject.id,
        }
        params = {...params, ...this.state.filters};
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                tasks: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.tasks) {
            return (<CircularProgress/>)
        }
        if (!this.state.tasks) {
            return (<CircularProgress/>)
        }
        if (!this.state.subscribers) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedComponentList
                    title={'Завершенные задачи коллег'}
                    url={'task/ownercompleted'}
                    component={TaskCard}
                    componentKeyword={'task'}
                    filterFields={[
                        {
                            name: 'executor',
                            label: 'Исполнитель',
                            xs: 12,
                            sm: null,
                            md: null,
                            lg: null,
                            xl: null,
                            required: true,
                            default: null,
                            type: 'select',
                            options: this.state.subscribers.map(each => {
                                return(
                                    {value: each.user.id, label: `${each.user.first_name} ${each.user.last_name}`}
                                )
                            })
                        },
                    ]}
                    filterCallBack={this.filterCallBack.bind(this)}
                    data={this.state.tasks}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                />
            </Box>
        )
    }
}

export default withRouter(TaskOwnerCompleted)