import React from "react";

import dayjs from "dayjs";

import {CircularProgress, Stack} from "@mui/joy";

// components
import CommonInfoTab from "../Common/CommonInfoTab";
import ContractCreateModal from "./ContractCreateModal";
import BaseApi from "../../utils/BaseApi";
import {b64toBlob, downloadBlob} from "../../utils/misc";
import CommonCallBackButton from "../Common/CommonCallBackButton";
import CommonPaginatedComponentList from "../Common/CommonPaginatedComponentList";

// icons
import InfoIcon from "@mui/icons-material/Info";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";
import PhaseCard from "./PhaseCard";
import PhaseCreateModal from "./PhaseCreateModal";


class ContractDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contract: null,
            phases: null,
            extraData: null,
            create: !!!this.props.contract,
        };
    }

    async componentDidMount() {
        await this.retrieveContract();
        await this.retrieveExtraData();
    }

    async retrieveExtraData(){
        let extraData = {};
        const api = new BaseApi();
        let url = `contact/`;
        let response = await api.get(
            url,
            {
                account: this.props.opportunity.account.id,
                page_size: 10000,
            }
        );
        extraData.contacts = response.data.results.map((each) => (
            {'value': each.id, 'label': `${each.first_name} ${each.last_name} - ${each.role}`}
        ))
        url = `subscriber/`;
        response = await api.get(
            url,
            {
                active: true,
                can_sign_financial: true,
            }
        );
        extraData.signature = response.data.results.map((each) => (
            {'value': each.user.id, 'label': `${each.user.first_name} ${each.user.last_name}`}
        ))
        this.setState(
            {
                extraData: extraData,
            }
        )
    }

    async retrieveContract() {
        let contract;
        let url = `contract/${this.props.opportunity.id}/full/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        contract = response.data
        url = 'phase'
        response = await api.get(url, {
            opportunity: this.props.opportunity.id
        });
        let phases = response.data.results
        this.setState({
            contract: contract,
            phases: phases,
        })
    }

    async generate(generateParams){
        const api = new BaseApi();
        const url = `contract/${this.state.contract.id}/generate/`;
        let params = {}
        if (generateParams[0]){
            params.service = true
        }
        if (generateParams[1]){
            params.download = true
        }
        if (generateParams[2]){
            params.attach = true
        }
        let response = await api.get(url, params);
        let timestamp = dayjs().format('YYYYMMDDHHmmss');
        if (params.download){
            downloadBlob(
                b64toBlob(response.data),
                `contract_${this.state.contract.id}_${timestamp}.docx`,
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            )
        }
    }

    render() {
        if (this.state.create){
            return(
                <ContractCreateModal
                    opportunity={this.props.opportunity}
                    callBack={this.retrieveContract.bind(this)}
                />
            )
        }
        if (!this.state.contract || !this.state.phases || !this.state.extraData){
            return (<CircularProgress/>)
        }
        return(
            <Stack
                spacing={2}
            >
                <Stack
                    direction={'row'}
                    spacing={1}
                >
                    <CommonCallBackButton
                        icon={DownloadIcon}
                        label={'Услуги'}
                        color={'neutral'}
                        callBack={this.generate.bind(this, [true, true, false])}
                    />
                    <CommonCallBackButton
                        icon={AttachFileIcon}
                        label={'Услуги'}
                        color={'neutral'}
                        callBack={this.generate.bind(this, [true, false, true])}
                    />
                    <CommonCallBackButton
                        icon={DownloadIcon}
                        label={'Работы'}
                        color={'neutral'}
                        callBack={this.generate.bind(this, [false, true, false])}
                    />
                    <CommonCallBackButton
                        icon={AttachFileIcon}
                        label={'Работы'}
                        color={'neutral'}
                        callBack={this.generate.bind(this, [false, false, true])}
                    />
                </Stack>
                <CommonInfoTab
                    callBack={this.retrieveContract.bind(this)}
                    groups={
                        [
                            {
                                title: 'Общая информация',
                                icon: <InfoIcon/>,
                                fields: [
                                    {
                                        title: 'Номер',
                                        value: this.state.contract.contract_number,
                                        xs: 12,
                                        sm: 6,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['contract_number'],
                                            baseUrl: 'contract',
                                            obj: this.state.contract
                                        },
                                    },
                                    {
                                        title: 'Дата',
                                        value: dayjs(this.state.contract.contract_date).format('DD.MM.YYYY'),
                                        xs: 12,
                                        sm: 6,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['contract_date'],
                                            baseUrl: 'contract',
                                            obj: this.state.contract
                                        },
                                    },
                                    {
                                        title: 'Тема',
                                        value: this.state.contract.contract_theme,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: true,
                                            fields: ['contract_theme'],
                                            baseUrl: 'contract',
                                            obj: this.state.contract
                                        },
                                    },
                                    {
                                        title: 'Порядок сдачи работ',
                                        value: this.state.contract.contract_delivery_order,
                                        html: true,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: true,
                                            fields: ['contract_delivery_order'],
                                            baseUrl: 'contract',
                                            obj: this.state.contract
                                        },
                                    },
                                    {
                                        title: 'Количество печатных экз. отчета',
                                        value: this.state.contract.contract_print_number,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: true,
                                            fields: ['contract_print_number'],
                                            baseUrl: 'contract',
                                            obj: this.state.contract
                                        },
                                    },
                                    {
                                        title: 'Форма отчетности',
                                        value: this.state.contract.contract_report_form,
                                        fieldFromArray: 'title',
                                        edit: {
                                            permission: true,
                                            fields: [],
                                            m2mFields: ['contract_report_form', ],
                                            baseUrl: 'contract',
                                            obj: {
                                                id: this.props.contract.id,
                                                contract_report_form: this.props.contract.contract_report_form.map((item) => (item.id))
                                            }
                                        },
                                    },
                                    {
                                        title: 'Форма передачи электронных экземпляров',
                                        value: this.state.contract.contract_elec_report_form,
                                        fieldFromArray: 'title',
                                        edit: {
                                            permission: true,
                                            fields: [],
                                            m2mFields: ['contract_elec_report_form', ],
                                            baseUrl: 'contract',
                                            obj: {
                                                id: this.props.contract.id,
                                                contract_elec_report_form: this.props.contract.contract_elec_report_form.map((item) => (item.id))
                                            }
                                        },
                                    },
                                    {
                                        title: 'Подписант заказчика',
                                        value: this.state.contract.contract_customer?`${this.state.contract.contract_customer.first_name} ${this.state.contract.contract_customer.last_name} - ${this.state.contract.contract_customer.role}`:null,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: true,
                                            fields: ['contract_customer'],
                                            baseUrl: 'contract',
                                            obj: {
                                                id: this.state.contract.id,
                                                contract_customer: this.state.contract.contract_customer?.id
                                            },
                                            fieldUpdates: [
                                                {
                                                    name: 'contract_customer',
                                                    updates: [
                                                        {
                                                            key: 'options',
                                                            value: this.state.extraData.contacts
                                                        },
                                                    ]
                                                }
                                            ],
                                        },
                                    },
                                    {
                                        title: 'Контактное лицо заказчика',
                                        value: this.state.contract.contract_contact_person?`${this.state.contract.contract_contact_person.first_name} ${this.state.contract.contract_contact_person.last_name} - ${this.state.contract.contract_contact_person.role}`:null,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: true,
                                            fields: ['contract_contact_person'],
                                            baseUrl: 'contract',
                                            obj: {
                                                id: this.state.contract.id,
                                                contract_contact_person: this.state.contract.contract_contact_person?.id
                                            },
                                            fieldUpdates: [
                                                {
                                                    name: 'contract_contact_person',
                                                    updates: [
                                                        {
                                                            key: 'options',
                                                            value: this.state.extraData.contacts
                                                        },
                                                    ]
                                                }
                                            ],
                                        },
                                    },
                                    {
                                        title: 'Подписант исполнителя',
                                        value: this.state.contract.contract_signatory?`${this.state.contract.contract_signatory.first_name} ${this.state.contract.contract_signatory.last_name}`:null,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: true,
                                            fields: ['contract_signatory'],
                                            baseUrl: 'contract',
                                            obj: {
                                                id: this.state.contract.id,
                                                contract_signatory: this.state.contract.contract_signatory?.id
                                            },
                                            fieldUpdates: [
                                                {
                                                    name: 'contract_signatory',
                                                    updates: [
                                                        {
                                                            key: 'options',
                                                            value: this.state.extraData.signature
                                                        },
                                                    ]
                                                }
                                            ],
                                        },
                                    },
                                ]
                            }
                        ]
                    }
                />
                <CommonPaginatedComponentList
                    title={'Этапы'}
                    data={this.state.phases}
                    component={PhaseCard}
                    componentKeyword={'phase'}
                />
                <PhaseCreateModal
                    opportunity={this.props.opportunity}
                    callBack={this.retrieveContract.bind(this)}
                />
            </Stack>
        )
    }
}

export default ContractDetail
