import React from 'react';

// components
import CommonSidebar from "../Common/CommonSidebar";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";

//icons
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';


class DocumentSidebar extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            letterGroup: null,
            incomingLetterGroup: null,
        };
    }

    async componentDidMount() {
        await this.retrieveLetterPermission();
        await this.retrieveIncomingLetterPermission();
    }

    async retrieveLetterPermission(){
        let p = 0;
        p += await retrievePermission(
            'LetterViewSet',
            'partial_update',
            this.context.subscriberObject
        );

        if (p){
            this.setState(
                {
                    letterGroup: {
                        title: 'Исходящие письма',
                        items: [
                            {
                                title: 'Список',
                                link: '/letter/',
                                icon: ForwardToInboxIcon,
                            },
                        ],
                    }
                }
            );
        }
    }

    async retrieveIncomingLetterPermission(){
        let p = 0;
        p += await retrievePermission(
            'IncomingLetterViewSet',
            'partial_update',
            this.context.subscriberObject
        );

        if (p){
            this.setState(
                {
                    incomingLetterGroup: {
                        title: 'Входящие письма',
                        items: [
                            {
                                title: 'Список',
                                link: '/incoming_letter/',
                                icon: MarkunreadMailboxIcon,
                            },
                        ],
                    }
                }
            );
        }
    }

    render() {
        let groups = [];
        if (this.state.letterGroup) {
            groups.push(this.state.letterGroup)
        }
        if (this.state.incomingLetterGroup) {
            groups.push(this.state.incomingLetterGroup)
        }
        return(
            <CommonSidebar
                groups={groups}
            />
        )
    }
}

export default DocumentSidebar
