import CommonActionModal from "../Common/CommonActionModal";
import NewQuoteDetailInner from "./NewQuoteDetailInner";

// icons
import WebAssetIcon from '@mui/icons-material/WebAsset';


class NewQuoteDetail extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: WebAssetIcon,
            buttonLabel: null,
            buttonColor: 'neutral',
            ready: true,
        })
    }

    async setModalOpenFalse() {
        await this.props.callBack()
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    async beforeModalOpen(){
        return {
            modalLabel: 'ТКП',
            modalIcon: null,
            mainComponent: NewQuoteDetailInner,
            mainComponentProps: {
                quote: this.props.quote,
            },
        }
    }
}

export default NewQuoteDetail