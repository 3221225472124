import React from "react";

import {useColorScheme} from '@mui/joy/styles';

import {DarkMode, LightMode} from "@mui/icons-material";
import IconButton from "@mui/joy/IconButton";


const ColorSchemeToggle = () => {
    const {mode, setMode: setJoyMode} = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return null;
    }
    return (
        <IconButton
            onClick={() => {
                setJoyMode(mode === 'dark' ? 'light' : 'dark');
            }}
        >
            {mode === 'dark' ? <DarkMode/> : <LightMode/>}
        </IconButton>
    );
};

export default ColorSchemeToggle
