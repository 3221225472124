import React from 'react';

// components
import CommonPaginatedTable from "../Common/CommonPaginatedTable";
import RouteDetail from "./RouteDetail";


class RouteTable extends React.Component {
    render() {
        return(
            <CommonPaginatedTable
                title={null}
                url={'/route'}
                data={this.props.routes}
                fields={[
                    {
                        title: 'Сущность',
                        name: 'route.entity_human',
                        visible: true,
                    },
                    {
                        title: 'ID Сущности',
                        name: 'route.entity_id',
                        visible: true,
                    },
                    {
                        title: '',
                        visible: true,
                        use_component: true,
                        component: RouteDetail,
                        callBack: this.props.callBack
                    },
                    {
                        title: 'Стадия',
                        name: 'stage.title',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => (value),
                        color_value: 'stage.color',
                    },
                    {
                        title: 'Прошло',
                        name: 'delay.0',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => (value>comparison?'danger':'success'),
                        color_value: 'delay.0',
                        color_comparison: 'stage.end_delay'
                    },
                    {
                        title: 'Дедлайн',
                        name: 'stage.end_delay',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('primary'),
                    },
                ]
                }
            />
        )
    }
}

export default RouteTable;
