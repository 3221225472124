import React from 'react';
import {CssVarsProvider} from "@mui/joy/styles";

import {AuthProvider} from "../../context/AuthContext";
import LoginInner from "./LoginInner";


export default function Login() {
    return (
        <CssVarsProvider>
            <AuthProvider>
                <LoginInner/>
            </AuthProvider>
        </CssVarsProvider>
    )
};
