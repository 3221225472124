import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";

// components
import CommonEditModal from "../Common/CommonEditModal";
import CommonCallBackButton from "../Common/CommonCallBackButton";
import IconButton from "@mui/joy/IconButton";

// icons
import NoteIcon from '@mui/icons-material/Note';
import DeleteIcon from "@mui/icons-material/Delete";
import BaseApi from "../../utils/BaseApi";

class NoteCard extends React.Component {

    async destroy() {
        const url = `note/${this.props.note.id}/`;
        const api = new BaseApi();
        await api.delete(url, {});
        await this.props.callBack();
    }

    render() {
        if (!this.props.note) {
            return (<CircularProgress/>)
        }
        return (
            <Card
                orientation="horizontal"
                variant="outlined"
                sx={
                    {
                        mr: 1,
                        ml: 1,
                        mb: 1,
                        p: 1
                    }
                }
            >
                <CardContent
                    sx={
                        {
                            m: 0,
                        }
                    }
                >
                    <Stack
                        direction={'row'}
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            color={this.props.note.color}
                            sx={{
                                    mr: 1,
                                }}
                        >
                            <NoteIcon/>
                        </IconButton>
                        <div dangerouslySetInnerHTML={{__html: this.props.note.text?this.props.note.text:'-'}} />
                        <CommonEditModal
                            fields={
                                [
                                    'text',
                                    'color',
                                ]
                            }
                            baseUrl={'note'}
                            obj={this.props.note}
                            callBack={this.props.callBack}
                        />
                        <CommonCallBackButton
                            quote={this.props.quote}
                            callBack={this.destroy.bind(this)}
                            propsObject={'note'}
                            propsObjectKey={'id'}
                            icon={DeleteIcon}
                        />
                    </Stack>
                </CardContent>
            </Card>
        )
    }
}

export default NoteCard;
