import React from 'react';

import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Common/CommonForm";
import {CircularProgress} from "@mui/joy";
import {splitToList} from "../../utils/misc";


class QualificatorCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields: null
        };
    }

    async componentDidMount() {
        await this.retrieveFormFields();
    }

    async retrieveFormFields() {
        const url = `qualificator/create_form/`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        this.setState(
            {
                formFields: response.data
            }
        )
    }

    async processForm(form){
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.industry_focus = splitToList(formDataObj.industry_focus, ',');
        formDataObj.physic_task_type = splitToList(formDataObj.physic_task_type, ',');
        formDataObj.calculations_purpose = splitToList(formDataObj.calculations_purpose, ',');
        formDataObj.report_form = splitToList(formDataObj.report_form, ',');
        formDataObj.extra_terms = splitToList(formDataObj.extra_terms, ',');
        let url = `qualificator/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj,
        );
        if (response.status === 201){
            let opp_response
            let lead_response
            if (this.props.opportunity) {
                let url = `opportunity/${this.props.opportunity.id}/`
                opp_response = await api.patch(
                    url,
                    {
                        qualificator: response.data.id,
                    }
                );
            }
            if (this.props.lead) {
                let url = `lead/${this.props.lead.id}/`
                lead_response = await api.patch(
                    url,
                    {
                        qualificator: response.data.id,
                    }
                );
            }
            if (opp_response?.status === 200){
                this.props.callBack(opp_response.data.qualificator);
            }
            if (lead_response?.status === 200){
                this.props.callBack(lead_response.data.qualificator);
            }
        }
    }

    render() {
        if (!this.state.formFields) {
            return (<CircularProgress/>)
        }
        return(
            <CommonForm
                processForm={this.processForm.bind(this)}
                fields={this.state.formFields}
            />
        )
    }
}

export default QualificatorCreate;
