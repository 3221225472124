import React from "react";

import dayjs from "dayjs";

import {CircularProgress, Stack} from "@mui/joy";

// components
import CommonInfoTab from "../Common/CommonInfoTab";
import NewQuoteEstimationRevisionCreateModal from "./NewQuoteEstimationRevisionCreateModal";
import CommonCallBackButton from "../Common/CommonCallBackButton";
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";

// icons
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from '@mui/icons-material/Settings';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


class NewQuoteDetailInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quote: null,
            estimationRevisions: null,
            extraData: null,
        };
    }

    async componentDidMount(){
        await this.retrieveQuote(false)
        await this.retrieveExtraData();
    }

    async retrieveExtraData(){
        let extraData = {};
        const api = new BaseApi();
        let url = `contact/`;
        let response = await api.get(
            url,
            {
                account: this.props.quote.opportunity.account.id,
                page_size: 10000,
            }
        );
        extraData.contacts = response.data.results.map((each) => (
            {'value': each.id, 'label': `${each.first_name} ${each.last_name} - ${each.role}`}
        ))
        url = `account/customers/`;
        response = await api.get(
            url,
            {
                page_size: 10000,
            }
        );
        extraData.accounts = response.data.results.map((each) => (
            {'value': each.id, 'label': each.nameshort}
        ))
        url = `subscriber/`;
        response = await api.get(
            url,
            {
                active: true,
                can_sign_financial: true,
            }
        );
        extraData.signature = response.data.results.map((each) => (
            {'value': each.id, 'label': `${each.user.first_name} ${each.user.last_name}`}
        ))
        this.setState(
            {
                extraData: extraData,
            }
        )
    }

    async retrieveQuote(full=true) {
        const api = new BaseApi();
        let url;
        let quote;
        let estimationRevisions;
        let response
        if (full){
            url = `new_quote/${this.props.quote.id}`;
            response = await api.get(
                url,
                {}
            );
            quote = response.data;
        }
        else {
            quote=this.props.quote;
        }
        url = `new_quote_estimation_revisions/`;
        response = await api.get(
            url,
            {
                quote: this.props.quote.id
            }
        );
        estimationRevisions = response.data.results
        this.setState(
            {
                quote: quote,
                estimationRevisions: estimationRevisions,
            }
        )
    }

    async deleteRevision(id) {
        const url = `new_quote_estimation_revisions/${id}/`;
        const api = new BaseApi();
        await api.delete(url);
        await this.retrieveQuote(false)
    }

    async moveUpRevision(id){
        const url = `new_quote_estimation_revisions/${id}/move_up/`;
        const api = new BaseApi();
        await api.get(url);
        await this.retrieveQuote(false)
    }

    async moveDownRevision(id){
        const url = `new_quote_estimation_revisions/${id}/move_down/`;
        const api = new BaseApi();
        await api.get(url);
        await this.retrieveQuote(false)
    }
    
    render() {
        if (!this.state.quote || !this.state.estimationRevisions || !this.state.extraData) {
            return (<CircularProgress/>)
        }
        return(
            <Stack
                spacing={2}
            >
                <CommonInfoTab
                    callBack={this.retrieveQuote.bind(this)}
                    groups={
                        [
                            {
                                title: 'Общая информация',
                                icon: <InfoIcon />,
                                fields: [
                                    {
                                        title: 'Тема',
                                        value: this.state.quote.title,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: true,
                                            fields: ['title',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                    {
                                        title: 'В ответ на номер',
                                        value: this.state.quote.reply_to_number,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['reply_to_number',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                    {
                                        title: 'В ответ на дата',
                                        value: this.state.quote.reply_to_date?dayjs(this.state.quote.reply_to_date).format('DD MMM YYYY'):this.state.quote.reply_to_date,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['reply_to_date',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Параметры',
                                icon: <SettingsIcon />,
                                fields: [
                                    {
                                        title: 'Суммарная трудоемкость (дни)',
                                        value: this.state.quote.work_days,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['work_days',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                    {
                                        title: 'Стоимость дня',
                                        value: this.state.quote.day_rate,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['day_rate',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                    {
                                        title: 'Процент накладных',
                                        value: this.state.quote.overhead_rate,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['overhead_rate',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                    {
                                        title: 'Материальные затраты',
                                        value: this.state.quote.misc_cost,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['misc_cost',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                    {
                                        title: 'Процент прибыли',
                                        value: this.state.quote.profit_rate,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['profit_rate',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                    {
                                        title: 'Процент аванса',
                                        value: this.state.quote.prepay_rate,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['prepay_rate',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                    {
                                        title: 'Срок действия цены',
                                        value: this.state.quote.offer_end_dt?dayjs(this.state.quote.offer_end_dt).format('DD MMM YYYY'):this.state.quote.offer_end_dt,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: true,
                                            fields: ['offer_end_dt',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Текст',
                                icon: <TextSnippetIcon/>,
                                fields: [
                                    {
                                        title: 'Блок о компании',
                                        value: this.state.quote.about_company,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['about_company',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote,
                                        }
                                    },
                                    {
                                        title: 'Блок состав работ',
                                        value: this.state.quote.specification,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['specification',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                    {
                                        title: 'Блок о скидке',
                                        value: this.state.quote.discount,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['discount',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote
                                        }
                                    },
                                    {
                                        title: 'Тексты в блок Сообщаем',
                                        value: this.state.quote.letter_wordings,
                                        fieldFromArray: 'title',
                                        edit: {
                                            permission: true,
                                            fields: [],
                                            m2mFields: ['letter_wordings', ],
                                            baseUrl: 'new_quote',
                                            obj: {
                                                id: this.state.quote.id,
                                                letter_wordings: this.state.quote.letter_wordings.map((item) => (item.id))
                                            }
                                        }
                                    },
                                    {
                                        title: 'Список преимуществ от совместной работы',
                                        value: this.state.quote.businesses,
                                        fieldFromArray: 'title',
                                        edit: {
                                            permission: true,
                                            fields: [],
                                            m2mFields: ['businesses', ],
                                            baseUrl: 'new_quote',
                                            obj: {
                                                id: this.state.quote.id,
                                                businesses: this.state.quote.businesses.map((item) => (item.id))
                                            }
                                        }
                                    },
                                    {
                                        title: 'Компании',
                                        value: this.state.quote.accounts,
                                        fieldFromArray: 'nameshort',
                                        edit: {
                                            permission: true,
                                            fields: [],
                                            m2mFields: ['accounts', ],
                                            baseUrl: 'new_quote',
                                            obj: {
                                                id: this.state.quote.id,
                                                accounts: this.state.quote.accounts.map((item) => (item.id))
                                            },
                                            fieldUpdates: [
                                                {
                                                    name: 'accounts',
                                                    updates: [
                                                        {
                                                            key: 'options',
                                                            value: this.state.extraData.accounts
                                                        },
                                                    ]
                                                }
                                            ],
                                        }
                                    },
                                    {
                                        title: 'Свободный текст',
                                        value: this.state.quote.free_text,
                                        html: true,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: true,
                                            fields: ['free_text',],
                                            baseUrl: 'new_quote',
                                            obj: this.state.quote,
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Персоналии',
                                icon: <ContactPageIcon />,
                                fields: [
                                    {
                                        title: 'Получатель',
                                        value: `${this.state.quote.contact.first_name} ${this.state.quote.contact.last_name} - ${this.state.quote.contact.role}`,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['contact',],
                                            baseUrl: 'new_quote',
                                            obj: {
                                                id: this.state.quote.id,
                                                contact: this.state.quote.contact.id
                                            },
                                            fieldUpdates: [
                                                {
                                                    name: 'contact',
                                                    updates: [
                                                        {
                                                            key: 'options',
                                                            value: this.state.extraData.contacts
                                                        },
                                                    ]
                                                }
                                            ],
                                        }
                                    },
                                    {
                                        title: 'Подписант',
                                        value: `${this.state.quote.signature.user.first_name} ${this.state.quote.signature.user.last_name}`,
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 6,
                                        edit: {
                                            permission: true,
                                            fields: ['signature',],
                                            baseUrl: 'new_quote',
                                            obj: {
                                                id: this.state.quote.id,
                                                signature: this.state.quote.signature.id
                                            },
                                            fieldUpdates: [
                                                {
                                                    name: 'signature',
                                                    updates: [
                                                        {
                                                            key: 'options',
                                                            value: this.state.extraData.signature
                                                        },
                                                    ]
                                                }
                                            ],
                                        }
                                    },
                                ]
                            }
                        ]
                    }
                />
                <CommonPaginatedTable
                    title={'Ревизии трудоемкости'}
                    data={this.state.estimationRevisions}
                    fields={[
                        {
                            title: 'Порядок',
                            name: 'order',
                            visible: true,
                        },
                         {
                            title: 'Номер',
                            name: 'revision.number',
                            visible: true,
                        },
                        {
                            title: 'Описание',
                            name: 'revision.desc',
                            visible: true,
                            width: '50%'
                        },
                        {
                            title: 'Доб. текст',
                            name: 'add_text',
                            visible: true,
                            edit: {
                                permission: true,
                                fields: ['add_text',],
                                baseUrl: 'new_quote_estimation_revisions',
                                callBack: this.retrieveQuote.bind(this),
                            }
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                icon: DeleteIcon,
                                color: 'danger',
                            },
                            callBack: this.deleteRevision.bind(this),
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                icon: KeyboardArrowUpIcon,
                            },
                            callBack: this.moveUpRevision.bind(this),
                        },
                        {
                            title: '',
                            visible: true,
                            use_component: true,
                            component: CommonCallBackButton,
                            componentProps: {
                                propsObject: 'componentObj',
                                propsObjectKey: 'id',
                                icon: KeyboardArrowDownIcon,
                            },
                            callBack: this.moveDownRevision.bind(this),
                        },
                    ]}
                />
                <NewQuoteEstimationRevisionCreateModal
                    quote={this.props.quote}
                    callBack={this.retrieveQuote.bind(this)}
                />
            </Stack>
        )
    }
}

export default NewQuoteDetailInner
