import React from "react";

import {stateToHTML} from 'draft-js-export-html';
import Modifier from "draft-js/lib/DraftModifier";
import {CircularProgress} from "@mui/joy";

import Box from "@mui/joy/Box";

// components
import BaseApi from "../../utils/BaseApi";
import MUIRichTextEditor from '../MuiRte/MUIRichTextEditor.tsx'
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";
import EditorState from "draft-js/lib/EditorState";
import CommentReplyCard from "./CommentReplyCard";

// icons
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';


class CommentForm extends React.Component {
    constructor(props) {
        super(props);
        this.rte = React.createRef()
        this.state = {
            mention_process: false,
            mention_filter: '',
            users: null,
            value: '',
            editorState: EditorState.createEmpty(),
            replyTo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        this.forceUpdate();
    }

    reply(comment){
        let value = '@' + comment.owner.username
        let content = Modifier.insertText(
            this.state.editorState.getCurrentContent(),
            this.state.editorState.getSelection(),
            value,
        )
        this.setState(
            {
                content: content,
                replyTo: comment,
            }
        )
    }

    async retrieveUsers() {
        const url = 'user/';
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        )
        this.setState(
            {
                users: response.data.results.map(each => {
                    return (
                        {
                            keys: [each.username],
                            value: '@' + each.username,
                            content: <ProfileHorizontalByProps
                                user={each}
                            />,
                        }
                    )
                })
            }
        );
    }

    async componentDidMount() {
        await this.retrieveUsers();
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({value: this.props.initialValue ?? ''})
        }
    }

    handleEditorChange(state) {
        this.setState(
            {
                value: stateToHTML(state.getCurrentContent()),
                editorState: state,
            }
        );
    }

    clearReplyTo(){
        this.setState(
            {
                replyTo: null,
            }
        )
    }

    render() {
        if (!this.state.users) {
            return (<CircularProgress />)
        }
        return(
            <Box
                sx={{
                    height: this.state.replyTo?'400px':'200px',
                    overflowY: 'scroll',
                }}
            >
                {
                    this.state.replyTo?
                        <CommentReplyCard
                            comment={this.state.replyTo}
                            deleteCallBack={this.clearReplyTo.bind(this)}
                        />:
                        null
                }
                <MUIRichTextEditor
                    ref={this.rte}
                    id={'comment-form'}
                    controls={[
                        "title",
                        "bold",
                        "italic",
                        "numberList",
                        "bulletList",
                        "increase-indent",
                        "clear",
                    ]}
                    // customControls={[
                    //     {
                    //         name: "increase-indent",
                    //         icon: <FormatIndentIncreaseIcon />,
                    //         type: "block",
                    //         blockWrapper: <ol />
                    //     }
                    // ]}
                    defaultValue={this.state.content}
                    label="Начните набор..."
                    onChange={this.handleEditorChange.bind(this)}
                    autocomplete={{
                        strategies: [
                            {
                                items: this.state.users,
                                triggerChar: "@",
                                insertSpaceAfter: true
                            },
                        ]
                    }}
                />
            </Box>
        )
    }
}

export default CommentForm;
