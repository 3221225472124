import React from 'react';

import Avatar from "@mui/joy/Avatar";
import {CardOverflow, Stack} from "@mui/joy";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";

// components
import EstimationRevisionModal from "./EstimationRevisionModal";
import EstimationRevisionChart from "./EstimationRevisionChart";
import EstimationRevisionSetCurrent from "./EstimationRevisionSetCurrent";
import BaseApi from "../../utils/BaseApi";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import EstimationRevisionFastModal from "./EstimationRevisionFastModal";
import CommonCallBackButton from "../Common/CommonCallBackButton";

// icons
import BoltIcon from '@mui/icons-material/Bolt'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";



class EstimationRevisionCard extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            revision: this.props.revision,
            copyPermission: 0,
            deletePermission: 0,
        };
    }

    async componentDidMount() {
        await this.retrievePermissions();
    }

    async retrievePermissions() {
        let copyPermission;
        let deletePermission;
        let p = await retrievePermission(
            'EstimationRevisionViewSet',
            'copy',
            this.context.subscriberObject
        );
        copyPermission = p;
        p = await retrievePermission(
            'EstimationRevisionViewSet',
            'destroy',
            this.context.subscriberObject
        );
        deletePermission = p;
        this.setState(
            {
                copyPermission: copyPermission,
                deletePermission: deletePermission,
            }
        );
    }

    async retrieveRevision() {
        const url = `estimation_revision/${this.props.revision.id}`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        this.setState({
            revision: response.data
        })
    }

    async copyRevision() {
        const url = `estimation_revision/${this.props.revision.id}/copy`;
        const api = new BaseApi();
        await api.get(
            url,
            {}
        );
        await this.props.callBack();
    }

    async deleteRevision() {
        const url = `estimation_revision/${this.props.revision.id}/`;
        const api = new BaseApi();
        await api.delete(url);
        await this.props.callBack();
    }

    render() {
        return(
            <Card>
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                        >
                            <EstimationRevisionSetCurrent
                                revision={this.state.revision}
                                callBack={this.props.callBack}
                            />
                            <Typography level="title-md">
                                Ревизия №{this.state.revision.number}: {this.state.revision.desc}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                        >
                            <EstimationRevisionFastModal
                                revision={this.state.revision}
                            />
                            <EstimationRevisionModal
                                revision={this.state.revision}
                                callBack={this.retrieveRevision.bind(this)}
                            />
                        </Stack>
                    </Stack>
                    <EstimationRevisionChart
                        revision={this.state.revision}
                    />
                </CardContent>
                <CardOverflow>
                    <Divider inset="context"/>
                    <CardContent orientation="horizontal">
                        <Stack
                            direction="row"
                            spacing={1}
                        >
                            {
                                this.state.copyPermission > 0?
                                    <CommonCallBackButton
                                        icon={ContentCopyIcon}
                                        label={'Копировать'}
                                        color={'warning'}
                                        callBack={this.copyRevision.bind(this)}
                                    />:
                                    null
                            }
                            {
                                this.state.deletePermission > 0?
                                    <CommonCallBackButton
                                        icon={DeleteIcon}
                                        label={'Удалить'}
                                        color={'danger'}
                                        callBack={this.deleteRevision.bind(this)}
                                    />:
                                    null
                            }
                        </Stack>
                    </CardContent>
                </CardOverflow>
            </Card>

        )
    }
}

export default EstimationRevisionCard