import React from "react";

import {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    ConnectionLineType,
    Controls,
    MiniMap,
    ReactFlow,
} from "reactflow";
import {CircularProgress,} from "@mui/joy";
import Box from "@mui/joy/Box";


import 'reactflow/dist/style.css';

// components
import {simulateGraph} from "../../utils/d3Force";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import BaseApi from "../../utils/BaseApi";
import RouteNode from "./RouteNode";
import FloatingConnectionLine from "./FloatingConnectionLine";
import SelfConnectingEdge from "./SelfConnectingEdge";
import RouteStageCreate from "./RouteStageCreate";


const nodeTypes = {
    mainNode: RouteNode,
};
const edgeTypes = {
    selfconnecting: SelfConnectingEdge,
};


class RouteEntityAdmin extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            edges: null,
            nodes: null,
            isLoading: true,
            highlightedNode: null
        };
    }

    async componentDidMount() {
        await this.retrieveStages();
        await setTimeout(() => {
          this.setState({isLoading: false});
        }, 2000);
    }

    async retrieveStages(){
        const url = `route_stage/`;
        const api = new BaseApi();
        const params = {
            is_active: true,
            entity: this.props.entity,
        };
        let response = await api.get(url, params);
        let rawNodes = response.data.results.map((stage) => {
            return(
                {
                    id: stage.id,
                    position: {
                        x: 0,
                        y: 0,
                    },
                    data: {
                        label: stage.title,
                        obj: stage
                    },
                }
            )
            }
        );
        let rawEdges = [];
        for (let stage of response.data.results) {
            for (let edge of stage.next_stages) {
                rawEdges.push(
                    {
                        id: `${stage.id}-${edge.id}`,
                        source: stage.id,
                        target: edge.id,
                    }
                )
            }
        }
        const {formattedEdges, formattedNodes} = simulateGraph(
            rawEdges,
            rawNodes,
            this.highlightCallBack.bind(this)
        );
        this.setState(
            {
                nodes: formattedNodes,
                edges: formattedEdges,
            }
        );
    }

    highlightCallBack(id){
        if (id === this.state.highlightedNode){
            this.setState(
            {
                highlightedNode: null,
                edges: this.state.edges.map((edge) => {
                            edge.style = {stroke: 'white'}
                            return edge
                        }
                    )
                }
            );
        }
        else {
            let edges = this.state.edges.map((edge) => {
                if (edge.source === id.toString()){
                    edge.style = {stroke: 'yellow'}
                }
                else if (edge.target === id.toString()){
                    edge.style = {stroke: 'orange'}
                }
                else {
                    edge.style = {stroke: 'white'}
                }
                return edge
            });
            this.setState(
                {
                    highlightedNode: id,
                    edges: edges,
                }
            );
        }
    }

    onNodesChange(changes) {
        this.setState(
            {
                nodes: applyNodeChanges(changes, this.state.nodes)
            }
        );
    }

    onEdgesChange(changes) {
        this.setState(
            {
                edges: applyEdgeChanges(changes, this.state.edges)
            }
        );
    }

    async onConnect(params) {
        await this.setState(
            {
                edges: addEdge(params, this.state.edges)
            }
        );
        const url = `route_stage/${params.source}/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let next_stages = response.data.next_stages.map(each => {
            return each.id
        })
        next_stages.push(parseInt(params.target))
        await api.patch(
            url,
            {
                next_stages: next_stages
            }
        );
        // await this.retrieveStages();
    }

    render() {
        if (!this.state.nodes || !this.state.edges || this.state.isLoading){
            return (<CircularProgress/>)
        }
        return(
            <>
                <Box
                    sx={{
                        mt: 1,
                        mb: 1,
                    }}
                >
                    <RouteStageCreate
                        entity={this.props.entity}
                        callBack={this.retrieveStages.bind(this)}
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: 800,
                        border: 2,
                        borderColor: '#FFF',
                    }}
                >
                    <ReactFlow
                        nodes={this.state.nodes}
                        edges={this.state.edges}
                        onNodesChange={this.onNodesChange.bind(this)}
                        onEdgesChange={this.onEdgesChange.bind(this)}
                        connectionLineComponent={FloatingConnectionLine}
                        connectionLineType={ConnectionLineType.Bezier}
                        connectionLineContainerStyle={{ strokeWidth: 4 }}
                        fitView
                        defaultViewport={{
                            x: 0,
                            y: 0,
                            zoom: 1
                        }}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        onConnect={this.onConnect.bind(this)}
                    >
                        <Background />
                        <MiniMap
                            pannable={true}
                        />
                        <Controls/>
                    </ReactFlow>
                </Box>
            </>
        )
    }
}

export default RouteEntityAdmin;
