import React from "react";
import {Stack} from "@mui/joy";

// components
import CommonInfoTab from "../Common/CommonInfoTab";
import RouteTab from "../Route/RouteTab";
import LeadConvertModal from "./LeadConvertModal";

// icons
import InfoIcon from '@mui/icons-material/Info';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import AdsClickIcon from '@mui/icons-material/AdsClick';


class LeadTab extends React.Component {
    render() {
        return(
            <Stack
                spacing={2}
            >
                <RouteTab
                    entity={'Lead'}
                    entity_id={this.props.lead.id}
                    // entitySubscriber={this.state.lead.owner.id}
                />
                {
                    this.props.lead.status === 'OPEN'?
                        <LeadConvertModal
                            lead={this.props.lead}
                        />:
                        null
                }
                <CommonInfoTab
                    callBack={this.props.callBack}
                    groups={[
                        {
                            title: 'Основная информация',
                            icon: <InfoIcon />,
                            fields: [
                                {
                                    title: 'Компания из запроса',
                                    value: this.props.lead.account_title,
                                    edit: {
                                        permission: true,
                                        fields: ['account_title', ],
                                        baseUrl: 'lead',
                                        obj: this.props.lead
                                    },
                                },
                                {
                                    title: 'ФИО',
                                    value: `${this.props.lead.last_name} ${this.props.lead.first_name} ${this.props.lead.patronymic}`,
                                    edit: {
                                        permission: true,
                                        fields: ['last_name', 'first_name', 'patronymic'],
                                        baseUrl: 'lead',
                                        obj: this.props.lead
                                    },
                                },
                                {
                                    title: 'Описание',
                                    value: this.props.lead.desc,
                                    html: true,
                                    md: 12,
                                    lg: 12,
                                    edit: {
                                        permission: true,
                                        fields: ['desc', ],
                                        baseUrl: 'lead',
                                        obj: this.props.lead
                                    },
                                },
                                {
                                    title: 'Телефон',
                                    value: this.props.lead.phone,
                                    edit: {
                                        permission: true,
                                        fields: ['phone', ],
                                        baseUrl: 'lead',
                                        obj: this.props.lead
                                    },
                                },
                                {
                                    title: 'Электронная почта',
                                    value: this.props.lead.email,
                                    edit: {
                                        permission: true,
                                        fields: ['email', ],
                                        baseUrl: 'lead',
                                        obj: this.props.lead
                                    },
                                },
                            ]
                        },
                        {
                            title: 'Обработка запроса',
                            icon: <AutoFixHighIcon />,
                            fields: [
                                {
                                    title: 'ИНН',
                                    value: this.props.lead.inn,
                                    md: 12,
                                    lg: 12,
                                    edit: {
                                        permission: true,
                                        fields: ['inn', ],
                                        baseUrl: 'lead',
                                        obj: this.props.lead
                                    },
                                },

                            ]
                        },
                        {
                            title: 'Конвертация и привязки',
                            icon: <PrecisionManufacturingIcon />,
                            fields: [
                                {
                                    title: 'Компания в CRM',
                                    value: this.props.lead.crm_account?.nameshort,
                                    linkPattern: '/account/detail/:linkParam',
                                    linkParam: this.props.lead.crm_account?.id,
                                },
                                {
                                    title: 'Сделка',
                                    value: this.props.lead.opportunity?.short_name,
                                    linkPattern: '/opportunity/detail/:linkParam',
                                    linkParam: this.props.lead.opportunity?.id,
                                },
                            ]
                        },
                        {
                            title: 'Источник и метки',
                            icon: <AdsClickIcon />,
                            fields: [
                                {
                                    title: 'Источник',
                                    value: this.props.lead.source?.title,
                                    md: 12,
                                    lg: 12,
                                    edit: {
                                        permission: true,
                                        fields: ['source', ],
                                        baseUrl: 'lead',
                                        obj: this.props.lead
                                    },
                                },
                                {
                                    title: 'Номер кампании Direct',
                                    value: this.props.lead.ya_campaign_number,
                                    edit: {
                                        permission: true,
                                        fields: ['ya_campaign_number', ],
                                        baseUrl: 'lead',
                                        obj: this.props.lead
                                    },
                                },
                                {
                                    title: 'Номер группы Direct',
                                    value: this.props.lead.ya_group_number,
                                    edit: {
                                        permission: true,
                                        fields: ['ya_group_number', ],
                                        baseUrl: 'lead',
                                        obj: this.props.lead
                                    },
                                },
                                {
                                    title: 'Ключевая фраза Direct',
                                    value: this.props.lead.ya_keyword,
                                    edit: {
                                        permission: true,
                                        fields: ['ya_keyword', ],
                                        baseUrl: 'lead',
                                        obj: this.props.lead
                                    },
                                },
                            ]
                        },
                    ]}
                />
            </Stack>

        )
    }
}

export default LeadTab