import React from "react";

import {Stack} from "@mui/joy";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";


class CommonJSONFieldForm extends React.Component {
    async onSubmit(event){
        event.preventDefault();
        let formDataObj = Object.fromEntries(
            (new FormData(event.currentTarget)).entries()
        );
        for (let k of Object.keys(formDataObj)){
            switch (typeof this.props.value[k]) {
                case "number":
                    formDataObj[k] = parseInt(formDataObj[k])
                    break
                case "boolean":
                    formDataObj[k] = !!formDataObj[k]
                    break
                default:
                    break
            }
        }
        await this.props.processForm(formDataObj);
    }

    render() {
        return(
            <form
                onSubmit={this.onSubmit.bind(this)}
            >
                <Stack
                    spacing={1}
                >
                    {
                        Object.entries(this.props.value).map(
                            (kv, index) => (
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                >

                                    <Typography>
                                        {kv[0]}
                                    </Typography>
                                    <Input
                                        type={
                                            (() => {
                                                switch (typeof kv[1]) {
                                                    case "string":
                                                        return 'text'
                                                    case "number":
                                                        return 'number'
                                                    case "boolean":
                                                        return 'checkbox'
                                                    default:
                                                        return 'text'
                                                }
                                            })()
                                        }
                                        id={kv[0]}
                                        name={kv[0]}
                                        defaultValue={kv[1]}
                                    />
                                </Stack>
                            )
                        )
                    }
                </Stack>
                <Button
                    sx={{
                        mt: 2
                    }}
                    type={'submit'}
                    color={'primary'}
                >
                    Сохранить
                </Button>
            </form>
        )
    }
}

export default CommonJSONFieldForm
