// components
import CommonActionModal from "../Common/CommonActionModal";
import CommentAttachmentAddInner from "./CommentAttachmentAddInner";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableViewIcon from "@mui/icons-material/TableView";


class CommentAttachmentAdd extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Прикрепить вложение',
            buttonColor: 'neutral',
            buttonVariant: 'soft',
            ready: true,
        })
    }

    async beforeModalOpen(){
        return {
            modalLabel: 'Добавление вложения',
            modalIcon: TableViewIcon,
            mainComponent: CommentAttachmentAddInner,
            mainComponentProps: {
                appName: this.props.appName,
                appId: this.props.appId,
                callBack: this.process.bind(this)
            },
        }
    }

    async process(id){
        await this.props.callBack(id);
        this.setModalOpenFalse()
    }
}

export default CommentAttachmentAdd
