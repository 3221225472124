import React from "react";
import {Step, StepIndicator, Stepper} from "@mui/joy";

// icons
import {Check} from "@mui/icons-material";
import Typography from "@mui/joy/Typography";


class CommonStepper extends React.Component {
    constructor(props) {
        super(props);
        this.lockedSteps = this.props.lockedSteps?this.props.lockedSteps:[]
    }

    render() {
        return(
            <Stepper
                sx={{
                    width: '100%',
                    mb: 2,
                }}
            >
                {this.props.steps.map((step, index) => (
                    <Step
                        key={step}
                        onClick={
                            async () => {
                                if (this.props.activeStep >= index){
                                    if (!this.lockedSteps.includes(index)){
                                        await this.props.callBack(index)
                                    }
                                }
                            }
                        }
                        indicator={
                            <StepIndicator
                                variant={'solid'}
                                color={this.props.activeStep < index ? 'neutral' : 'success'}
                            >
                                {this.props.activeStep <= index ? index + 1 : <Check/>}
                            </StepIndicator>
                        }
                        sx={{
                            '&::after': {
                                ...(this.props.activeStep > index &&
                                    index !== 2 && {bgcolor: 'success.solidBg'}),
                            },
                            "&:hover": {
                                cursor: 'pointer',
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                textDecoration: this.props.activeStep > index?'underline':null,
                            }}
                        >
                            {step.title}
                        </Typography>

                    </Step>
                ))}
            </Stepper>
        )
    }
}

export default CommonStepper
