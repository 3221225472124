import React from "react";
import Typography from "@mui/joy/Typography";
import {Stack} from "@mui/joy";

// components
import withNavigate from "../../utils/withNavigate";
import CommonFilterModal from "./CommonFilterModal";
import Chip from "@mui/joy/Chip";
import Pagination from "../Pagination/Pagination";



class CommonPaginatedComponentList extends React.Component{
    render() {
        return (
            <Stack
                direction="column"
                spacing={2}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography
                        level="h4"
                    >
                        {this.props.title}
                    </Typography>
                    {
                        this.props.filterFields?
                            <CommonFilterModal
                                callBack={this.props.filterCallBack}
                                filterFields={this.props.filterFields}
                            />:
                            null
                    }
                </Stack>
                <Stack
                    spacing={2}
                >
                    {this.props.data.map((each) => {
                        let props = {};
                        props[`${this.props.componentKeyword}`] = each
                        props.callBack = this.props.callBack
                        return (
                            <this.props.component
                                {...props}
                            />
                        )
                    })}
                    {
                        this.props.data?.length === 0?
                            <Chip
                                color={'warning'}
                                sx={{mt: 1}}
                            >
                                Список пуст
                            </Chip>:
                            null
                    }
                </Stack>
                {
                    this.props.pages?
                        <Pagination
                            count={this.props.pages}
                            page={this.props.pageNumber}
                            onClick={
                                (value) => {
                                    this.props.navigate(`/${this.props.url}/page/`+value);
                                }
                            }
                        />:
                        null
                }
            </Stack>
        )
    }
}

export default withNavigate(CommonPaginatedComponentList)