import React from "react";

// components
import retrievePermission from "../../utils/retrievePermission";
import BaseApi from "../../utils/BaseApi";
import ContractReadOnlyDetail from "./ContractReadOnlyDetail";
import ContractDetail from "./ContractDetail";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class ContractTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            contract: null,
            updatePermission: 0,
        };
    }

    async componentDidMount() {
        await this.retrieveContract();
        await this.retrieveUpdatePermission();
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'ContractViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        await this.setState(
            {
                updatePermission: p
            }
        );
    }

    async retrieveContract() {
        const url = `contract/${this.props.opportunity.id}/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        if (response.status === 200){
            this.setState(
                {
                    contract: response.data
                }
            );
        }
    }

    render() {
        if (this.state.updatePermission){
            return (
                <ContractDetail
                    opportunity={this.props.opportunity}
                    contract={this.state.contract}
                />
            )
        }
        else if (this.state.contract){
            return (
                <ContractReadOnlyDetail
                    contract={this.state.contract}
                />
            )
        }
        else {
            return (
                <>Договор для этой сделки еще не создан</>
            )
        }
    }
}

export default ContractTab