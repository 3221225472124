import React from 'react';
import {Stack} from "@mui/joy";

// components
import CommunicationsCard from "../Communication/CommunicationsCard";
import CommonInfoTab from "../Common/CommonInfoTab";

// icons
import InfoIcon from "@mui/icons-material/Info";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import retrievePermission from "../../utils/retrievePermission";


class ContactTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            updatePermission: 0,
        };
    }

    async componentDidMount(){
        await this.retrieveUpdatePermission();
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'ContactViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        this.setState(
            {
                updatePermission: p
            }
        );
    }
    
    render() {
        return(
            <Stack
                spacing={2}
            >
                <CommonInfoTab
                    callBack={this.props.callBack}
                    groups={
                        [
                            {
                                title: 'Общая информация',
                                icon: <InfoIcon/>,
                                fields: [
                                    {
                                        title: 'Пол',
                                        value: this.props.contact.sex_human,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['sex', ],
                                            baseUrl: 'contact',
                                            obj: this.props.contact
                                        }
                                    },
                                    {
                                        title: 'Корпоративный уровень',
                                        value: this.props.contact.corporate_level_human,
                                        html: true,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['corporate_level', ],
                                            baseUrl: 'contact',
                                            obj: this.props.contact
                                        }
                                    },
                                    {
                                        title: 'Корпоративный тип',
                                        value: this.props.contact.corporate_type_human,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['corporate_type', ],
                                            baseUrl: 'contact',
                                            obj: this.props.contact,
                                        },
                                    },
                                ]
                            }
                        ]
                    }
                />
                <CommunicationsCard
                    related_app={'Contact'}
                    related_app_id={this.props.contact.id}
                />
            </Stack>
        )
    }
}

export default ContactTab