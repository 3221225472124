// components
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Common/CommonActionModal";
import CommonForm from "../Common/CommonForm";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AttachFileIcon from "@mui/icons-material/Assignment";


class AttachmentCreate extends CommonActionModal {
    async componentDidMount() {
        let formFields = await this.retrieveFields();
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Загрузить файл',
            buttonColor: 'primary',
            modalLabel: 'Добавление вложения',
            modalIcon: AttachFileIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
            ready: true,
        })
    }

    async retrieveFields(){
        const url = `attachment/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return response.data
    }

    async processForm(form) {
        let formData = form.state.formData;
        let fileInput = form.state.formObject.upload;
        let upload = fileInput.files[0];
        formData.append('related_app_id', this.props.appId);
        formData.append('related_app', this.props.appName);
        formData.append('xtype', this.props.xtype);
        if (upload) {
            formData.append('upload', upload);
        }
        const url = `attachment/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formData,
            true,
        );
        if (response.status === 201) {
            if (this.props.callBack) {
                await this.props.callBack(response.data.id);
            }
            this.setModalOpenFalse()
        }
    }
}

export default AttachmentCreate
