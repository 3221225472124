import React from 'react';

import Sheet from '@mui/joy/Sheet';
import GlobalStyles from '@mui/joy/GlobalStyles';
import List from '@mui/joy/List';
import Tooltip from '@mui/joy/Tooltip';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";

// components
import Search from "../Search/Search";
import BaseApi from "../../utils/BaseApi";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import NotificationButton from '../Notification/NotificationButton';
import ColorSchemeToggle from '../Common/ColorSchemeToggle';
import ProfileMini from '../Profile/ProfileMini';
import {closeSidebar} from "../Common/utils";
import withNavigate from '../../utils/withNavigate';
import withColorScheme from "../../utils/withColorScheme";

// sidebars
import HomeSidebar from './HomeSidebar';
import OpportunitySidebar from './OpportunitySidebar';
import TaskSidebar from './TaskSidebar';
import AccountSidebar from './AccountSidebar'
import KpiSidebar from "./KpiSidebar";
import FeatureRequestSidebar from "./FeatureRequestSidebar";
import OperationsSidebar from "./OperationsSidebar";
import AdminSidebar from "./AdminSidebar";
import FinancialSidebar from "./FinancialSidebar";
import MarketingSidebar from "./MarketingSidebar";
import DocumentSidebar from "./DocumentSidebar";

// icons
import AssignmentIcon from '@mui/icons-material/Assignment';
import EngineeringIcon from '@mui/icons-material/Engineering';
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SupportIcon from '@mui/icons-material/Support';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ArticleIcon from '@mui/icons-material/Article';


class Sidebar extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            activeButton: 'home',
            secondSidebarComponent: null,
            descendantsCount: 0,
        };
    }

    async retrieveDescendantsCount() {
        const url = 'subscriber/descendants/';
        const api = new BaseApi();
        let response = await api.get(
            url,{}
        );
        this.setState({
            descendantsCount: response.data.count
        })
    }

    async componentDidMount() {
        await this.setMenuState();
        await this.retrieveDescendantsCount();
    }

    setMenuState(){
        switch (this.props.location.pathname.split('/')[1])
        {
            case '/':
                this.setState(
                {
                        secondSidebarComponent: <HomeSidebar />,
                        activeButton: 'home'
                    }
                );
                break;
            case 'idea':
                this.setState(
                {
                        secondSidebarComponent: <HomeSidebar />,
                        activeButton: 'home'
                    }
                );
                break;
            case 'opportunity':
                this.setState(
                    {
                        secondSidebarComponent: <OpportunitySidebar/>,
                        activeButton: 'opportunity',
                    }
                );
                break;
            case 'lead':
                this.setState(
                    {
                        secondSidebarComponent: <OpportunitySidebar/>,
                        activeButton: 'opportunity',
                    }
                );
                break;
            case 'contract':
                this.setState(
                    {
                        secondSidebarComponent: <OpportunitySidebar/>,
                        activeButton: 'opportunity',
                    }
                );
                break;
            case 'route':
                this.setState(
                    {
                        secondSidebarComponent: <TaskSidebar/>,
                        activeButton: 'task',
                    }
                );
                break;
            case 'delivery':
                this.setState(
                    {
                        secondSidebarComponent: <TaskSidebar/>,
                        activeButton: 'task',
                    }
                );
                break;
            case 'task':
                this.setState(
                    {
                        secondSidebarComponent: <TaskSidebar/>,
                        activeButton: 'task',
                    }
                );
                break;
            case 'account':
                this.setState(
                    {
                        secondSidebarComponent: <AccountSidebar/>,
                        activeButton: 'account',
                    }
                );
                break;
            case 'contact':
                this.setState(
                    {
                        secondSidebarComponent: <AccountSidebar/>,
                        activeButton: 'account',
                    }
                );
                break;
            case 'kpi':
                this.setState(
                    {
                        secondSidebarComponent: <KpiSidebar/>,
                        activeButton: 'kpi',
                    }
                );
                break;
            case 'feature_request':
                this.setState(
                    {
                        secondSidebarComponent: <FeatureRequestSidebar/>,
                        activeButton: 'feature_request',
                    }
                );
                break;
            case 'operations':
                this.setState(
                    {
                        secondSidebarComponent: <OperationsSidebar/>,
                        activeButton: 'operations',
                    }
                );
                break;
            case 'hub':
                this.setState(
                    {
                        secondSidebarComponent: <MarketingSidebar />,
                        activeButton: 'marketing',
                    }
                );
                break;
            case 'letter':
                this.setState(
                    {
                        secondSidebarComponent: <DocumentSidebar />,
                        activeButton: 'document',
                    }
                );
                break;
            case 'incoming_letter':
                this.setState(
                    {
                        secondSidebarComponent: <DocumentSidebar />,
                        activeButton: 'document',
                    }
                );
                break;
            case 'admin':
                this.setState(
                    {
                        secondSidebarComponent: <AdminSidebar/>,
                        activeButton: 'admin',
                    }
                );
                break;
            case 'financial':
                this.setState(
                    {
                        secondSidebarComponent: <FinancialSidebar/>,
                        activeButton: 'financial',
                    }
                );
                break;

            default:
                this.setState(
                    {
                            secondSidebarComponent: <HomeSidebar />,
                            activeButton: 'home'
                        }
                );
        }
    }

    async componentDidUpdate(prevProps) {
       if(this.props.location.pathname !== prevProps.location.pathname)
       {
           this.setMenuState();
       }
    }

    setSecondSidebarComponent(component, item, link) {
        this.setState(
            {
                secondSidebarComponent: component,
                activeButton: item,
            }
        );
        if (link !== null) {
            this.props.navigate(link);
        }
    }

    render() {
        return (
            <>
                <Sheet
                    className='FirstSidebar'
                    variant='soft'
                    color='primary'
                    invertedColors
                    sx={{
                        position: {
                            xs: 'fixed',
                            lg: 'sticky',
                        },
                        transform: {
                            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                            lg: 'none',
                        },
                        transition: 'transform 0.4s',
                        zIndex: 1000,
                        height: '100dvh',
                        width: 'var(--FirstSidebar-width)',
                        top: 0,
                        p: 1.5,
                        py: 3,
                        flexShrink: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: "space-between",
                        alignItems: 'center',
                        gap: 2,
                        borderRight: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <GlobalStyles
                        styles={{
                            ':root': {
                                '--FirstSidebar-width': '68px',
                            },
                        }}
                    />
                    <Link
                        href={'/'}
                        target={'_blank'}
                    >
                        <div
                            style={{
                                filter: (
                                    this.props.colorScheme.colorScheme === 'dark'?
                                        'brightness(0%) contrast(100%) invert(100%)':
                                        ''
                                ),
                                width: '34px',
                                height: '34px',
                                background: 'url(https://multiphysics.ru/static/logo_web_h34px.svg) 0 0',
                                border: '0px',
                            }}
                        />
                    </Link>
                    <List
                        sx={
                            {
                                '--ListItem-radius': '8px',
                                '--List-gap': '12px',
                                overflowY: 'scroll',
                            }
                        }
                    >
                        <Tooltip title='Главная' placement={'right'}>
                            <ListItem
                                variant={('home' === this.state.activeButton) ? 'solid' : null}
                            >
                                <ListItemButton onClick={() => this.setSecondSidebarComponent(<HomeSidebar />, 'home', '/')}>
                                    <HomeIcon fontSize='small'/>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        <Tooltip title='Контрагенты' placement={'right'}>
                            <ListItem
                                variant={('account' === this.state.activeButton) ? 'solid' : null}
                            >
                                <ListItemButton onClick={() => this.setSecondSidebarComponent(<AccountSidebar />, 'account', null)}>
                                    <ApartmentIcon fontSize='small'/>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        <Tooltip title='Дела' placement={'right'}>
                            <ListItem
                                variant={('task' === this.state.activeButton) ? 'solid' : null}
                            >
                                <ListItemButton onClick={() => this.setSecondSidebarComponent(<TaskSidebar />, 'task', null)}>
                                    <AssignmentIcon fontSize='small'/>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        <Tooltip title='Маркетинг' placement={'right'}>
                            <ListItem
                                variant={('marketing' === this.state.activeButton) ? 'solid' : null}
                            >
                                <ListItemButton onClick={() => this.setSecondSidebarComponent(<MarketingSidebar />, 'marketing', null)}>
                                    <StorefrontIcon fontSize='small'/>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        <Tooltip title='Документооборот' placement={'right'}>
                            <ListItem
                                variant={('document' === this.state.activeButton) ? 'solid' : null}
                            >
                                <ListItemButton onClick={() => this.setSecondSidebarComponent(<DocumentSidebar />, 'document', null)}>
                                    <ArticleIcon fontSize='small'/>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        <Tooltip title='Сделки' placement={'right'}>
                            <ListItem
                                variant={('opportunity' === this.state.activeButton) ? 'solid' : null}
                            >
                                <ListItemButton onClick={() => this.setSecondSidebarComponent(<OpportunitySidebar />, 'opportunity', null)}>
                                    <EngineeringIcon fontSize='small'/>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        <Tooltip title='КПЭ' placement={'right'}>
                            <ListItem
                                variant={('kpi' === this.state.activeButton) ? 'solid' : null}
                            >
                                <ListItemButton onClick={() => this.setSecondSidebarComponent(<KpiSidebar />, 'kpi', null)}>
                                    <BarChartIcon fontSize='small'/>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        <Tooltip title='Техническая поддержка' placement={'right'}>
                            <ListItem
                                variant={('feature_request' === this.state.activeButton) ? 'solid' : null}
                            >
                                <ListItemButton onClick={() => this.setSecondSidebarComponent(<FeatureRequestSidebar />, 'feature_request', null)}>
                                    <SupportIcon fontSize='small'/>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        {
                            this.state.descendantsCount > 0?
                                <Tooltip title='Операционное управление' placement={'right'}>
                                    <ListItem
                                        variant={('bonus' === this.state.activeButton) ? 'solid' : null}
                                    >
                                        <ListItemButton onClick={() => this.setSecondSidebarComponent(<OperationsSidebar />, 'operations', null)}>
                                            <BusinessCenterIcon fontSize='small'/>
                                        </ListItemButton>
                                    </ListItem>
                                </Tooltip>:
                                null
                        }
                        {
                            this.context.userObject.is_superuser?
                                <Tooltip title='Администрирование' placement={'right'}>
                                    <ListItem
                                        variant={('admin' === this.state.activeButton) ? 'solid' : null}
                                    >
                                        <ListItemButton onClick={() => this.setSecondSidebarComponent(<AdminSidebar />, 'admin', null)}>
                                            <SettingsIcon fontSize='small'/>
                                        </ListItemButton>
                                    </ListItem>
                                </Tooltip>:
                                null
                        }
                        {
                            this.context.userObject.is_superuser?
                                <Tooltip title='Финансы' placement={'right'}>
                                    <ListItem
                                        variant={('financial' === this.state.activeButton) ? 'solid' : null}
                                    >
                                        <ListItemButton onClick={() => this.setSecondSidebarComponent(<FinancialSidebar />, 'financial', null)}>
                                            <CurrencyRubleIcon fontSize='small'/>
                                        </ListItemButton>
                                    </ListItem>
                                </Tooltip>:
                                null
                        }
                    </List>
                    <List
                        sx={
                            {
                                '--ListItem-radius': '8px',
                                '--List-gap': '12px',
                                overflowY: 'scroll',
                            }
                        }
                    >
                        <ListItem>
                            <Search/>
                        </ListItem>
                        <ListItem>
                            <NotificationButton/>
                        </ListItem>
                        <ListItem>
                            <ColorSchemeToggle />
                        </ListItem>
                    </List>
                </Sheet>
                <Sheet
                    className='SecondSidebar'
                    sx={{
                        position: {
                            xs: 'fixed',
                            lg: 'sticky',
                        },
                        transform: {
                            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
                            lg: 'none',
                        },
                        borderRight: '1px solid',
                        borderColor: 'divider',
                        transition: 'transform 0.4s',
                        zIndex: 999,
                        height: '100dvh',
                        width: '200px',
                        top: 0,
                        p: 1,
                        flexShrink: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    {this.state.secondSidebarComponent}
                    <ProfileMini />
                </Sheet>
                <Box
                        className="Sidebar-overlay"
                        sx={{
                            position: 'fixed',
                            zIndex: 998,
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            opacity: 'var(--SideNavigation-slideIn)',
                            backgroundColor: 'var(--joy-palette-background-backdrop)',
                            transition: 'opacity 0.4s',
                            transform: {
                                xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                                lg: 'translateX(-100%)',
                            },
                        }}
                        onClick={() => closeSidebar()}
                    />
            </>
        )
    }
}

export default withNavigate(withColorScheme(Sidebar));
