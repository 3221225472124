import React from 'react';


import withNavigate from "../../utils/withNavigate";
import DeliveryDetail from "./DeliveryDetail";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";


class DeliveryTable extends React.Component {
    render() {
        return (
            <CommonPaginatedTable
                title={null}
                data={this.props.deliveries}
                fields={[
                    {
                        title: 'Тип',
                        name: 'delivery.xtype_human',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('primary'),
                    },
                    {
                        title: 'Бизнес-процесс',
                        name: 'delivery.title',
                        visible: true,
                    },
                    {
                        title: '',
                        visible: true,
                        use_component: true,
                        component: DeliveryDetail,
                        callBack: this.props.callBack
                    },
                    {
                        title: 'Сделка',
                        name: 'delivery.opportunity.short_name',
                        visible: this.props.showOpportunity,
                        linkPattern: '/opportunity/detail/:linkParam',
                        linkParam: 'delivery.opportunity.id',
                    },
                    {
                        title: 'Компания',
                        name: 'delivery.opportunity.account.nameshort',
                        visible: this.props.showAccount,
                        linkPattern: '/account/detail/:linkParam',
                        linkParam: 'delivery.opportunity.account.id',
                    },
                    {
                        title: 'Стадия',
                        name: 'stage.title',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => (value),
                        color_value: 'stage.color',
                    },
                    {
                        title: 'Прошло',
                        name: 'delay.0',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => (value>comparison?'danger':'success'),
                        color_value: 'delay.0',
                        color_comparison: 'stage.end_delay'
                    },
                    {
                        title: 'Дедлайн',
                        name: 'stage.end_delay',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('primary'),
                    },
                    {
                        title: 'Исполнитель',
                        name: 'operator.user.first_name',
                        visible: this.props.showOperator,
                    },
                    {
                        title: '',
                        name: 'operator.user.last_name',
                        visible: this.props.showOperator,
                    },
                ]}
            />
        )
    }
}

export default withNavigate(DeliveryTable);
