// components
import CommonForm from "../Common/CommonForm";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import EditIcon from '@mui/icons-material/Edit';


class FinancialModelConfigValueUpdate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: EditIcon,
            buttonIconOnly: true,
            buttonLabel: '',
            buttonColor: 'default',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = [
            {
                'name': this.props.name,
                'label': this.props.title,
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': this.props.defaultValue,
                'type': 'number',
            },
            {
                type: 'submit',
                label: 'Сохранить'
            }
        ]
        return {
            modalLabel: 'Редактирование параметра',
            modalIcon: null,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async processForm(form) {
        let data = Object.fromEntries(form.state.formData.entries());
        await this.props.callBack(data);
        this.setModalOpenFalse()
    }

}

export default FinancialModelConfigValueUpdate
