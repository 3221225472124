import React from "react";

import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import {Stack} from "@mui/joy";
import Snackbar from '@mui/joy/Snackbar';
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";

// components
import NewQuoteDetail from "./NewQuoteDetail";
import CommonCallBackButton from "../Common/CommonCallBackButton";
import BaseApi from "../../utils/BaseApi";
import {b64toBlob, downloadBlob} from "../../utils/misc";

// icons
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import dayjs from "dayjs";


class NewQuoteCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attached: false,
        };
    }

    async copyQuote(){
        const api = new BaseApi();
        const url = `new_quote/${this.props.quote.id}/copy/`;
        let params = {attach: true}
        let response = await api.get(url, params);
        if (response.status ===200){
            this.props.callBack();
        }
    }

    async attachQuote(){
        const api = new BaseApi();
        const url = `new_quote/${this.props.quote.id}/generate/`;
        let params = {attach: true}
        let response = await api.get(url, params);
        if (response.status ===200){
            this.setState({
                attached: true,
            })
        }
    }

    async downloadQuote(){
        const api = new BaseApi();
        const url = `new_quote/${this.props.quote.id}/generate/`;
        let params = {download: true}
        let response = await api.get(url, params);
        let timestamp = dayjs().format('YYYYMMDDHHmmss');
        downloadBlob(
            b64toBlob(response.data),
            `CIFRA_TCP_${this.props.quote.opportunity.id}_${timestamp}.docx`,
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        )
    }

    render() {
        let numberFormat = new Intl.NumberFormat('ru', {maximumFractionDigits: 2})
        return(
            <Card>
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            <Typography>
                                {this.props.quote.title}
                            </Typography>
                            <Chip
                                color={'lime'}
                            >
                                {numberFormat.format(this.props.quote.price)} ₽
                            </Chip>
                            <Chip
                                color={'neutral'}
                            >
                                {this.props.quote.created_on}
                            </Chip>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={1}
                        >
                            <CommonCallBackButton
                                quote={this.props.quote}
                                callBack={this.copyQuote.bind(this)}
                                propsObject={'quote'}
                                propsObjectKey={'id'}
                                icon={ContentCopyIcon}
                                color={'lime'}
                            />
                            <CommonCallBackButton
                                quote={this.props.quote}
                                callBack={this.attachQuote.bind(this)}
                                propsObject={'quote'}
                                propsObjectKey={'id'}
                                icon={AttachFileIcon}
                            />
                            <Snackbar
                              anchorOrigin={{ vertical: 'top', horizontal: 'center',}}
                              open={this.state.attached}
                              onClose={() => (this.setState({attached: false,}))}
                            >
                              ТКП добавлено во вложения
                            </Snackbar>
                            <CommonCallBackButton
                                quote={this.props.quote}
                                callBack={this.downloadQuote.bind(this)}
                                propsObject={'quote'}
                                propsObjectKey={'id'}
                                icon={DownloadIcon}
                            />
                            <NewQuoteDetail
                                quote={this.props.quote}
                                callBack={this.props.callBack}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        )
    }
}

export default NewQuoteCard
