// components
import BaseApi from "../../../utils/BaseApi";
import CommonForm from "../../Common/CommonForm";
import CommonActionModal from "../../Common/CommonActionModal";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableViewIcon from "@mui/icons-material/TableView";


class ContactTeamCreate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Добавление контакта в команду',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        let url = `opp_contact_team/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let formFields = response.data;
        url = `contact/`;
        let params = {
            account: this.props.opportunity.account.id
        }
        response = await api.get(
            url,
            params
        );
        for (let field of formFields){
            if (field.name === 'contact') {
                field.options = response.data.results.map(each => {
                    return (
                        {
                            'value': each.id,
                            'label': `${each.first_name} ${each.last_name}`,
                        }
                    )
                })
            }
        }
        return formFields
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.opportunity = this.props.opportunity.id
        const url = `opp_contact_team/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            this.setModalOpenFalse();
            this.props.callBack();
        }
    }
}

export default ContactTeamCreate
