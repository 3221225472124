import React from "react";

import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";


class CommonCallBackButton extends React.Component {
    render() {
        let argument;
        if (this.props[this.props.propsObject]){
            if (this.props[this.props.propsObject]){
                argument = this.props[this.props.propsObject][this.props.propsObjectKey]
            }
        }
        return(
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                {
                    this.props.label?
                        <Button
                            onClick={() => (this.props.callBack(argument))}
                            startDecorator={<this.props.icon fontSize={'small'}/>}
                            color={this.props.color?this.props.color:'neutral'}
                            size={'sm'}
                            sx={{mt: 1, mb: 1, mr: 1}}
                            variant={this.props.variant?this.props.variant:'plain'}
                        >
                            {this.props.label?this.props.label:null}
                        </Button>:
                        <IconButton
                            size={'sm'}
                            onClick={() => (this.props.callBack(argument))}
                            sx={{mt: 1, mb: 1, mr: 1}}
                            color={this.props.color?this.props.color:'neutral'}
                        >
                            <this.props.icon fontSize={'small'}/>
                        </IconButton>
                }
            </Box>
        )
    }
}

export default CommonCallBackButton
