import React from "react";
import CommonInfoTab from "../Common/CommonInfoTab";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";

// icons
import SupportIcon from '@mui/icons-material/Support';


class FeatureRequestDetailHeader extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            updatePermission: 0,
        };
    }

    async componentDidMount(){
        await this.retrieveUpdatePermission();
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'FeatureRequestViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        this.setState(
            {
                updatePermission: p
            }
        );
    }

    render() {
        return(
            <CommonInfoTab
                flat={true}
                groups={
                    [
                        {
                            title: this.props.featureRequest.title,
                            icon: <SupportIcon/>,
                            flat: true,
                            fields: [
                                {
                                    title: 'Тип',
                                    value:  this.props.featureRequest.xtype_human,
                                    flat: true,
                                    xs: 6,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    edit: {
                                        permission: this.state.updatePermission,
                                        fields: ['xtype', ],
                                        baseUrl: 'feature_request',
                                        obj: this.props.featureRequest,
                                        callBack: this.props.callBack,
                                    },
                                },
                                {
                                    title: 'Статус',
                                    value:  this.props.featureRequest.work_status_human,
                                    flat: true,
                                    xs: 6,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    edit: {
                                        permission: this.state.updatePermission,
                                        fields: ['work_status', ],
                                        baseUrl: 'feature_request',
                                        obj: this.props.featureRequest,
                                        callBack: this.props.callBack,
                                    },
                                },
                            ]
                        },
                    ]
                }
            />
        )
    }
}

export default FeatureRequestDetailHeader