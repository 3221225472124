import React from "react";

//icons
import SearchIcon from '@mui/icons-material/Search';
import CommonActionModal from "../Common/CommonActionModal";
import TableViewIcon from "@mui/icons-material/TableView";
import CommonForm from "../Common/CommonForm";
import SearchInner from "./SearchInner";



class Search extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: SearchIcon,
            buttonColor: 'neutral',
            flat: true,
            ready: true,
        })
    }

    async beforeModalOpen(){
        return {
            modalLabel: 'Добавление контакта',
            modalIcon: TableViewIcon,
            mainComponent: SearchInner,
            mainComponentProps: {
                callBack: this.setModalOpenFalse.bind(this)
            },
        }
    }
}

export default Search