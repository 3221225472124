// components
import CommonForm from "../Common/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {deleteArrayItemByKeyValue} from "../../utils/misc";


class AddressCreateModal extends CommonActionModal {
    async componentDidMount() {
        let formFields = await this.retrieveFields();
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            modalLabel: 'Создание реквизитов',
            modalIcon: AssignmentIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
            ready: true,
        })
    }

    async retrieveFields() {
        const url = `address/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let formFields = response.data
        formFields = deleteArrayItemByKeyValue(
            formFields,
            'name',
            'related_app'
        );
        formFields = deleteArrayItemByKeyValue(
            formFields,
            'name',
            'related_app_id'
        );
        return (formFields)
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        formDataObj.related_app = this.props.related_app;
        formDataObj.related_app_id = this.props.related_app_id;
        const url = `address/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            if (this.props.callBack){
                await this.props.callBack()
            }
            this.setModalOpenFalse();
        }
    }
}

export default AddressCreateModal