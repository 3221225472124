import React from 'react';
import {CircularProgress, Option, Select, Stack,} from "@mui/joy";
import {BarChart} from '@mui/x-charts/BarChart';
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";

// components
import {compareNumbers, getRandomInt} from "../../utils/misc";

// icons
import CloseIcon from "@mui/icons-material/Close";
import PaletteIcon from '@mui/icons-material/Palette';


class YearBarChartWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartConfig: this.props.chartConfig,
            ...this.props.stats,
            dataset: this.props.stats.stats,
            datasetTotal: this.props.stats.total
        };
        this.customID = crypto.randomUUID();
    }

    async componentDidMount() {
        let chartConfig = this.state.chartConfig
        if (!chartConfig || Object.keys(chartConfig).length === 0){
            chartConfig = await this.repairChartConfig();
        }
        if (!chartConfig.currentCategory){
            chartConfig = await this.repairChartConfig();
        }
        if (!chartConfig.dataset){
            chartConfig.dataset = 'default'
            await this.updateChartConfig(chartConfig)
        }
        this.setDataset(chartConfig.dataset, chartConfig.datasetValue)
    }

    setDataset(name, value){
        let dataset = this.state.stats;
        let datasetTotal = this.state.total;
        let ds = this.state.group_stats[name]
        let dsTotal;
        if (ds){
            dsTotal = ds['total'][value]
            ds = ds['periods'][value]
        }
        if (ds){
            dataset = ds
        }
        if (dsTotal){
            datasetTotal = dsTotal
        }
        this.setState(
            {
                dataset: dataset,
                datasetTotal: datasetTotal,
            }
        )
    }

    async repairChartConfig() {
        let currentYear = new Date().getFullYear()
        let chartConfig = {
            currentCategory: Object.keys(this.props.stats.categories)[0],
            years: [new Date().getFullYear(), ],
            yearColors: {},
            colorMode: 'heatmap',
            dataset: 'default',
        }
        chartConfig.yearColors[currentYear] = this.props.colors[0];
        await this.updateChartConfig(chartConfig)
        return chartConfig
    }

    async handleYearChange(event, newValue){
        let chartConfig = this.state.chartConfig;
        chartConfig.years = newValue.sort(compareNumbers)
        for (let year of newValue){
            if (!(year in chartConfig.yearColors)){
                chartConfig.yearColors[year] = this.props.colors[getRandomInt(20)];
            }
        }
        await this.updateChartConfig(chartConfig)
    }

    async currentCategoryChange(event, newValue){
        let chartConfig = this.state.chartConfig;
        chartConfig.currentCategory = newValue;
        await this.updateChartConfig(chartConfig);
    }

    async colorModeChange(event, newValue){
        let chartConfig = this.state.chartConfig;
        let colorMode;
        if (chartConfig.colorMode === 'heatmap') {
            colorMode = 'list'
        }
        else {
            colorMode = 'heatmap'
        }
        chartConfig.colorMode = colorMode;
        await this.updateChartConfig(chartConfig);
    }

    async yearColorChange(event, newValue, year){
        let chartConfig = this.state.chartConfig;
        chartConfig.yearColors[year] = newValue;
        await this.updateChartConfig(chartConfig);
    }

    async datasetChange(event, newValue){
        let chartConfig = this.state.chartConfig;
        chartConfig.dataset = newValue;
        let values = this.state.group_stats[newValue]
        let datasetValue = null;
        if (values){
            datasetValue = Object.keys(this.state.group_stats[newValue]['periods'])[0]
        }
        chartConfig.datasetValue = datasetValue
        this.setDataset(newValue, datasetValue)
        await this.updateChartConfig(chartConfig);
    }

    async datasetValueChange(event, newValue){
        let chartConfig = this.state.chartConfig;
        chartConfig.datasetValue = newValue;
        this.setDataset(this.state.chartConfig.dataset, newValue)
        await this.updateChartConfig(chartConfig);
    }

    async updateChartConfig(chartConfig){
        await this.props.updateChartConfigCallBack(
            chartConfig,
            this.props.index
        );
        this.setState({chartConfig: chartConfig});
    }

    render() {
        let numberFormat = new Intl.NumberFormat('ru', {maximumFractionDigits: 2})
        if (
            !this.state.dataset ||
            !this.state.chartConfig ||
            Object.entries(this.state.chartConfig).length === 0 ||
            !this.state.chartConfig.currentCategory ||
            !this.state.chartConfig.dataset
        ) {
            return (<CircularProgress/>)
        }
        return (
            <Stack
                sx={{
                    width: '100%',
                }}
                spacing={1}
            >
                <Stack
                    direction={'row'}
                    spacing={2}
                >
                    <Select
                        defaultValue={this.state.chartConfig.years}
                        multiple
                        onChange={this.handleYearChange.bind(this)}
                        key={'years'+this.customID}
                    >
                        {
                            Object.entries(this.state.stats).map(
                                (each) => (<Option value={parseInt(each[0])} key={'years'+this.customID+each[0]}>{each[0]}</Option>)
                            )
                        }
                    </Select>
                    <Select
                        defaultValue={this.state.chartConfig.dataset}
                        onChange={this.datasetChange.bind(this)}
                        key={'dataset'+this.customID}
                    >
                        <Option value={'default'}>Все данные</Option>
                        {
                            Object.entries(this.state.group_stats).map(
                                (each) => (<Option value={each[0]} key={'dataset'+this.customID+each[0]}>{each[0]}</Option>)
                            )
                        }
                    </Select>
                    {
                        this.state.chartConfig.dataset !== 'default'?
                            <Select
                                defaultValue={this.state.chartConfig.datasetValue}
                                onChange={this.datasetValueChange.bind(this)}
                                key={'datasetValue'+this.customID}
                            >
                                {
                                    Object.entries(this.state.group_stats[this.state.chartConfig.dataset]['periods']).map(
                                        (each) => (<Option value={each[0]} key={'datasetValue'+this.customID+each[0]}>{each[0]}</Option>)
                                    )
                                }
                            </Select>:
                            null
                    }
                    <Select
                        defaultValue={this.state.chartConfig.currentCategory}
                        onChange={this.currentCategoryChange.bind(this)}
                        key={'currentCategory'+this.customID}
                    >
                        {
                            Object.entries(this.state.categories).map(
                                (each) => (<Option value={each[0]} key={'currentCategory'+this.customID+each[0]}>{each[1]}</Option>)
                            )
                        }
                    </Select>
                    <IconButton
                        size={'sm'}
                        color="warning"
                        sx={{'ml': 1,}}
                        onClick={this.colorModeChange.bind(this)}
                    >
                        <PaletteIcon />
                    </IconButton>
                    <IconButton
                        size={'sm'}
                        color="danger"
                        sx={{'ml': 1,}}
                        onClick={
                            () => {this.props.deleteChartCallBack(this.props.index)}
                        }
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <BarChart
                    sx={{
                        ml: 2
                    }}
                    series={
                            this.state.chartConfig.years.map(year => {
                                    if (year in this.state.dataset){
                                        return(
                                            {
                                                data: Object.entries(this.state.dataset[year]).map(
                                                    (each) => (each[1][this.state.chartConfig.currentCategory])
                                                ),
                                                label: year.toString(),
                                                color: this.state.chartConfig.yearColors[year]
                                            }
                                        )
                                    }
                                    else {
                                        return {
                                            data: [],
                                            label: year.toString(),
                                            color: this.state.chartConfig.yearColors[year]
                                        }
                                    }
                                }
                            )
                    }
                    height={290}
                    xAxis={
                        this.state.chartConfig.years.length !== 0?
                            [
                                {
                                    data: Object.entries(this.state.dataset[Object.keys(this.state.dataset)[0]]).map(
                                        (each) => (each[0])
                                    ),
                                    scaleType: 'band',
                                    tickPlacement: 'middle',
                                    tickLabelPlacement: 'middle',
                                }
                            ]:
                            null
                    }
                    yAxis={
                        this.state.chartConfig.colorMode === 'heatmap'?
                            this.state.chartConfig.years.length !== 0?
                                [{
                                    colorMap: {
                                        type: 'continuous',
                                        min: this.state.dataset[this.state.chartConfig.years[0]]?Math.min(...Object.entries(this.state.dataset[this.state.chartConfig.years[0]]).map(
                                                (each) => (each[1][this.state.chartConfig.currentCategory])
                                            )):0,
                                        max: this.state.dataset[this.state.chartConfig.years[0]]?Math.max(...Object.entries(this.state.dataset[this.state.chartConfig.years[0]]).map(
                                                (each) => (each[1][this.state.chartConfig.currentCategory])
                                            )):0,
                                        color: ['red', 'green'],
                                    },
                                    valueFormatter: (yCoord, context) => (yCoord.toExponential()),
                                }]:
                                null:
                            [
                                {
                                    valueFormatter: (yCoord, context) => (yCoord>9999?yCoord.toExponential():yCoord),
                                }
                            ]
                    }
                    margin={{top: 10, bottom: 30, left: 60, right: 10}}
                />
                <Card>
                    <Stack
                        spacing={1}
                    >
                        {
                            this.state.chartConfig.years.map(year => (
                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                    sx={{
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        flexWrap: 'wrap',
                                    }}
                                    useFlexGap
                                    key={'totals'+this.customID+year}
                                >
                                    <Typography
                                        level={'body-sm'}
                                        sx={{
                                            fontWeight: 700,
                                            color: this.state.chartConfig.yearColors[year]
                                        }}
                                    >
                                        {year}:
                                    </Typography>
                                    <Select
                                        defaultValue={this.state.chartConfig.yearColors[year]}
                                        onChange={(event, newValue) => {
                                            this.yearColorChange.bind(this)(event, newValue, year)
                                        }}
                                        size="sm"
                                        sx={{
                                            minHeight: '12px',
                                            minWidth: '52px',
                                        }}
                                        renderValue={(selected) => (<Chip size='sm' sx={{backgroundColor: selected.value, p:0.5, minHeight: '10px'}}></Chip>)}
                                    >
                                        {
                                            this.props.colors.map(
                                                (each) => (<Option value={each} key={'colors'+this.customID+each}><Chip size='sm' sx={{backgroundColor: each, p:0.5, minHeight: '10px'}}></Chip></Option>)
                                            )
                                        }
                                    </Select>
                                    {
                                        this.state.datasetTotal[year]?
                                            <>
                                                {
                                                    this.state.chartConfig.currentCategory !== 'count'?
                                                        <Chip
                                                            color={'warning'}
                                                            size={'sm'}
                                                        >
                                                            Кол: {this.state.datasetTotal[year].count}
                                                        </Chip>:
                                                        null
                                                }
                                                <Chip
                                                    color={'success'}
                                                    size={'sm'}
                                                >
                                                    {this.state.chartConfig.currentCategory.endsWith('_ave')? 'Ср':'Сум'}: {numberFormat.format(this.state.datasetTotal[year][this.state.chartConfig.currentCategory])}
                                                </Chip>
                                                {
                                                    this.state.datasetTotal[year][`${this.state.chartConfig.currentCategory}_ave`] !== undefined?
                                                        <Chip
                                                            color={'info'}
                                                            size={'sm'}
                                                        >
                                                            Ср: {numberFormat.format(this.state.datasetTotal[year][`${this.state.chartConfig.currentCategory}_ave`])}
                                                        </Chip>:
                                                        null
                                                }
                                                {
                                                    this.state.datasetTotal[year][`${this.state.chartConfig.currentCategory}_median`] !== undefined?
                                                        <Chip
                                                            color={'indigo'}
                                                            size={'sm'}
                                                        >
                                                            Мед: {numberFormat.format(this.state.datasetTotal[year][`${this.state.chartConfig.currentCategory}_median`])}
                                                        </Chip>:
                                                        null
                                                }
                                            </>:
                                            null
                                    }
                                </Stack>
                            ))
                        }
                    </Stack>
                </Card>
            </Stack>
        )
    }
}

export default YearBarChartWidget