import React from "react";

import {CircularProgress} from "@mui/joy";

// components
import {retrieveComponentCard, } from "./utils";


class ComponentCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            card: null,
        };
    }

    async componentDidMount() {
        this.setState(
            {
                card: await retrieveComponentCard(
                    this.props.entity,
                    this.props.entity_id
                ),
            }
        );
    }

    render() {
        if (!this.state.card) {
            return (<CircularProgress/>)
        }
        return(
            <>
                {this.state.card}
            </>
        )
    }
}

export default ComponentCard
