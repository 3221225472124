import React from "react";

import Typography from "@mui/joy/Typography";
import Pagination from "../Pagination/Pagination";
import {Stack, Table} from "@mui/joy";
import Link from "@mui/joy/Link";
import Chip from "@mui/joy/Chip";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";

// components
import withNavigate from "../../utils/withNavigate";
import CommonFilterModal from "./CommonFilterModal";
import {generatePath} from "react-router-dom";
import {getNestedField} from "../../utils/misc";
import CommonEditModal from "./CommonEditModal";
import {joyTheme} from "../../theme";

// icons
import DownloadIcon from '@mui/icons-material/Download';


class CommonPaginatedTable extends React.Component {
    render() {
        return (
            <>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        mb: 2
                    }}
                >
                    {
                        this.props.title?
                            <Typography
                                level="h4"
                            >
                                {this.props.title}
                            </Typography>:
                            null
                    }
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        {
                            this.props.download?
                                <Box
                                    sx={{
                                        display: 'inline'
                                    }}
                                >
                                    <Button
                                        sx={{mt: 1, mb: 1, mr: 1}}
                                        size={'sm'}
                                        startDecorator={<DownloadIcon fontSize={'small'}/>}
                                        onClick={() => (this.props.download.callBack())}
                                    >
                                        Скачать
                                    </Button>
                                </Box>:
                                null
                        }
                        {
                            this.props.createComponent?
                                <this.props.createComponent
                                    {...this.props.createComponentProps}
                                    callBack={this.props.callBack}
                                />:
                                null
                        }
                        {
                            this.props.extraComponents?
                                <>
                                    {
                                        this.props.extraComponents.map((c)=>(
                                            <c.component
                                                {...c.componentProps}
                                                callBack={c.callBack}
                                            />
                                        ))
                                    }
                                </>
                                :null
                        }
                        {
                            this.props.filterFields?
                                <CommonFilterModal
                                    callBack={this.props.filterCallBack}
                                    filterFields={this.props.filterFields}
                                />:
                                null
                        }
                    </Stack>
                </Stack>
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                >
                    <thead>
                        <tr>
                            {this.props.fields.map((field) => {
                                if (!field.visible){
                                            return null
                                        }
                                return (
                                    <th key={'header-'+field.name} width={field.width?field.width:null}>
                                        {field.title}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((each) => {
                            let rowColorKey = this.props.color?this.props.color.rule(
                                this.props.color.value?getNestedField(each, this.props.color.value, false):null,
                                this.props.color.comparison?getNestedField(each, this.props.color.comparison, false):null,
                            ):null;
                            return (
                                <tr
                                    key={this.props.url+'-'+each.id}
                                    style={{
                                        backgroundColor: joyTheme.colorSchemes[localStorage.getItem("joy-mode")].palette[rowColorKey]?.softBg,
                                        color: joyTheme.colorSchemes[localStorage.getItem("joy-mode")].palette[rowColorKey]?.mainChannel,
                                    }}
                                >
                                    {this.props.fields.map((field) => {
                                        if (!field.visible){
                                            return null
                                        }
                                        return (
                                            <td key={this.props.url+'-'+each.id+'-'+field.name} width={field.width?field.width:null}>
                                                <Stack
                                                    direction={'row'}
                                                    spacing={1}
                                                >
                                                    <Box
                                                        display={'flex'}
                                                        sx={{
                                                            maxWidth: (field.edit && field.edit.permission)?'80%':'100%'
                                                        }}
                                                    >
                                                        {
                                                            field.linkPattern?
                                                                <Box
                                                                    sx={{
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                    }}
                                                                >
                                                                    <Link
                                                                        href={
                                                                            generatePath(
                                                                                field.linkPattern,
                                                                                {
                                                                                    linkParam: getNestedField(each, field.linkParam)
                                                                                }
                                                                            )
                                                                        }
                                                                        target={field.linkTarget}
                                                                    >
                                                                        {getNestedField(each, field.name, false, field.preProcess)}
                                                                    </Link>
                                                                </Box>
                                                            : field.use_component ?
                                                                <field.component
                                                                    componentId={each.id}
                                                                    componentObj={each}
                                                                    callBack={field.callBack}
                                                                    url={this.props.url}
                                                                    {...field.componentProps}
                                                                />
                                                            :
                                                                <>
                                                                    {
                                                                        field.chip?
                                                                            <Chip
                                                                                sx={{
                                                                                    width: '100%',
                                                                                }}
                                                                                color={
                                                                                    field.color_rule(
                                                                                        field.color_value?getNestedField(each, field.color_value, false):null,
                                                                                        field.color_comparison?getNestedField(each, field.color_comparison, false):null,
                                                                                    )
                                                                                }
                                                                            >
                                                                                {getNestedField(each, field.name, false, field.preProcess)}
                                                                            </Chip>:
                                                                            <>{getNestedField(each, field.name, true, field.preProcess)}</>
                                                                    }
                                                                </>
                                                        }
                                                    </Box>
                                                    {
                                                        field.edit && field.edit.permission?

                                                            <CommonEditModal
                                                                fields={field.edit.fields}
                                                                m2mFields={field.edit.m2mFields}
                                                                baseUrl={field.edit.baseUrl}
                                                                obj={field.edit.obj?getNestedField(each, field.edit.obj,false):each}
                                                                callBack={field.edit.callBack}
                                                                buttonIconOnly={true}
                                                            />
                                                            :
                                                            null
                                                    }
                                                </Stack>
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                    {
                        this.props.data?.length === 0?
                            <Chip
                                color={'warning'}
                                sx={{mt: 1}}
                            >
                                Список пуст
                            </Chip>:
                            null
                    }
                </Table>
                {
                    this.props.pages?
                       <Pagination
                            count={this.props.pages}
                            page={this.props.pageNumber}
                            onClick={
                                (value) => {
                                    if (typeof this.props.pagesCallback == 'function') {
                                        this.props.pagesCallback(value);
                                    }
                                    if (this.props.url) {
                                        this.props.navigate(`/${this.props.url}/page/`+value);
                                    }
                                }
                            }
                        />:
                        null
                }
            </>
        )
    }
}

export default withNavigate(CommonPaginatedTable)