import React from 'react';

import OpportunityList from "./OpportunityList";
import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Common/CommonForm";
import {CircularProgress} from "@mui/joy";


class OpportunityReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statuses: ['OPENX', ],
            team: [],
            my_roles: [],
            subscribers: null,
            xtypes_choices: null,
            xtypes: [],
            group: false,
            ready: false,
            load: false,
        }
    }

    async componentDidMount() {
        await this.retrieveData();
    }

    async retrieveData() {
        let url;
        let params;
        let response;
        let subscribers;
        let xtypes_choices = [];
        const api = new BaseApi();
        url = 'subscriber/descendants/';
        params = {}
        response = await api.get(
            url,
            params,
        );
        subscribers = response.data.results;
        url = 'attachment/subscriber_xtypes/';
        params = {}
        response = await api.get(
            url,
            params,
        );
        for (const category of response.data.all_xtypes) {
            if (response.data.available_xtypes.includes(category[0])){
                xtypes_choices.push(category);
            }
        }
        if (subscribers && xtypes_choices){
            this.setState({
                subscribers: subscribers,
                xtypes_choices: xtypes_choices,
                ready: true,
            });
        }
    }

    async processForm(form) {
        await this.setState({
            statuses: [],
            team: [],
            xtypes: [],
        })
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        await this.setState({
            statuses: formDataObj.statuses.split(','),
            team: formDataObj.team.split(','),
            xtypes: formDataObj.xtypes.split(','),
            group: formDataObj.group,
            load: true,
        });
    }

    render() {
        if (!this.state.ready) {
            return (
                <CircularProgress/>
            )
        }
        return (
            <>
                <CommonForm
                    processForm={this.processForm.bind(this)}
                    fields={[
                        {
                            name: 'statuses',
                            label: 'Статусы сделки',
                            xs: 12,
                            sm: null,
                            md: 6,
                            lg: null,
                            xl: null,
                            required: true,
                            default: ['OPENX',],
                            type: 'multiselect',
                            options: [
                                {'value': 'OPENX', 'label': 'В работе'},
                                {'value': 'DELAY', 'label': 'Ожидает'},
                                {'value': 'CLOSE', 'label': 'Закрыта'},
                                {'value': 'LOSTX', 'label': 'Потеряна'},
                            ]
                        },
                        {
                            'name': 'team',
                            'label': 'Участники команды',
                            'xs': 12,
                            'sm': null,
                            'md': 6,
                            'lg': null,
                            'xl': null,
                            'required': false,
                            'default': [],
                            'type': 'multiselect',
                            'options': this.state.subscribers.map((each) => {return(
                                {
                                    'value': each.id,
                                    'label': `${each.user.first_name} ${each.user.last_name}`
                                }
                            )})
                        },
                        {
                            'name': 'xtypes',
                            'label': 'Типы процессов',
                            'xs': 12,
                            'sm': null,
                            'md': 6,
                            'lg': null,
                            'xl': null,
                            'required': false,
                            'default': [],
                            'type': 'multiselect',
                            'options': this.state.xtypes_choices.map((each) => {return(
                                {
                                    'value': each[0],
                                    'label': each[1]
                                }
                            )})
                        },
                        {
                            name: 'group',
                            label: 'Группировать',
                            xs: 12,
                            sm: null,
                            md: 6,
                            lg: null,
                            xl: null,
                            required: false,
                            default: this.state.group,
                            type: 'checkbox',
                        },
                        {
                            'type': 'submit',
                            'label': 'Отобрать'
                        }
                    ]}
                />
                {
                    this.state.load?
                        this.state.statuses.map(each => {
                            return(
                                <OpportunityList
                                    status={each}
                                    team={this.state.team}
                                    myRoles={this.state.myRoles}
                                    xtypes={this.state.xtypes}
                                    group={this.state.group}
                                />
                            )
                        }):
                        null
                }

            </>
        )
    }
}

export default OpportunityReport;