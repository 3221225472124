import React from "react";

import {CircularProgress,} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedComponentList from "../Common/CommonPaginatedComponentList";
import QuoteCard from "./QuoteCard";


class QuoteTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quotes: null
        };
    }

    async componentDidMount() {
        await this.retrieveQuotes();
    }

    async retrieveQuotes() {
        const url = 'quote/';
        const api = new BaseApi();
        let params = {
            opportunity: this.props.opportunity.id
        }
        let response = await api.get(url, params);
        this.setState(
            {
                quotes: response.data.results,
            }
        );
    }

    render() {
        if (!this.state.quotes) {
            return (<CircularProgress/>)
        }
        return(
            <CommonPaginatedComponentList
                title={'Архивные ТКП'}
                component={QuoteCard}
                componentKeyword={'quote'}
                data={this.state.quotes}
                callBack={this.retrieveQuotes.bind(this)}
            />
        )
    }
}

export default QuoteTab
