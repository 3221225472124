import React from "react";

// components
import CommonForm from "../Common/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonStepper from "../Common/CommonStepper";

// icons

import {CircularProgress, Stack} from "@mui/joy";
import AttachmentCreate from "../Attachment/AttachmentCreate";


class CommentAttachmentAddInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            xtype: null
        }
        this.steps = [
            {
                order: 0,
                title: 'Тип',
            },
            {
                order: 1,
                title: 'Вложение',
            },
        ]
    }

    async retrieveCategories() {
        const url = `attachment/subscriber_xtypes`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let categories = []
        for (const category of response.data.all_xtypes) {
            if (response.data.available_xtypes.includes(category[0])){
                categories.push(category);
            }
        }
        return categories
    }

    async componentDidMount() {
        let categories = await this.retrieveCategories()
        let formFields = [
            {
                name: 'xtype',
                label: 'Тип',
                xs: 12,
                sm: null,
                md: null,
                lg: null,
                xl: null,
                required: true,
                default: this.state.xtype?this.state.xtype:null,
                type: 'select',
                options: categories.map(
                    (a) => ({
                        value: a[0],
                        label: a[1],
                    })
                )
            },
            {
                type: 'submit',
                label: 'Далее',
            },
        ]
        this.setState(
            {
                formFields: formFields,
            }
        );
    }

    async retrieveAttachmentsForm(id){
        this.setState({
            formFields: null
        })
        let xtype = this.state.xtype
        if (xtype) {
            const url = `attachment/`;
            const api = new BaseApi();
            const params = {
                deleted_bool: false,
                related_app: this.props.appName,
                related_app_id: this.props.appId,
                xtype: xtype,
            }
            let response = await api.get(
                url,
                params
            );
            this.setState(
                {
                    formFields: [
                        {
                            name: 'attachment',
                            label: 'Вложение',
                            xs: 12,
                            sm: null,
                            md: null,
                            lg: null,
                            xl: null,
                            required: true,
                            default: id?id:null,
                            type: 'select',
                            options: response.data.results.map(
                                (a) => ({
                                    value: a.id,
                                    label: a.uploadname,
                                })
                            )
                        },
                        {
                            type: 'submit',
                            label: 'Добавить',
                        },
                    ],
                    attachments: response.data.results,
                    activeStep: 1,
                }
            );
        }
    }

    async processStep0(form) {
        let xtype = form.state.formData.get('xtype');
        this.setState({
            xtype: xtype,
        }, this.retrieveAttachmentsForm)
    }

    async processStep1(form) {
        let attachment = form.state.formData.get('attachment');
        await this.props.callBack(attachment)
    }

    async setStep(order) {
        let formData = new FormData();
        let formFake = {'state': {'formData': formData}}
        if (order === 0){
            await this.componentDidMount()
        }
        else {
            await this['processStep'+(order-1)](formFake)
        }
        this.setState(
            {activeStep: order}
        )
    }

    render() {
        if (!this.state.formFields) {
            return (<CircularProgress />)
        }
        if (this.state.activeStep === 0) {
            return (
                <>
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        fields={this.state.formFields}
                        processForm={this.processStep0.bind(this)}
                    />
                </>
            )
        }
        else if (this.state.activeStep === 1) {
            return (
                <>
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <AttachmentCreate
                        appId={this.props.appId}
                        appName={this.props.appName}
                        xtype={this.state.xtype}
                        callBack={this.retrieveAttachmentsForm.bind(this)}
                    />
                    <Stack>
                        <CommonForm
                            fields={this.state.formFields}
                            processForm={this.processStep1.bind(this)}
                        />
                    </Stack>
                </>
            )
        }
    }
}

export default CommentAttachmentAddInner
