import React from "react";

// components
import CommonForm from "../Common/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonStepper from "../Common/CommonStepper";

// icons

import {CircularProgress, Stack} from "@mui/joy";
import {setFormFieldKey} from "../../utils/misc";


class DeliveryCreateInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            data: {},
        }
        this.steps = [
            {
                order: 0,
                title: 'Основные данные',
            },
            {
                order: 1,
                title: 'Исполнитель',
            },
        ]
    }

    async retrieveCategories() {
        const url = `attachment/subscriber_xtypes`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let categories = []
        for (const category of response.data.all_xtypes) {
            if (response.data.available_xtypes.includes(category[0])){
                categories.push(category);
            }
        }
        return categories
    }

    async retrieveFields() {
        const url = 'delivery/create_form/';
        const api = new BaseApi();
        const params = {
            fields: JSON.stringify(
                ['title', 'desc']
            )
        }
        let response = await api.get(
            url,
            params
        );
        return response.data
    }

    async componentDidMount() {
        let categories = await this.retrieveCategories()
        let formFields = [
            {
                name: 'xtype',
                label: 'Тип',
                xs: 12,
                sm: null,
                md: null,
                lg: null,
                xl: null,
                required: true,
                default: this.state.xtype?this.state.xtype:null,
                type: 'select',
                options: categories.map(
                    (a) => ({
                        value: a[0],
                        label: a[1],
                    })
                )
            },
            ...(await this.retrieveFields()),
        ]
        formFields = setFormFieldKey(
            formFields,
            'title',
            'required',
            true,
        )
        formFields = setFormFieldKey(
            formFields,
            'submitButton',
            'label',
            'Далее',
        )
        this.setState(
            {
                formFields: formFields,
            }
        );
    }

    async retrieveOperators(){
        let url;
        let params;
        let response;
        let stage;
        const api = new BaseApi();
        this.setState({
            formFields: null
        })
        let xtype = this.state.data.xtype
        if (xtype) {
            url = `delivery_stage/`;
            params = {
                first: true,
                is_active: true,
                xtype: xtype,
            }
            response = await api.get(
                url,
                params
            );
            if (response.data?.results){
                stage = response.data.results[0];
            }
            if (stage){
                url = `opp_team/`;
                params = {
                    opportunity: this.props.opportunity.id,
                    role: stage.operator,
                }
                response = await api.get(
                    url,
                    params
                );
                let subscriberIds = [];
                let subscribers = [];
                for (let teammate of response.data.results){
                    if (!subscriberIds.includes(teammate.subscriber.id)){
                        subscriberIds.push(teammate.subscriber.id);
                        subscribers.push(teammate.subscriber);
                    }
                }
                this.setState(
                    {
                        formFields: [
                            {
                                name: 'operator',
                                label: 'Исполнитель',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: response.data.results[0]?response.data.results[0].id:null,
                                type: 'select',
                                options: subscribers.map(
                                    (s) => ({
                                        value: s.id,
                                        label: `${s.user.first_name} ${s.user.last_name}`,
                                    })
                                )
                            },
                            {
                                type: 'submit',
                                label: 'Создать',
                            },
                        ],
                        attachments: response.data.results,
                        activeStep: 1,
                        stage: stage
                    }
                );
            }
        }
    }

    async processStep0(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        this.setState(
            {
                data: formDataObj
            },
            this.retrieveOperators
        )
    }

    async processStep1(form) {
        let data = this.state.data;
        let operator = parseInt(form.state.formData.get('operator'));
        data.opportunity = this.props.opportunity.id;
        let url = `delivery/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            data
        );
        if (response.status === 201) {
            url = `delivery_state/`
            response = await api.post(
                url,
                {
                    operator: operator,
                    stage: this.state.stage.id,
                    delivery: response.data.id,
                }
            );
            if (response.status === 201) {
                await this.props.callBack();
            }
        }
    }

    async setStep(order) {
        let formData = new FormData();
        let formFake = {'state': {'formData': formData}}
        if (order === 0){
            await this.componentDidMount()
        }
        else {
            await this['processStep'+(order-1)](formFake)
        }
        this.setState(
            {activeStep: order}
        )
    }

    render() {
        if (!this.state.formFields) {
            return (<CircularProgress />)
        }
        if (this.state.activeStep === 0) {
            return (
                <>
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <CommonForm
                        fields={this.state.formFields}
                        processForm={this.processStep0.bind(this)}
                    />
                </>
            )
        }
        else if (this.state.activeStep === 1) {
            return (
                <>
                    <CommonStepper
                        steps={this.steps}
                        activeStep={this.state.activeStep}
                        callBack={this.setStep.bind(this)}
                    />
                    <Stack>
                        <CommonForm
                            fields={this.state.formFields}
                            processForm={this.processStep1.bind(this)}
                        />
                    </Stack>
                </>
            )
        }
    }
}

export default DeliveryCreateInner
