import React from "react";

import {CircularProgress, Stack} from "@mui/joy";

// components
import NewQuoteCreateModal from "./NewQuoteCreateModal";
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedComponentList from "../Common/CommonPaginatedComponentList";
import NewQuoteCard from "./NewQuoteCard";
import QuoteTab from "../Quote/QuoteTab";


class NewQuoteTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quotes: null
        };
    }

    async componentDidMount() {
        await this.retrieveQuotes();
    }

    async retrieveQuotes() {
        const url = 'new_quote/';
        const api = new BaseApi();
        let params = {
            opportunity: this.props.opportunity.id
        }
        let response = await api.get(url, params);
        this.setState(
            {
                quotes: response.data.results,
            }
        );
    }

    render() {
        if (!this.state.quotes) {
            return (<CircularProgress/>)
        }
        return(
            <Stack
                spacing={2}
            >
                <NewQuoteCreateModal
                    opportunity={this.props.opportunity}
                    callBack={this.retrieveQuotes.bind(this)}
                />
                <CommonPaginatedComponentList
                    title={''}
                    component={NewQuoteCard}
                    componentKeyword={'quote'}
                    data={this.state.quotes}
                    callBack={this.retrieveQuotes.bind(this)}
                />
                <QuoteTab
                    opportunity={this.props.opportunity}
                />
            </Stack>
        )
    }
}

export default NewQuoteTab
