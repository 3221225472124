import React from "react";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import {CardOverflow, Stack} from "@mui/joy";
import Box from "@mui/joy/Box";

// components
import BaseApi from "../../utils/BaseApi";


class PermissionViewSet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewset_title: this.props.viewset[0],
            viewset_data: this.props.viewset[1]
        };
    }

    async changeItem(subscriber_type, action) {
        let value = null;
        if (this.state.viewset_data[subscriber_type][action] !== false) {
            const url = `api_permission/${this.state.viewset_data[subscriber_type][action]}`;
            const api = new BaseApi();
            let response = await api.delete(
                url
            );
            if (response.status === 204) {
                value = false;
            }
        }
        else {
            const url = `api_permission/`;
            const api = new BaseApi();
            let response = await api.post(
                url,
                {
                    viewset: this.state.viewset_title[1],
                    action: action,
                    subscriber_type: subscriber_type,
                }
            );
            if (response.status === 201) {
                value = response.data.id;
            }
        }
        if (value !== null){
            let new_data = this.state.viewset_data;
            new_data[subscriber_type][action] = value;
            this.setState(
                {
                    viewset_data: new_data
                }
            );
        }
    }

    render() {
        return(
            <Box
                sx={{
                    pb: 2,
                    overflowY: 'scroll',
                }}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="stretch"
                >
                    {Object.entries(this.state.viewset_data).map(subscriber_type => {
                        return(
                            <Card
                                sx={{
                                    width: '150px',
                                }}
                            >
                                <CardOverflow
                                    variant="soft"
                                    sx={{
                                        p: 2,
                                        height: '80px',
                                    }}
                                >
                                    <Typography level={'body-md'}>
                                        {this.props.subscriberTypes[subscriber_type[0]]}
                                    </Typography>
                                </CardOverflow>
                                <CardContent
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {Object.entries(subscriber_type[1]).map(action => {
                                        return(
                                            <Chip
                                                color={action[1] !== false?'success':'danger'}
                                                onClick={this.changeItem.bind(this, subscriber_type[0], action[0])}
                                            >
                                                {action[0]}
                                            </Chip>
                                        )
                                    })}
                                </CardContent>
                            </Card>
                        )
                    })}
                </Stack>
            </Box>
        )
    }
}

export default PermissionViewSet