import React from 'react';

// components
import CommonSidebar from "../Common/CommonSidebar";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";

//icons
import GroupIcon from "@mui/icons-material/Group";
import CastIcon from "@mui/icons-material/Cast";


class MarketingSidebar extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            hubGroup: null,
        };
    }

    async componentDidMount() {
        await this.retrieveHubPermission();
    }

    async retrieveHubPermission(){
        let p = 0;
        p += await retrievePermission(
            'WebinarViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        p += await retrievePermission(
            'MarketingUserViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        if (p > 1){
            this.setState(
                {
                    hubGroup: {
                        title: 'HUB',
                        items: [
                            {
                                title: 'Пользователи',
                                link: '/hub/marketing_user/',
                                icon: GroupIcon,
                            },
                            {
                                title: 'Вебинары',
                                link: '/hub/webinar/',
                                icon: CastIcon,
                            },
                        ],
                    },
                }
            );
        }
    }

    render() {
        let groups = [];
        if (this.state.hubGroup) {
            groups.push(this.state.hubGroup)
        }
        return(
            <CommonSidebar
                groups={groups}
            />
        )
    }
}

export default MarketingSidebar
