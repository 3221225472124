import React, {} from "react";
import ReconnectingWebSocket from "reconnecting-websocket";

import CircularProgress from "@mui/joy/CircularProgress";
import Box from "@mui/joy/Box";
import Badge from "@mui/joy/Badge";
import NotificationsIcon from '@mui/icons-material/Notifications';

// components
import withNavigate from "../../utils/withNavigate";
import BaseApi from "../../utils/BaseApi";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import {setAuthTokens} from "../../utils/misc";
import IconButton from "@mui/joy/IconButton";


class NotificationButton extends React.Component {
    static contextType = UserSubscriberContext
    
    constructor(props) {
        super(props);
        this.state = {
            count: null
        };
    }

    async componentDidMount() {
        await this.retrieveNotifications();
        this.client = new ReconnectingWebSocket(
            `${process.env.REACT_APP_WS_HOST}/ws/notification/${this.context.userObject.id}/`,
            [],
            {
                // maxRetries: 10,
            }
        );
        this.client.onopen = () => {
            console.log("WebSocket Client Connected");
            // this.timer = setInterval(
            //     () => {
            //         let tokens = JSON.parse(localStorage.getItem("authTokens"));
            //         this.client.send(
            //             JSON.stringify(
            //                 {
            //                     type: 'ping',
            //                     access: tokens.access,
            //                     refresh: tokens.refresh,
            //                 }
            //             )
            //         )
            //     },
            //     5000
            // );
        };
        this.client.onclose = () => {
            console.log("WebSocket Client Disconnected");
            // if (this.timer){
            //     clearInterval(this.timer);
            // }
        }
        this.client.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);
            if (dataFromServer) {
                // if (dataFromServer.type === 'tokens'){
                //     setAuthTokens(
                //         dataFromServer.access_token,
                //         dataFromServer.refresh_token
                //     )
                // }
                if (dataFromServer.type === 'count' ){
                    this.setState({
                        count: dataFromServer.count,
                    });
                }
            }
        };
    }

    componentWillUnmount() {
        if (this.client){
            this.client.close();
        }
        // if (this.timer){
        //     clearInterval(this.timer);
        // }
    }

    async retrieveNotifications() {
        const api = new BaseApi();
        let response = await api.get(
            'notification/',
            { 'is_read': 0 }
        );
        this.setState(
            {
                count: response.data.count,
            }
        );
    }

    render() {
        if (this.state.count === null || this.state.count === undefined) {
            return (<CircularProgress />)
        }
        return(
            <Badge badgeContent={this.state.count} color="danger">
                 <IconButton
                    onClick={() => this.props.navigate('/notification')}
                 >
                     <NotificationsIcon fontSize={'large'}/>
                 </IconButton>
            </Badge>
        )
    }
}

export default withNavigate(NotificationButton);
