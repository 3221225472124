// components
import CommonActionModal from "../Common/CommonActionModal";
import NotificationDetailInner from "./NotificationDetailInner";

// icons
import WebAssetIcon from '@mui/icons-material/WebAsset';


class NotificationDetailModal extends CommonActionModal {
    retrieveNotificationId(){
        let notificationId = this.props.notificationId
        if (!notificationId){
            notificationId = this.props.componentId
        }
        return notificationId
    }

    async componentDidMount() {
        this.setState({
            buttonIcon: WebAssetIcon,
            buttonColor: 'neutral',
            ready: true,
            modalOpen: this.props.modalOpen,
            mainComponent: NotificationDetailInner,
            mainComponentProps: {
                notificationId: this.retrieveNotificationId(),
            },
        })
    }

    async beforeModalOpen(){
        return {
            mainComponent: NotificationDetailInner,
            mainComponentProps: {
                notificationId: this.retrieveNotificationId(),
            },
        }
    }

    async setModalOpenFalse() {
        await this.props.callBack();
        this.setState(
            {
                modalOpen: false,
            }
        );
    }


}

export default NotificationDetailModal
