// components
import CommonForm from "../Common/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {setFormFieldKey, splitToList} from "../../utils/misc";


class ContractCreateModal extends CommonActionModal {
    async componentDidMount() {
        let formFields = await this.retrieveFields();
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            modalLabel: 'Создание договора',
            modalIcon: AssignmentIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
            ready: true,
        })
    }

    async retrieveFields() {
        let url = `contract/update_form/`;
        const api = new BaseApi();
        let response = await api.get(
            url, {}
        );
        let formFields = response.data
        formFields = setFormFieldKey(
            formFields,
            'contract_theme',
            'type',
            'input',
        )
        formFields = setFormFieldKey(
            formFields,
            'contract_date',
            'default',
            new Date(),
        )
        url = `contract/next_number`;
        response = await api.get(
            url,
            {}
        );
        formFields = setFormFieldKey(
            formFields,
            'contract_number',
            'default',
            response.data
        )
        url = `contact/`;
        response = await api.get(
            url,
            {
                account: this.props.opportunity.account.id,
                page_size: 10000,
            }
        );
        formFields = setFormFieldKey(
            formFields,
            'contract_customer',
            'options',
            response.data.results.map((each) => (
                {'value': each.id, 'label': `${each.first_name} ${each.last_name} - ${each.role}`}
            ))
        )
        formFields = setFormFieldKey(
            formFields,
            'contract_contact_person',
            'options',
            response.data.results.map((each) => (
                {'value': each.id, 'label': `${each.first_name} ${each.last_name} - ${each.role}`}
            ))
        )
        url = `subscriber/`;
        response = await api.get(
            url,
            {
                active: true,
                can_sign_financial: true,
            }
        );
        formFields = setFormFieldKey(
            formFields,
            'contract_signatory',
            'options',
            response.data.results.map((each) => (
                {'value': each.id, 'label': `${each.user.first_name} ${each.user.last_name}`}
            ))
        )
        return formFields
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        if ('contract_report_form' in formDataObj) {
            formDataObj.contract_report_form = splitToList(formDataObj.contract_report_form, ',')
        }
        if ('contract_elec_report_form' in formDataObj) {
            formDataObj.contract_elec_report_form = splitToList(formDataObj.contract_elec_report_form, ',')
        }
        const url = `contract/${this.props.opportunity.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            formDataObj
        );
        if (response.status === 200) {
            this.props.callBack();
            this.setModalOpenFalse();
        }
    }
}

export default ContractCreateModal