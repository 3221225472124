import React from "react";
import {CircularProgress, Stack} from "@mui/joy";

// components
import ContactCreateModal from "./ContactCreateModal";
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedTable from "../Common/CommonPaginatedTable";


class ContactsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: null,
            contactsPages: null,
            contactsPage: 1,
        };
    }

    async componentDidMount() {
        await this.retrieveContacts();
    }

    async retrieveContacts(page=1) {
        this.setState(
            {
                contacts: null
            }
        );
        const url = `contact/`;
        const params = {
            page: page,
            account: this.props.account.id,
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                contacts: response.data.results,
                contactsPages: response.data.total_pages,
                contactsPage: page,
            }
        );
    }

    render() {
        if (!this.state.contacts) {
            return (<CircularProgress/>)
        }
        return(
            <Stack
                spacing={2}
            >
                <ContactCreateModal
                    account={this.props.account}
                    callBack={this.retrieveContacts.bind(this)}
                />
                <CommonPaginatedTable
                    data={this.state.contacts}
                    pages={this.state.contactsPages}
                    pageNumber={this.state.contactsPage}
                    pagesCallback={this.retrieveContacts.bind(this)}
                    fields={[
                         {
                            title: 'Фамилия',
                            name: 'last_name',
                            visible: true,
                            linkPattern: '/contact/detail/:linkParam',
                            linkParam: 'id',
                        },
                        {
                            title: 'Имя',
                            name: 'first_name',
                            visible: true,
                        },
                        {
                            title: 'Отчество',
                            name: 'patronymic',
                            visible: true,
                        },
                        {
                            title: 'Должность',
                            name: 'role',
                            visible: true,
                        },
                        {
                            title: 'Телефон',
                            name: 'primary_phone',
                            visible: true,
                        },
                        {
                            title: 'Электронная почта',
                            name: 'primary_email',
                            visible: true,
                        },
                    ]
                    }
                />
            </Stack>
        )
    }
}

export default ContactsTab;
