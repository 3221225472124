// components
import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Common/CommonForm";
import CommonActionModal from "../Common/CommonActionModal";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableViewIcon from "@mui/icons-material/TableView";


class RouteStageCreate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить стадию',
            buttonColor: 'success',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Добавление стадии',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        const url = `route_stage/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return response.data
    }


    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        formDataObj.entity = this.props.entity;
        formDataObj.next_stages = [];
        const url = `route_stage/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            await this.setModalOpenFalse();
            await this.props.callBack();
        }
    }
}

export default RouteStageCreate
