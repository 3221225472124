import React from "react";

// components
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import CommonInfoTab from "../Common/CommonInfoTab";

// icons
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import dayjs from "dayjs";


class PhaseCard extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            phase: this.props.phase,
            updatePermission: 0,
        };
    }

    async componentDidMount(){
        await this.retrieveUpdatePermission();
    }

    setPhase(phase){
        this.setState(
            {phase: phase}
        )
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'PhaseViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        this.setState(
            {
                updatePermission: p
            }
        );
    }

    render() {
        return(
            <CommonInfoTab
                groups={
                    [
                        {
                            title: this.props.phase.title,
                            icon: <ViewAgendaIcon />,
                            
                            fields: [
                                {
                                    title: 'Дата начала',
                                    value:  dayjs(this.state.phase.start_date).format('DD.MM.YYYY'),
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    edit: {
                                        permission: this.state.updatePermission,
                                        fields: ['start_date', ],
                                        baseUrl: 'phase',
                                        obj: this.state.phase,
                                        callBack: this.setPhase.bind(this),
                                    },
                                },
                                {
                                    title: 'Дата окончания',
                                    value:  dayjs(this.state.phase.end_date).format('DD.MM.YYYY'),
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    edit: {
                                        permission: this.state.updatePermission,
                                        fields: ['end_date', ],
                                        baseUrl: 'phase',
                                        obj: this.state.phase,
                                        callBack: this.setPhase.bind(this),
                                    },
                                },
                                {
                                    title: 'Результат',
                                    value:  this.state.phase.result_of_work,
                                    html: true,
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                    edit: {
                                        permission: this.state.updatePermission,
                                        fields: ['result_of_work', ],
                                        baseUrl: 'phase',
                                        obj: this.state.phase,
                                        callBack: this.setPhase.bind(this),
                                    },
                                },
                                {
                                    title: 'Полная цена',
                                    value:  this.state.phase.full_price,
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    edit: {
                                        permission: this.state.updatePermission,
                                        fields: ['full_price', ],
                                        baseUrl: 'phase',
                                        obj: this.state.phase,
                                        callBack: this.setPhase.bind(this),
                                    },
                                },
                                {
                                    title: 'Процент аванса',
                                    value:  this.state.phase.advance_rate,
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    edit: {
                                        permission: this.state.updatePermission,
                                        fields: ['advance_rate', ],
                                        baseUrl: 'phase',
                                        obj: this.state.phase,
                                        callBack: this.setPhase.bind(this),
                                    },
                                },
                                {
                                    title: 'Срок аванса (дней)',
                                    value:  this.state.phase.advance_payment_days,
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    edit: {
                                        permission: this.state.updatePermission,
                                        fields: ['advance_payment_days', ],
                                        baseUrl: 'phase',
                                        obj: this.state.phase,
                                        callBack: this.setPhase.bind(this),
                                    },
                                },
                                {
                                    title: 'Срок полной оплаты (дней)',
                                    value:  this.state.phase.full_payment_days,
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    edit: {
                                        permission: this.state.updatePermission,
                                        fields: ['full_payment_days', ],
                                        baseUrl: 'phase',
                                        obj: this.state.phase,
                                        callBack: this.setPhase.bind(this),
                                    },
                                },
                            ]
                        },
                    ]
                }
            />
        )
    }
}

export default PhaseCard