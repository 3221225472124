// components
import CommonForm from "../../Common/CommonForm";
import {splitToList} from "../../../utils/misc";
import BaseApi from "../../../utils/BaseApi";
import CommonActionModal from "../../Common/CommonActionModal";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableViewIcon from "@mui/icons-material/TableView";


class DeliveryActionCreateCreate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: '',
            buttonColor: 'success',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Добавление порождения',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        const url = `delivery_stage_action_create/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return response.data

    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.stage = this.props.stageId;
        if ('next_stages' in formDataObj) {
            formDataObj.next_stages = splitToList(formDataObj.next_stages, ',')
        }
        const url = `delivery_stage_action_create/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            await this.props.callBack();
            await this.setModalOpenFalse();
        }
    }
}

export default DeliveryActionCreateCreate
