import React from "react";
import {Stack} from "@mui/joy";
import Chip from "@mui/joy/Chip";
import {customRange} from "../../utils/misc";
import Typography from "@mui/joy/Typography";


class Pagination extends React.Component{
    render() {
        return(
            <Stack
                direction={'row'}
                spacing={1}
            >
                {customRange(1,this.props.count, 1).map(
                    (p) => {
                        if (
                            [1, this.props.page - 1, this.props.page, this.props.page + 1, this.props.count].includes(p)
                        ){
                            return(
                                <>
                                    {
                                        p === this.props.page - 1 && !([1, 2].includes(p))?
                                            <Typography>...</Typography>:
                                            null
                                    }
                                    <Chip
                                        onClick={() => (this.props.onClick(p))}
                                        color={this.props.page === p?'primary':'neutral'}
                                    >
                                        {p}
                                    </Chip>
                                    {
                                        p === this.props.page + 1 && !([this.props.count - 1, this.props.count].includes(p))?
                                            <Typography>...</Typography>:
                                            null
                                    }
                                </>
                            )
                        }
                        else {
                            return(null)
                        }
                    }
                )}
            </Stack>
        )
    }
}

export default Pagination